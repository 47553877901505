import { Avatar, Button, Dropdown, MenuProps } from "antd";
import { Header } from "antd/es/layout/layout";
import {
    UserOutlined,
    LogoutOutlined,
    MenuOutlined
} from '@ant-design/icons';
import { useAuth } from "../../lib/auth";
import { UserProfile } from "../../features/users/components/UserProfile";
import { useState } from "react";


const TopBar = (props: { sidebarOpen: boolean, toggleSideBar: any }) => {

    const [openUserProfile, setOpenUserProfile] = useState(false);

    const {logoutFn, user} = useAuth();

    const items: MenuProps['items'] = [
        {
            label: 'Profile',
            key: 'profile',
            icon: <UserOutlined />,
            onClick: () => {
                setOpenUserProfile(true)
            }
        },
        {
            label: 'Logout',
            key: 'logout',
            icon: <LogoutOutlined />,
            onClick: () => {
                logoutFn();
            }
        },
    ]

    const closeUserProfile = () => {
        setOpenUserProfile(false)
    }

    return (
        <>
            <UserProfile onClose={closeUserProfile} open={openUserProfile} />
            <Header style={{ paddingLeft: 20, backgroundColor: '#fff', boxShadow: '0 2px 4px -1px rgba(0,0,0,0.25)' }}>
                <Button icon={<MenuOutlined />} onClick={props.toggleSideBar} />
                <Dropdown menu={{ items }} placement="bottom"> 
                    <Avatar style={{ backgroundColor: '#00a2ae', verticalAlign: 'middle', position: 'absolute', right: 40, marginTop: 12 }} size="large" gap={4}>
                        {user?.name.charAt(0).toUpperCase()}
                    </Avatar>
                </Dropdown>
            </Header>
        </>
    )
}

export default TopBar;