import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Table, Card, Col, Spin, Button,Input } from 'antd';
import styles from './StudentCategoryModal.module.css';
import { getCodingTestResults } from "../api/getCodingTestResults"
import { getMcqTestResults } from "../api/getMcqTestResults";
import { CodingQuestionResult, MCQuestionResult } from "../../studentcourses/types";
import { ResponseInNewTab } from './ResponseInNewTab';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut, Pie } from 'react-chartjs-2';
import ReactDOM from 'react-dom/client';
const { Search } = Input;

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  visible: boolean;
  onClose: () => void;
  categoriesResponse: CategoriesResponse;
  submoduleId: number;
  testId: number;
  testType: string;
  onRender: any;
}

interface CategoriesResponse {
  excellentScores: Record<string, number[]>;
  goodScores: Record<string, number[]>;
  satisfactoryScores: Record<string, number[]>;
  interventionScores: Record<string, number[]>;
  otherScores: Record<string, string>;
  notStarted: Record<string, number>;
}
 
export const StudentCategoryModal: React.FC<Props> = ({ visible, onClose, categoriesResponse, submoduleId, testId, testType, onRender }) => {
  const { excellentScores, goodScores, satisfactoryScores, interventionScores, otherScores, notStarted } = categoriesResponse;
  const [activeTab, setActiveTab] = useState<string>('overall');
  const [filteredData, setFilteredData] = useState<{ name: string; rollnumber: string; marks: any; }[] | undefined>(undefined);
  const [codingQuestionTestDetail, setCodingQuestionTestDetail] = useState<CodingQuestionResult>();
  const [mcQuestionResult, setMcQuestionResult] = useState<MCQuestionResult>();
  const [selectedSubmoduleId, setSelectedSubmoduleId] = useState<number | null>(null);
  const [selectedTestId, setSelectedTestId] = useState<number | null>(null);
  const [submoduleid, setSubmoduleid] = useState<number | null>(null);
  const [testid, setTestid] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);



  const totalStudents = Object.values(categoriesResponse).reduce((total, category) => {
    return total + Object.keys(category).length;
  }, 0);


  const options = {
    animation: {
      animateRotate: true, 
      animateScale: true, 
      
    },
  };

  const analysisData = {
    labels: ['Excellent', 'Good', 'Satisfactory', 'Intervention', 'Not Started'],
    datasets: [
      {
        label: '# of Students',
        data: [
          Object.keys(excellentScores).length,
          Object.keys(goodScores).length,
          Object.keys(satisfactoryScores).length,
          Object.keys(interventionScores).length,
          Object.keys(notStarted).length
        ],
        backgroundColor: [
          'rgba(46, 204, 113, 0.9)',   
          'rgba(241, 196, 15, 0.9)',  
          'rgba(52, 152, 219, 0.9)',   
          'rgba(44, 62, 80, 0.9)',     
          'rgba(231, 76, 60, 0.9)',    
        ],
        borderColor: [
          'rgba(46, 204, 113, 1)',  
          'rgba(241, 196, 15, 1)',   
          'rgba(52, 152, 219, 1)',  
          'rgba(44, 62, 80, 1)',   
          'rgba(231, 76, 60, 1)',    
        ],
        borderWidth: 2, 
      },
    ],
  };

  const tableColumns = [
    { title: 'Category', dataIndex: 'category', key: 'category' },
    { title: 'Overall', dataIndex: 'overall', key: 'overall' },
    { title: 'Excellent', dataIndex: 'excellent', key: 'excellent' },
    { title: 'Intervention', dataIndex: 'intervention', key: 'intervention' },
    { title: 'Good', dataIndex: 'good', key: 'good' },
    { title: 'Satisfactory', dataIndex: 'satisfactory', key: 'satisfactory' },
    { title: 'Not Started', dataIndex: 'notStarted', key: 'notStarted' },
  ];
  
  const generateAnalysisTableData = (): { key: string; category: string; overall: number; intervention: number; good: number; satisfactory: number; notStarted: number, excellent:number }[] => {
    const overallCount = Object.values(categoriesResponse).reduce((total, category) => {
      return total + Object.keys(category).length;
    }, 0);
  
    const interventionCount = Object.keys(interventionScores).length;
    const goodCount = Object.keys(goodScores).length;
    const satisfactoryCount = Object.keys(satisfactoryScores).length;
    const notStartedCount = Object.keys(notStarted).length;
    const excellentCount=Object.keys(excellentScores).length;
  
    return [{
      key: 'total',
      category: 'Total Students',
      overall: overallCount,
      intervention: interventionCount,
      good: goodCount,
      satisfactory: satisfactoryCount,
      notStarted: notStartedCount,
      excellent: excellentCount,
    }];
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (!visible) {
      setSubmoduleid(null);
      setTestid(null);
    }
  }, [visible]);

  useEffect(() => {
    setSelectedSubmoduleId(submoduleId);
    setSelectedTestId(testId);
  }, [submoduleId, testId]);

  const handleCloseModal = () => {
    setSubmoduleid(null);
    setTestid(null);
    onClose();
  };

  const handleButtonClicked = (enrollmentid: number) => {
    goToResult(enrollmentid, submoduleId, testId, testType);
    setSubmoduleid(submoduleId);
    setTestid(testId);
  };


  

  const renderTableData = (): { name: string; rollnumber: string; marks: any }[] | undefined => {
    let data: { name: string; rollnumber: string; marks: any; }[] | undefined;
    switch (activeTab) {
      case 'overall':
        data = filteredData || generateTableData(Object.entries({ ...excellentScores, ...goodScores, ...satisfactoryScores, ...interventionScores, ...otherScores, ...notStarted }));
        break;
      case 'excellent':
        data = filteredData || generateTableData(Object.entries(excellentScores));
        break;
      case 'good':
        data = filteredData || generateTableData(Object.entries(goodScores));
        break;
      case 'satisfactory':
        data = filteredData || generateTableData(Object.entries(satisfactoryScores));
        break;
      case 'intervention':
        data = filteredData || generateTableData(Object.entries(interventionScores));
        break;
      case 'not started':
        data = filteredData || generateTableData(Object.entries(notStarted));
        break;
      default:
        data = undefined;
    }
    if (data) {
      data = data.map((item, index) => ({ ...item, key: index.toString() }));
    }
  
    return data;
  };

  const generateTableData = (dataEntries: [string, any][]) => {
    return dataEntries.map(([key, value]) => {
      const [name, rollnumber] = key.split('-');
      const marks = Array.isArray(value) ? value[0] : 0;
      const enrollmentId = Array.isArray(value) ? value[1] : null;
      return { name, rollnumber, marks, enrollmentId };
    });
  };

  const columns = (activeTab !== 'not started' && activeTab !== 'overall') ? [
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: (a: any, b: any) => a.name.localeCompare(b.name) },
    { title: 'Roll Number', dataIndex: 'rollnumber', key: 'rollnumber' },
    { title: 'Marks', dataIndex: 'marks', key: 'marks', sorter: (a: any, b: any) => a.marks - b.marks },
    {
      title: 'Action',
      key: 'action',
      render: (record: { enrollmentId: number }) => (
        <Button type="primary" onClick={() => handleButtonClicked(record.enrollmentId)}>
          Click me
        </Button>
      ),
    }
  ] : [
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: (a: any, b: any) => a.name.localeCompare(b.name) },
    { title: 'Roll Number', dataIndex: 'rollnumber', key: 'rollnumber', sorter: (a: any, b: any) => a.rollnumber.localeCompare(b.rollnumber) },
    { title: 'Marks', dataIndex: 'marks', key: 'marks', sorter: (a: any, b: any) => a.marks - b.marks }
  ];

 
  const onSearch = (searchInput: string) => {
    let query = ''
    if (searchInput !== '') {
      query = searchInput.trim();
      const regex = new RegExp(query, 'i');
      const filteredOverviewData = generateTableData(Object.entries({ ...excellentScores, ...goodScores, ...satisfactoryScores, ...interventionScores, ...otherScores, ...notStarted })).filter(item => regex.test(item.name) || regex.test(item.rollnumber));
      setFilteredData(filteredOverviewData);
    } else {
      setFilteredData(undefined);
    }
  }

  const goToResult = async (enrollmentDetailId: number, testid: number, submoduleid: number, testType: string) => {
    setLoading(true);
    setSubmoduleid(submoduleid);
    setTestid(testid);

    const queryParams = {
      enrollmentId: enrollmentDetailId?.toString() ?? '',
      moduleId: selectedSubmoduleId?.toString() ?? '',
      testid: selectedTestId?.toString() ?? '',
      submoduleid: selectedSubmoduleId?.toString() ?? '',
      testType: testType?.toString() ?? ''
    };

    try {
      if (testType === 'Multi Choice') {
        await getMcqTestResults(+enrollmentDetailId, +queryParams.testid)
          .then(res => {
            setMcQuestionResult(res);
            const newTab = window.open();
            if (newTab) {
              newTab.document.write(`
                <!DOCTYPE html>
                <html>
                <head>
                  <title>New Tab</title>
                  <link rel="icon" href="YM FavIcon.png" />
                  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/antd/4.0.0/antd.min.css" />
                  <style>
                    body {
                      background-color: #f0f0f0;
                      font-family: Arial, sans-serif;
                    }
                  </style>
                </head>
                <body>
                  <div id="root"></div>
                </body>
                </html>
              `);
              newTab.document.close();
               const root = ReactDOM.createRoot(newTab.document.getElementById('root') as HTMLElement);
              root.render(<ResponseInNewTab codingQuestionTestDetail={res} testType={testType} />);
              const titleElement = newTab.document.head.querySelector('title');
              if (titleElement) {
                titleElement.innerText = 'Answer Viewing part';
              } else {
                
              }
            }
          })
          .catch(err => {});
        setSubmoduleid(submoduleid);
        setTestid(testid);
      }

      if (testType === 'Coding') {
        await getCodingTestResults(+queryParams.enrollmentId, +queryParams.moduleId, +queryParams.testid)
          .then(res => {
            setCodingQuestionTestDetail(res);
            const newTab = window.open();
            if (newTab) {
              newTab.document.write(`
              <!DOCTYPE html>
              <html>
              <head>
                <title>New Tab</title>
                <link rel="icon" href="YM FavIcon.png" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/antd/4.0.0/antd.min.css" />
                <style>
                  body {
                    background-color: #f0f0f0;
                    font-family: Arial, sans-serif;
                  }
                </style>
              </head>
              <body>
                <div id="root"></div>
              </body>
              </html>
              `);
              newTab.document.close();
              const root = ReactDOM.createRoot(newTab.document.getElementById('root') as HTMLElement);
              root.render(<ResponseInNewTab codingQuestionTestDetail={res} testType={testType} />);
              const titleElement = newTab.document.head.querySelector('title');
              if (titleElement) {
                titleElement.innerText = 'Answer Viewing part';
              } else {
               
              }
            }
          })
          .catch(err =>{});
        setSubmoduleid(submoduleid);
        setTestid(testid);
      }

    } catch (error) {
    }
    finally {
      setLoading(false);
    }
  };

 



  
  
  

  const items = [
    {
      key: 'overall',
      label: 'Overall',
      children: (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col style={{ flex: 1, textAlign: 'left', fontSize: '18px' }}>
              Total Students: <span style={{ color: 'green' }}>{totalStudents}</span>
            </Col>
            <Col style={{ flex: 1, textAlign: 'right' }}>
              <Search placeholder="Search" enterButton onSearch={onSearch} style={{ width: 250 }} />
            </Col>
          </div>
          <br />
          <Card className={styles.card}>
            <Table dataSource={renderTableData()} columns={columns} />
          </Card>
        </>
      ),
    },
    {
      key: 'excellent',
      label: 'Excellent',
      children: (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col style={{ flex: 1, textAlign: 'left', fontSize: '18px' }}>
              Total Students: <span style={{ color: 'green' }}>{excellentScores ? Object.keys(excellentScores).length : 0}</span>
            </Col>
            <Col style={{ flex: 1, textAlign: 'right' }}>
              <Search placeholder="Search" enterButton onSearch={onSearch} style={{ width: 250 }} />
            </Col>
          </div>
          <br />
          <Card className={styles.card}>
            <Table dataSource={renderTableData()} columns={columns} />
          </Card>
        </>
      ),
    },
    {
      key: 'good',
      label: 'Good',
      children: (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col style={{ flex: 1, textAlign: 'left', fontSize: '18px' }}>
              Total Students: <span style={{ color: 'green' }}>{goodScores ? Object.keys(goodScores).length : 0}</span>
            </Col>
            <Col style={{ flex: 1, textAlign: 'right' }}>
              <Search placeholder="Search" enterButton onSearch={onSearch} style={{ width: 250 }} />
            </Col>
          </div>
          <br />
          <Card className={styles.card}>
            <Table dataSource={renderTableData()} columns={columns} />
          </Card>
        </>
      ),
    },
    
    {
      key: 'satisfactory',
      label: 'Satisfactory',
      children: (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col style={{ flex: 1, textAlign: 'left', fontSize: '18px' }}>
              Total Students: <span style={{ color: 'green' }}>{satisfactoryScores ? Object.keys(satisfactoryScores).length : 0}</span>
            </Col>
            <Col style={{ flex: 1, textAlign: 'right' }}>
              <Search placeholder="Search" enterButton onSearch={onSearch} style={{ width: 250 }} />
            </Col>
          </div>
          <br />
          <Card className={styles.card}>
            <Table dataSource={renderTableData()} columns={columns} />
          </Card>
        </>
      ),
    },
    {
      key: 'intervention',
      label: 'Intervention',
      children: (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col style={{ flex: 1, textAlign: 'left', fontSize: '18px' }}>
              Total Students: <span style={{ color: 'green' }}>{interventionScores ? Object.keys(interventionScores).length : 0}</span>
            </Col>
            <Col style={{ flex: 1, textAlign: 'right' }}>
              <Search placeholder="Search" enterButton onSearch={onSearch} style={{ width: 250 }} />
            </Col>
          </div>
          <br />
          <Card className={styles.card}>
            <Table dataSource={renderTableData()} columns={columns} />
          </Card>
        </>
      ),
    },
    {
      key: 'not started',
      label: 'Not Started',
      children: (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col style={{ flex: 1, textAlign: 'left', fontSize: '18px' }}>
              Total Students: <span style={{ color: 'green' }}>{notStarted ? Object.keys(notStarted).length : 0}</span>
            </Col>
            <Col style={{ flex: 1, textAlign: 'right' }}>
              <Search placeholder="Search" enterButton onSearch={onSearch} style={{ width: 250 }} />
            </Col>
          </div>
          <br />
          <Card className={styles.card}>
            <Table dataSource={renderTableData()} columns={columns} />
          </Card>
        </>
      ),
    },
    {
      key: 'analysis',
      label: 'Analysis',
      children: (
        <>
         <div className='pie-chart-container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className='pie-chart' style={{ width: '45%', height: '300px' }}>
            <Pie data={analysisData} options={options} />
          </div>
          <div className='pie-chart' style={{ width: '45%', height: '300px' }}>
            <Doughnut data={analysisData} />
          </div>
        </div>
        <br /><br /><br />
        <div>
          <Card>
        <Table
             
              columns={tableColumns}
              dataSource={generateAnalysisTableData()}
              bordered
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
            </Card>
        </div>  
        </>
      ),
    },

  ];
  
  return (
    <Modal
    title="Student Categories"
    open={visible}
    width={1300}
    onCancel={handleCloseModal}
    footer={null}
    className={styles.modalContent}
    style={{ borderRadius: '8px', backgroundColor: 'transparent !important' }}
    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    bodyStyle={{ border: 'none', boxShadow: 'none', borderRadius: '8px', maxHeight: '75vh', overflowY: 'auto' }}
    forceRender
  >
    <Tabs activeKey={activeTab} onChange={handleTabChange} items={items} />
    {loading && (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Spin size="large" />
      </div>
    )}
  </Modal>
  );
};

