import { Tabs, TabsProps } from "antd"
import { ContentLayout } from "../../../components/layout/ContentLayout";
import { CourseDetails } from "../components/CourseDetails";
import { CourseModules } from "../components/CourseModules";
import { CourseStudents } from "../components/CourseStudents";
import { useSearchParams } from "react-router-dom";



export const CourseForm = () => {

    const [searchParams] = useSearchParams();
    const mode = searchParams.get('mode');
    const formMode = (mode && mode !== '') ? mode : 'Add';
    const id = searchParams.get('id');
    const courseId = (id && id !== '') ? +id : 0;

    const cid = searchParams.get('collegeId');
    const collegeId = (cid && cid !== '') ? +cid : 0;

    const items: TabsProps['items'] = [
        {
            key: 'DETAIL',
            label: 'Course Details',
            children: <CourseDetails courseId={courseId} mode={formMode} />,
        },
        {
            key: 'MODULE',
            label: 'Modules and Sub Modules',
            children: <CourseModules courseId={courseId} />,
            disabled: (courseId === 0) 
        },
        {
            key: 'ENROLL',
            label: 'Enroll Students',
            children: <CourseStudents courseId={courseId} collegeId={collegeId} />,
            disabled: (courseId === 0) 
        },
    ];

    return (
        <ContentLayout title="Course">
            <Tabs defaultActiveKey="1" items={items} />
        </ContentLayout>
    )
}