import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { Test } from '../types';

import { ContentLayout } from '../../../components/layout/ContentLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createTest } from '../api/createTest';
import { updateTest } from '../api/updateTest';
import { getTestById } from '../api/getTestById';
import TextArea from 'antd/es/input/TextArea';
import { SOLUTION_LANGUAGES } from '../../../utils/constants';


export const TestForm = () => {

    const navigate = useNavigate();
    const [data, setData] = useState<Test>();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [showSolutionLanguages, setShowSolutionLanguages] = useState(false);

    const [searchParams] = useSearchParams();
    const mode = searchParams.get('mode');
    const id = searchParams.get('id');
    const tid = (id !== null && id !== '') ? +id : 0;

    const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

    const solutionLanguages = SOLUTION_LANGUAGES;

    useEffect(() => {
        if (tid !== 0) {
            setLoading(true);
            getTestById(tid).then((res) => {
                setData(res);
                setLoading(false);
            }, err => {
                setLoading(false);
            });
        }
    }, [tid]);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({ ...data });
            const selectedLanguages = data.solutionLanguages?.split(',') || [];
            setSelectedLanguages(selectedLanguages);
            form.setFieldValue("solutionLanguages", selectedLanguages);
    
            if (data.sectionName === 'Coding') {
                setShowSolutionLanguages(true);
            } else {
                setShowSolutionLanguages(false);
            }
        }
    }, [data, form]);
    

    const save = (data: Test) => {
        setLoading(true);
        data.solutionLanguages = selectedLanguages && selectedLanguages.length > 0 ? selectedLanguages.join(',') : '';
        createTest(data).then((res) => {
            setLoading(false);
            form.resetFields();
            navigate('/tests');
        }, err => {
            setLoading(false);
        });
    }

    const update = (data: Test) => {
        data.id = tid;
        setLoading(true);
        data.solutionLanguages = selectedLanguages.length > 0 ? selectedLanguages.join(',') : data.solutionLanguages;
        updateTest(data).then((res) => {
            setLoading(false);
            navigate('/tests');
        }, err => {
            setLoading(false);
        });
    };
    
    

    return (
        <ContentLayout title={mode === 'Add' ? 'Create Test' : 'Edit Test'}>
            <Form
                form={form}
                layout="vertical"
                onFinish={mode === 'Add' ? save : update}
                autoComplete="off"
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter name' }, { max: 100, message: 'Name should not be greater than 100 chars' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="testCode"
                            label="Test Code"
                            rules={[{ required: true, message: 'Please enter test code' }, { max: 25, message: 'Test Code should not be greater than 25 chars' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            name="sectionName"
                            label="Section"
                            rules={[{ required: true, message: 'Please select section' }]}
                        >
                            <Select
                                options={[
                                    { value: 'Multi Choice', label: 'Multi Choice' },
                                    { value: 'Coding', label: 'Coding' }
                                ]}
                                onChange={(value) => {
                                    if (value === 'Coding') {
                                        setShowSolutionLanguages(true);
                                    } else {
                                        setShowSolutionLanguages(false);
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6} >
                        <Form.Item
                            name="duration"
                            label="Duration (In Mins)"
                            rules={[{ required: true, message: 'Please enter duration' }]}
                        >
                            <Input type='number' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="tags"
                            label="Tags"
                            rules={[{ max: 100, message: 'Tags should not be greater than 100 chars' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} >
                    {showSolutionLanguages && (
                        <Col span={12}>
                            <Form.Item
                                name="solutionLanguages"
                                label="Solution Languages"
                                rules={[{ required: true, message: 'Please select at least one language' }]}
                            >
                                {/* <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Select solution languages"
                                    onChange={(selectedValues) => setSelectedLanguages(selectedValues)}
                                >
                                    {solutionLanguages.map((language) => (
                                        <Select.Option key={language} value={language}>
                                            {language}
                                        </Select.Option>
                                    ))}
                                </Select> */}

                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Select solution languages"
                                    onChange={(selectedValues) => {
                                        const languages = selectedValues.map((value:any) => value);
                                        setSelectedLanguages(languages);
                                    }}
                                >
                                    {solutionLanguages.map((language) => (
                                        <Select.Option key={language} value={language}>
                                            {language}
                                        </Select.Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </Col>)}
                    <Col span={12}>
                        <Form.Item
                            name="description"
                            label="Description"
                            rules={[{ max: 500, message: 'Description should not be greater than 500 chars' }]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} justify='end' >
                    <Col>
                        <Space>
                            {mode === 'Add' ? (
                                <>
                                    <Button htmlType="submit" type="primary" loading={loading} disabled={loading}>
                                        Add
                                    </Button>
                                    <Button htmlType="button" onClick={() => { navigate('/tests') }}>
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                data?.editable ?
                                    <>
                                        <Button htmlType="submit" type="primary" loading={loading} disabled={loading}>
                                            {mode === 'Add' ? 'Save' : 'Update'}
                                        </Button>
                                        <Button htmlType="button" onClick={() => { navigate('/tests') }}>
                                            Cancel
                                        </Button>
                                    </> :
                                    <Button htmlType="button" onClick={() => { navigate('/tests') }}>
                                        Back
                                    </Button>
                            )
                            }
                        </Space>
                    </Col>
                </Row>
            </Form>
        </ContentLayout>
    );
}