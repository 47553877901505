import { Button, Space, Table, Tooltip } from "antd"
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import {
    EditOutlined,
    EyeOutlined,
    ArrowRightOutlined
} from '@ant-design/icons';

import { CQTableParams, CodingQuestion } from "../types";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";

type CodingQuestionListProps = {
    rowCount?: number;
    data?: CodingQuestion[];
    loading: boolean;
    tableParams: CQTableParams;
    updateTableParams: (params: CQTableParams) => void;
    goToForm: (id: number, mode: string) => void;
}

export const CodingQuestionsList = ({ rowCount, data, loading, tableParams, updateTableParams, goToForm }: CodingQuestionListProps) => {

    const columns: ColumnsType<CodingQuestion> = [
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
            showSorterTooltip: false,
            width: 250,
            render: (id, data) => {
                return <div style={{ display: 'flex', direction: 'ltr', gap: '5px', alignContent: 'center' }}>{data.name} {data.editable === false ? <div className="circle"> </div> : ''}</div>
            }
        },
        {
            key: 'category',
            title: 'Category',
            dataIndex: 'category',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'subCategory',
            title: 'Sub Category',
            dataIndex: 'subCategory',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'difficultyLevel',
            title: 'Difficulty Level',
            dataIndex: 'difficultyLevel',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'action',
            title: 'Action',
            dataIndex: 'id',
            align: 'center',
            width: 150,
            render: (id, data) => {
                return (
                    <Space wrap>
                        <Tooltip placement="top" title={data.editable ? "Edit Question" : "View Question"}>
                            <Button icon={data.editable ? <EditOutlined /> : <EyeOutlined />} onClick={() => { goToForm(id, 'Edit') }} />
                        </Tooltip>
                    </Space>
                )
            },

        }
    ];

    const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any, extra: TableCurrentDataSource<CodingQuestion>) => {
        let { sortField, sortOrder, searchInput, qbkId } = tableParams;

        sortField = (sorter?.field) ? sorter.field : 'name';
        sortOrder = (sorter?.order) ? sorter.order : 'ascend';

        updateTableParams({
            pagination,
            sortField,
            sortOrder,
            searchInput,
            qbkId
        });
    };

    return (
        <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={{ ...tableParams.pagination, total: rowCount, showTotal: (rowCount, range) => `${range[0]}-${range[1]} of ${rowCount} items`, defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: [10, 25, 50, 100] }}
            loading={loading}
            size="small"
            onChange={handleTableChange}
        />
    )
}