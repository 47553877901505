import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

interface YearSelectionProps {
  onChange: (year: number) => void;
}

const YearSelection: React.FC<YearSelectionProps> = ({ onChange }) => {
  const currentYear = new Date().getFullYear();
  const yearsArray: number[] = [];

  for (let i = currentYear - 5; i <= currentYear + 5; i++) {
    yearsArray.push(i);
  }

  const handleYearChange = (value: string) => {
    const selectedYear = parseInt(value, 10);
    onChange(selectedYear);
  };

  return (
    <Select defaultValue={currentYear.toString()} onChange={handleYearChange}>
      {yearsArray.map((year) => (
        <Option key={year} value={year.toString()}>
          {year}
        </Option>
      ))}
    </Select>
  );
};

export default YearSelection;
