import { useEffect, useState } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout";
import { TableParams } from "../../../types";
 
import { Button, Card, Col, Empty, Row, Select, Space } from "antd";
import qs from 'qs';
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCodingQueryParams, getMCQQueryParams, getQueryParams } from "../../../utils/queryparams";
import { CQTableParams, CodingQuestion } from "../../codingquestions";
import { getCodingQuestions } from "../../codingquestions/api/getCodingQuestions";
import { MCQTableParams, MultiChoiceQuestion } from "../../mcquestions";
import { getMultiChoiceQuestions } from "../../mcquestions/api/getMultiChoiceQuestions";
import { QuestionBank } from "../../questionbanks";
import { getQuestionBanks } from "../../questionbanks/api/getQuestionBanks";
import { addCodingQuestionToTest } from "../api/addCodingQuestionToTest";
import { addMCQuestionToTest } from "../api/addMCQuestionToTest";
import { getCodingQuestionsOfTest } from "../api/getCodingQuestionsOfTest";
import { getMCQuestionsOfTest } from "../api/getMCQuestionsOfTest";
 
import { CheckCircleOutlined } from '@ant-design/icons';
import { addAllQuestions } from "../api/addAllQuestions";
import { getTestById } from "../api/getTestById";
import { removeAllQuestions } from "../api/removeAllQuestions";
import { removeCodingQuestion } from "../api/removeCodingQuestion";
import { removeMCQuestion } from "../api/removeMCQuestion";
import { Test } from "../types";
 
 
 
export const TestQuestions = () => {
 
    const navigate = useNavigate();
    const [questionbanks, setQuestionBanks] = useState<QuestionBank[]>([]);
    const [questionBankOptions, setQuestionBankOptions] = useState<{ value: number; label: string }[]>([]);
    const [mcQuestions, setMCQuestions] = useState<MultiChoiceQuestion[]>([]);
    const [codingQuestions, setCodingQuestions] = useState<CodingQuestion[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedQuestionBank, setSelectedQuestionBank] = useState<QuestionBank>();
    const [addedMCQuestions, setAddedMCQuestions] = useState<MultiChoiceQuestion[]>([]);
    const [addedCodingQuestions, setAddedCodingQuestions] = useState<CodingQuestion[]>([]);
    const [test, setTest] = useState<Test>();
 
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const type = searchParams.get('type');
    const testId = (id !== null && id !== '') ? +id : 0;
 
    useEffect(() => {
        if (testId !== 0) {
            getTestById(testId).then(res => {
                setTest(res);
            })
        }
        setMCQuestions([]);
        setCodingQuestions([]);
        refresh();
    }, [testId]);
 
    useEffect(() => {
        if (selectedQuestionBank) {
            if (selectedQuestionBank.type === 'Multi Choice') {
                const qbkId = selectedQuestionBank.id;
                setLoading(true);
                let mcqTableParams: MCQTableParams = {
                    pagination: {
                        current: 1,
                        pageSize: 999,
                    },
                    sortField: 'name',
                    sortOrder: 'asc',
                    searchInput: '',
                    answers: true,
                    qbkId
                };
                setMCQuestions([]);
                setCodingQuestions([]);
                getMultiChoiceQuestions(qs.stringify(getMCQQueryParams(mcqTableParams))).then((res) => {
                    setLoading(false);
                    if (res && res.content) {
                        setMCQuestions(res.content);
                    }
                }, () => {
                    setLoading(false);
                });
            }
 
            if (selectedQuestionBank.type === 'Coding') {
                const qbkId = selectedQuestionBank.id;
                setLoading(true);
                let cqTableParams: CQTableParams = {
                    pagination: {
                        current: 1,
                        pageSize: 999,
                    },
                    sortField: 'name',
                    sortOrder: 'asc',
                    searchInput: '',
                    qbkId
                };
                setMCQuestions([]);
                setCodingQuestions([]);
                getCodingQuestions(qs.stringify(getCodingQueryParams(cqTableParams))).then((res) => {
                    setLoading(false);
                    if (res && res.content) {
                        setCodingQuestions(res.content);
                    }
                }, () => {
                    setLoading(false);
                });
            }
        }
    }, [selectedQuestionBank]);
 
    const handleQuestionBankSearch = (searchInput: string) => {
        setQuestionBankOptions([]);
        if (searchInput !== '') {
            const query = `name=like=${searchInput};subject=like=${searchInput};topic=like=${searchInput};subTopic=like=${searchInput}`
            setLoading(true);
            const tableParams: TableParams = {
                pagination: {
                    current: 1,
                    pageSize: 10,
                },
                sortField: 'name',
                sortOrder: 'asc',
                searchInput: query,
                type: type || ''
            };
 
            getQuestionBanks(qs.stringify(getQueryParams(tableParams))).then((res) => {
                setLoading(false);
                if (res && res.content) {
                    setQuestionBanks(res.content)
                    const qbOptions = res.content.map((qb) => {
                        return { value: qb.id, label: qb.name }
                    });
                    setQuestionBankOptions(qbOptions)
                }
            }, () => {
                setLoading(false);
            });
        } else {
            setQuestionBankOptions([]);
        }
    }
 
    const handleQuestionBankSelection = (value: number) => {
        const selectedQB = questionbanks.filter(qb => qb.id === value);
        if (selectedQB) {
            setSelectedQuestionBank(selectedQB[0]);
        }
    }
 
    const addMCQuestion = (qId: number) => {
        addMCQuestionToTest(testId, qId).then(res => {
            getMCQuestionsOfTest(testId).then(res => {
                setAddedMCQuestions(res);
            });
        });
    }
 
    const addCodingQuestion = (qId: number) => {
        addCodingQuestionToTest(testId, qId).then(res => {
            getCodingQuestionsOfTest(testId).then(res => {
                setAddedCodingQuestions(res);
            });
        });
    }
 
    const removeMCQuestionFromTest = (qId: number) => {
        removeMCQuestion(testId, qId).then(res => {
            getMCQuestionsOfTest(testId).then(res => {
                setAddedMCQuestions(res);
            });
        });
    }
 
    const removeCodingQuestionFromTest = (qId: number) => {
        removeCodingQuestion(testId, qId).then(res => {
            getCodingQuestionsOfTest(testId).then(res => {
                setAddedCodingQuestions(res);
            });
        });
    }
 
    const removeAllQuestionsFromTest = () => {
        removeAllQuestions(testId).then(res => {
            refresh();
        })
    }
 
 
    const addAllQuestionsToTest = () => {
        if (selectedQuestionBank) {
            addAllQuestions(testId, selectedQuestionBank.id).then(res => {
                refresh();
            })
        }
    }
 
    const refresh = () => {
        if (type === 'Multi Choice') {
            getMCQuestionsOfTest(testId).then(res => {
                setAddedMCQuestions(res);
            });
        }
 
        if (type === 'Coding') {
            getCodingQuestionsOfTest(testId).then(res => {
                setAddedCodingQuestions(res);
            });
        }
    }
 
    return (
        <ContentLayout title="Test Questions">
            <Row gutter={16}>
                {test?.editable ? (<Col span={8}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="Search Question Bank"
                        optionFilterProp="children"
                        onChange={handleQuestionBankSelection}
                        onSearch={handleQuestionBankSearch}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={questionBankOptions}
                    />
                </Col>) : null}
 
                <Col span={8} >
                    <Space>
                        {test?.editable ?
                            (<>
                                <Button htmlType="button" type="primary" disabled={!selectedQuestionBank} onClick={() => addAllQuestionsToTest()} >
                                    Add All
                                </Button>
                                <Button htmlType="button" type="primary" onClick={() => removeAllQuestionsFromTest()} >
                                    Remove All
                                </Button>
                            </>) : null
                        }
                        <Button htmlType="button" onClick={() => { navigate('/tests') }} >
                            Back
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row gutter={16}>
                {test?.editable ? (<Col span={12}>
                    <div style={{ marginTop: 15 }}>Select Questions From Question Bank</div>
                    <div style={{ height: 500, border: '1px solid #e6e6e6', borderRadius: '1rem', marginTop: 10, padding: 15, overflowY: 'scroll' }}>
                        {
                            mcQuestions.map(q => (
                                <Card key={q.id} size="small" style={{ marginTop: 10, border: '1px solid #e6e6e6', width: '100%' }}>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Name: </span>{q.name}
                                        </Col>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Difficulty : </span>{q.difficultyLevel}
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Category: </span>{q.category}
                                        </Col>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Sub Category: </span>{q.subCategory}
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={24}>
                                            <span style={{ fontWeight: 500 }}>Question: </span><div style={{ border: '1px solid #e6e6e6', borderRadius: 6, padding: 10, backgroundColor: '#e6e6e6' }} dangerouslySetInnerHTML={{
                                                __html: q.question || "",
                                            }}></div>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={24}>
                                            <span style={{ fontWeight: 500 }}>Answers: </span>
 
                                            {q.answers.map((a, index) => (
                                                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span dangerouslySetInnerHTML={{ __html: `<strong>${index + 1}.</strong>` }} style={{ marginRight: '8px' }} />
                                                    <span dangerouslySetInnerHTML={{ __html: a.answer || "" }} style={{ flex: 1 }} />
                                                    {a.correct && <CheckCircleOutlined style={{ color: 'green', fontSize: '20px', marginLeft: '8px' }} />}
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col span={8}>
                                            <Button type="primary" onClick={() => { addMCQuestion(q.id) }} >Add</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            ))
                        }
                        {
                            codingQuestions.map(q => (
                                <Card key={q.id} size="small" style={{ marginTop: 10, border: '1px solid #e6e6e6', width: '100%' }}>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Name: </span>{q.name}
                                        </Col>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Difficulty : </span>{q.difficultyLevel}
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Category: </span>{q.category}
                                        </Col>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Sub Category: </span>{q.subCategory}
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={24}>
                                            <span style={{ fontWeight: 500 }}>Question: </span><div style={{ border: '1px solid #e6e6e6', borderRadius: 6, padding: 10, backgroundColor: '#e6e6e6' }} dangerouslySetInnerHTML={{
                                                __html: q.problemStatement || "",
                                            }}></div>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col span={8}>
                                            <Button type="primary" onClick={() => { addCodingQuestion(q.id) }} >Add</Button>
                                        </Col>
                                    </Row>
 
                                </Card>
                            ))
                        }
                    </div>
                </Col>) : null}
 
                <Col span={12}>
                    <div style={{ marginTop: 15 }}>Selected Questions</div>
                    <div style={{ height: 500, border: '1px solid #e6e6e6', borderRadius: '1rem', marginTop: 10, padding: 15, overflowY: 'scroll' }}>
                        {
                            addedMCQuestions.map(q => (
                                <Card key={q.id} size="small" style={{ marginTop: 10, border: '1px solid #e6e6e6', width: '100%', padding: 10 }}>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Name: </span>{q.name}
                                        </Col>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Difficulty : </span>{q.difficultyLevel}
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Category: </span>{q.category}
                                        </Col>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Sub Category: </span>{q.subCategory}
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={24}>
                                            <span style={{ fontWeight: 500 }}>Question: </span><div style={{ border: '1px solid #e6e6e6', borderRadius: 6, padding: 10, backgroundColor: '#e6e6e6' }} dangerouslySetInnerHTML={{
                                                __html: q.question || "",
                                            }}></div>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={24}>
                                            <span style={{ fontWeight: 500 }}>Answers: </span>
 
                                            {q.answers && q.answers.map((a, index) => (
                                                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span dangerouslySetInnerHTML={{ __html: `<strong>${index + 1}.</strong>` }} style={{ marginRight: '8px' }} />
                                                    <span dangerouslySetInnerHTML={{ __html: a.answer || "" }} style={{ flex: 1 }} />
                                                    {a.correct && <CheckCircleOutlined style={{ color: 'green', fontSize: '20px', marginLeft: '8px' }} />}
                                                </div>
                                            ))}

                                        </Col>
                                    </Row>
                                    {test?.editable ? (
                                        <Row>
                                            <Col span={8}>
                                                <Button type="primary" onClick={() => removeMCQuestionFromTest(q.id)} >Remove</Button>
                                            </Col>
                                        </Row>) : null}
                                </Card>
                            ))
                        }
                        {
                            addedCodingQuestions.map(q => (
                                <Card key={q.id} size="small" style={{ marginTop: 10, border: '1px solid #e6e6e6', width: '100%' }}>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Name: </span>{q.name}
                                        </Col>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Difficulty : </span>{q.difficultyLevel}
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Category: </span>{q.category}
                                        </Col>
                                        <Col span={12}>
                                            <span style={{ fontWeight: 500 }}>Sub Category: </span>{q.subCategory}
                                        </Col>
                                    </Row>
                                    <Row style={{ width: '100%', marginBottom: 12 }}>
                                        <Col span={24}>
                                            <span style={{ fontWeight: 500 }}>Question: </span><div style={{ border: '1px solid #e6e6e6', borderRadius: 6, padding: 10, backgroundColor: '#e6e6e6' }} dangerouslySetInnerHTML={{
                                                __html: q.problemStatement || "",
                                            }}></div>
                                        </Col>
                                    </Row>
                                    {test?.editable ? (
                                        <Row><Col span={8}>
                                            <Button type="primary" onClick={() => removeCodingQuestionFromTest(q.id)} >Remove</Button>
                                        </Col></Row>
                                    ) : null}
                                </Card>
                            ))
                        }
 
                        {addedCodingQuestions.length === 0 && addedMCQuestions.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : ''}
                    </div>
                </Col>
            </Row>
        </ContentLayout>
    )
}