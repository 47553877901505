import { type } from "os";
import { CQTableParams } from "../features/codingquestions";
import { CourseStudentTableParams, MCQTableParams } from "../features/mcquestions";
import { TableParams } from "../types";

export const getQueryParams = (params: TableParams) => ({
    limit: params.pagination?.pageSize,
    offset: params.pagination?.current,
    orderBy: params.sortField,
    order: params.sortOrder,
    searchInput: params.searchInput,
    type: params.type,
});

export const getMCQQueryParams = (params: MCQTableParams) => ({
    limit: params.pagination?.pageSize,
    offset: params.pagination?.current,
    orderBy: params.sortField,
    order: params.sortOrder,
    searchInput: params.searchInput,
    answers: params.answers,
    qbkId: params.qbkId
});


export const getCodingQueryParams = (params: CQTableParams) => ({
    limit: params.pagination?.pageSize,
    offset: params.pagination?.current,
    orderBy: params.sortField,
    order: params.sortOrder,
    searchInput: params.searchInput,
    qbkId: params.qbkId
});

export const getCourseStudentQueryParams = (params: CourseStudentTableParams) => ({
    limit: params.pagination?.pageSize,
    offset: params.pagination?.current,
    orderBy: params.sortField,
    order: params.sortOrder,
    searchInput: params.searchInput,
    courseId: params.courseId,
    collegeId: params.collegeId
});