import { axios } from "../../../lib/axios";


export const downloadCourseResult = async (courseId: number, selectedSubmodules: string[]) => {
  return await axios.get(`/dashboard/courseresult/export/${courseId}`, {
      params: {
          selectedSubmodules: selectedSubmodules.join(','), // Send selectedSubmodules as a comma-separated list
      },
      responseType: 'blob',
  });
};
