import { Navigate, Outlet } from 'react-router-dom';
import { MainLayout } from '../components/layout/MainLayout';
import { CodingQuestions } from '../features/codingquestions';
import { CodingQuestionForm } from '../features/codingquestions/routes/CodingQuestionForm';
import { Colleges } from '../features/colleges';
import { MultiChoiceQuestions } from '../features/mcquestions';
import { MultiChoiceQuestionForm } from '../features/mcquestions/routes/MultiChoiceQuestionForm';
import { QuestionBanks } from '../features/questionbanks';
import { QuestionBankForm } from '../features/questionbanks/routes/QuestionBankForm';
import { Tests } from '../features/tests';
import { TestForm } from '../features/tests/routes/TestForm';
import { Users } from '../features/users';
import { TestQuestions } from '../features/tests/routes/TestQuestions';
import { Courses } from '../features/courses';
import { CourseForm } from '../features/courses/routes/CourseForm';
import { UserForm } from '../features/users/routes/UserForm';
import { StudentCourses } from '../features/studentcourses';
import { StudentCourseMain } from '../features/studentcourses/routes/StudentCourseMain';
import { StudentMCQ } from '../features/studentcourses/routes/StudentMCQ';
import { StudentCoding } from '../features/studentcourses/routes/StudentCoding';
import { Dashboard } from '../features/studentdashboard';
import { AdminDashboard } from '../features/admindashboard';
import FacultyDashboard from '../features/facultydashboard/routes/FacultyDashboard';
import { StudentCourseResults } from '../features/studentcourses/routes/StudentCourseResults';
import { StudentCodeathons } from '../features/studentcourses/routes/StudentCodeathons';
import { StudentLabTests } from '../features/studentcourses/routes/StudentLabTests';
import { StudentRecriuts } from '../features/studentcourses/routes/StudentRecruits';



const App = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export const adminRoutes = [
  {
    path: '',
    element: <App />,
    children: [
      { path: '/', element: <AdminDashboard /> },
      { path: '/dashboard', element: <AdminDashboard /> },
      { path: '/colleges', element: <Colleges /> },
      { path: '/users', element: <Users /> },
      { path: '/user-form', element: <UserForm /> },
      { path: '/mcquestions', element: <MultiChoiceQuestions /> },
      { path: '/mcquestion-form', element: <MultiChoiceQuestionForm /> },
      { path: '/coding-questions', element: <CodingQuestions /> },
      { path: '/coding-question-form', element: <CodingQuestionForm /> },
      { path: '/questionbanks', element: <QuestionBanks /> },
      { path: '/questionbank-form', element: <QuestionBankForm /> },
      { path: '/tests', element: <Tests /> },
      { path: '/test-form', element: <TestForm /> },
      { path: '/test-questions', element: <TestQuestions /> },
      { path: '/courses', element: <Courses /> },
      { path: '/course-form', element: <CourseForm /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];

export const studentRoutes = [
  {
    path: '',
    element: <App />,
    children: [
      { path: '/', element: <StudentCourses /> },
      { path: '/dashboard', element: <Dashboard /> },
      { path: '/student-courses', element: <StudentCourses /> },
      { path: '/student-codeathon', element: <StudentCodeathons /> },
      { path: '/student-labtest', element: <StudentLabTests /> },
      { path: '/student-recruit', element: <StudentRecriuts /> },
      { path: '/student-course-main', element: <StudentCourseMain /> },
      { path: '/student-course-results', element: <StudentCourseResults /> },
      { path: '/student-mcq-test', element: <StudentMCQ /> },
      { path: '/student-coding-test', element: <StudentCoding /> },
      // { path: '/student-categories', element: <StudentCategories /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];


export const facultyRoutes = [
  {
    path: '',
    element: <App />,
    children: [
      { path: '/', element: <FacultyDashboard /> },
      { path: '/dashboard', element: <FacultyDashboard /> },
      { path: '/mcquestions', element: <MultiChoiceQuestions /> },
      { path: '/mcquestion-form', element: <MultiChoiceQuestionForm /> },
      { path: '/coding-questions', element: <CodingQuestions /> },
      { path: '/coding-question-form', element: <CodingQuestionForm /> },
      { path: '/questionbanks', element: <QuestionBanks /> },
      { path: '/questionbank-form', element: <QuestionBankForm /> },
      { path: '/tests', element: <Tests /> },
      { path: '/test-form', element: <TestForm /> },
      { path: '/test-questions', element: <TestQuestions /> },
      { path: '/courses', element: <Courses /> },
      { path: '/course-form', element: <CourseForm /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];