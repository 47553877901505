import { Spin } from 'antd';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '../lib/auth';

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spin />
        </div>
      }
    >
      <AuthProvider>
        <BrowserRouter>{children}</BrowserRouter>
      </AuthProvider>
    </React.Suspense>
  );
};

