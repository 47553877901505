import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthUser, getUser, LoginCredentialsDTO, loginWithUserNameAndPassword, UserResponse } from '../features/auth';
import storage from '../utils/storage';

interface AuthContextType {
  user?: AuthUser;
  isLoggedIn: boolean;
  loadUser: () => Promise<AuthUser>;
  loginFn: (data: LoginCredentialsDTO) => Promise<AuthUser>;
  logoutFn: () => Promise<void>;
  checkAccess: (allowedRoles: string[]) => boolean;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};

const useAuthProvider = (): AuthContextType => {
  const [user, setUser] = useState<AuthUser | undefined>();
  const isLoggedIn = !!storage.getToken();

  useEffect(() => {
    if (isLoggedIn) {
      getUser().then(res => {
        setUser(res);
      });
    }
  }, [isLoggedIn]);

  const handleUserResponse = async (data: UserResponse): Promise<AuthUser> => {
    const { jwt } = data;

    if (jwt) {
      storage.setToken(jwt);
      const user = await getUser();
      setUser(user);
      return user;
    }

    return {} as AuthUser;
  };

  const loadUser = async (): Promise<AuthUser> => {
    if (isLoggedIn) {
      return getUser();
    }

    return {} as AuthUser;
  };

  const loginFn = async (data: LoginCredentialsDTO): Promise<AuthUser> => {
    const response = await loginWithUserNameAndPassword(data);
    const user = await handleUserResponse(response);
    return user;
  };

  const logoutFn = (): Promise<void> => {
    storage.clearToken();
    window.location.assign(window.location.origin);
    return Promise.resolve();
  };

  const checkAccess = (allowedRoles: string[]): boolean => {
    if (allowedRoles && allowedRoles.length > 0 && user) {
      return allowedRoles.includes(user.role);
    }

    return false;
  };

  return {
    user,
    isLoggedIn,
    loadUser,
    loginFn,
    logoutFn,
    checkAccess,
  };
};
