import { Image, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import {
    UserOutlined,
    BankOutlined,
    CodeOutlined,
    CopyOutlined,
    DashboardOutlined,
    FormOutlined} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { MenuInfo } from "rc-menu/lib/interface";
import { useAuth } from "../../lib/auth";
import { ROLES } from "../../lib/authorization";
import { useEffect, useState } from "react";
import yellowmatics from "../../images/yellowmatics.jpeg"
 
 
 
const SideNav = (props: { sidebarOpen: boolean }) => {
 
    const [current, setCurrent] = useState<string>('')
 
    const navigate = useNavigate();
    const {checkAccess, user} = useAuth();
 
    useEffect(() => {
        if(user?.role === 'ADMIN') {
            setCurrent('colleges')
        }
   
        if(user?.role === 'FACULTY') {
            setCurrent('dashboard')
        }
   
        if(user?.role === 'STUDENT') {
            setCurrent('student-courses')
        }
    }, [user])
 
    const menus = [
        checkAccess([ROLES.FACULTY]) && {
            label: 'Dashboard',
            key: 'dashboard',
            icon: <DashboardOutlined />
        },
        checkAccess([ROLES.ADMIN]) && {
            label: 'Colleges',
            key: 'colleges',
            icon: <BankOutlined />
        },
        checkAccess([ROLES.ADMIN]) && {
            label: 'Users',
            key: 'users',
            icon: <UserOutlined />
        },
        checkAccess([ROLES.ADMIN, ROLES.FACULTY]) && {
            label: 'Question Bank',
            key: 'questionbanks',
            icon: <CodeOutlined />
        },
        checkAccess([ROLES.ADMIN, ROLES.FACULTY]) && {
            label: 'Tests',
            key: 'tests',
            icon: <FormOutlined />
        },
        checkAccess([ROLES.ADMIN, ROLES.FACULTY]) && {
            label: 'Courses',
            key: 'courses',
            icon: <CopyOutlined />
        },
        checkAccess([ROLES.STUDENT]) && {
            label: 'Courses',
            key: 'student-courses',
            icon: <CopyOutlined />
        },
        checkAccess([ROLES.STUDENT]) && {
            label: 'Codeathon',
            key: 'student-codeathon',
            icon: <CodeOutlined />
        },
        checkAccess([ROLES.STUDENT]) && {
            label: 'Lab Test',
            key: 'student-labtest',
            icon: <BankOutlined />
        },
        checkAccess([ROLES.STUDENT]) && {
            label: 'Recriut',
            key: 'student-recruit',
            icon: <FormOutlined />
        }
       
    ].filter(Boolean) as any;
 
    const handleNavigation = (e: MenuInfo) => {
        setCurrent(e.key);
        navigate(`/${e.key}`)
    }
 
    return (
        <Sider trigger={null} collapsible collapsed={props.sidebarOpen} theme="dark">
            <div style={{ minHeight: 60, margin: 16 }}>
                <Image preview={false} alt="YellowMatics"
                    // src="https://yellowmatics.com/wp-content/uploads/2021/06/cropped-logo.png"
                     src={yellowmatics}
                    height="50"
                    width="50"
                />
            </div>
            <Menu
                selectedKeys={[current]}
                theme="dark"
                mode="inline"
                items={menus}
                onClick={handleNavigation}
            />
        </Sider>
    )
}
 
export default SideNav;