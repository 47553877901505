import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from 'antd';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ContentLayout } from '../../../components/layout/ContentLayout';
import { ROLES } from '../../../lib/authorization';
import { TableParams } from '../../../types';
import { getQueryParams } from '../../../utils/queryparams';
import { College } from '../../colleges';
import { getColleges } from '../../colleges/api/getColleges';
import { createUser } from '../api/createUser';
import { getUserById } from '../api/getUserById';
import { updateUser } from '../api/updateUser';
import { PasswordUpdate, User } from '../types';
 
export const UserForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [data, setData] = useState<User>();
  const [department, setDepartment] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [colleges, setColleges] = useState<College[]>([]);
  const [collegeOptions, setCollegeOptions] = useState<any>();
  const [departmentOptions, setDepartmentOptions] = useState<any>();
  const [collegeId, setCollegeId] = useState<number>(0);
  const [selectedCollege, setSelectedCollege] = useState<number>();
  const [selectedDepartment, setSelectedDepartment] = useState<string>('');
  const [changePassword, setChangePassword] = useState(false);
  const [showBatchNo, setShowBatchNo] = useState(false);
  const [showFaculty, setShowFaculty] = useState(false);
  const [role, setRole] = useState<string>('');
 
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const id = searchParams.get('id');
  const uid = (id !== null && id !== '') ? +id : 0;
 
  const roleOptions = [
    { value: ROLES.FACULTY, label: 'FACULTY' },
    { value: ROLES.STUDENT, label: 'STUDENT' },
  ];
 
  useEffect(() => {
    if (uid !== 0) {
      setLoading(true);
      getUserById(uid).then(res => {
        setData(res);
        setLoading(false);
      }, error => {
        setLoading(false);
      });
    }
    getCollegesList();
  }, [uid]);
 
  const getCollegesList = () => {
    const params: TableParams = {
      pagination: {
        current: 1,
        pageSize: 100,
      },
      sortField: 'name',
      sortOrder: 'asc',
      searchInput: '',
    };
 
    getColleges(qs.stringify(getQueryParams(params))).then((res) => {
      setColleges(res.content);
      const collegeOptions = res.content.map((item) => {
        return { value: item.id, label: item.name };
      });
      setCollegeOptions(collegeOptions);
    });
  };
 
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        college: data.collegeId,
      });
  
      const selectedCollege = colleges.find((item) => item.id === data.collegeId);
      if (selectedCollege) {
        setCollegeId(selectedCollege.id);

        const [departmentValue, sectionValue] = data.department.split(' ');
  
        const departments = selectedCollege.departments.split(',').map((item) => {
          return { value: item, label: item };
        });
        setDepartmentOptions(departments);
  
        form.setFieldsValue({
          department: departmentValue,
          section: sectionValue,
        });
  
        handleRoleSelection(data.role);
      }
    }
  }, [data, colleges, form]);
  
 
 
 
  const save = (userData: User) => {
    setLoading(true);
    userData.collegeId = collegeId;
    // userData.department = department;
    userData.department = department + (form.getFieldValue('section') ? ` ${form.getFieldValue('section')}` : '');
    createUser(userData).then(
      (res) => {
        setLoading(false);
        form.resetFields();
        navigate('/users');
      },
      (err) => {
        setLoading(false);
      }
    );
  };
 
  const update = (user: User) => {
    setLoading(true);
 
    user.id = uid;
    user.collegeId = collegeId;
    // user.department = department + (form.getFieldValue('section') ? ` ${form.getFieldValue('section')}` : '');

      let updatedDepartment = form.getFieldValue('department') || data?.department || '';
      const sectionValue = form.getFieldValue('section');
      if (sectionValue) {
        updatedDepartment += ` ${sectionValue}`;
      }
      user.department = updatedDepartment;

 
    const passwordUpdate: PasswordUpdate = {
      password: changePassword ? user.newPassword : '',
      isPasswordUpdate: changePassword,
      isProfileUpdate: false,
    };
 
    if (changePassword && user.newPassword !== user.confirmPassword) {
      setLoading(false);
      toast.error('Passwords do not match.');
      return;
    }
 
    const updatedUser = { ...user, ...passwordUpdate, facultyname: user.facultyname, batchNo: user.batchNo };
 
    updateUser(updatedUser)
      .then((res: any) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
 
 
  const handleCollegeSelection = (value: number) => {
    form.setFieldValue('department', '');
    const college = colleges.filter((item) => {
      return item.id === value;
    });
 
    if (college.length !== 0) {
      setCollegeId(college[0].id);
      const departments = college[0].departments.split(',').map((item) => {
        return { value: item, label: item };
      });
      setDepartmentOptions(departments);
    }
  };
 
  const handleDepartmentSelection = (dept: string) => {
    setDepartment(dept);
  };
 
  const handleRoleSelection = (value: string) => {
    const selectedOption = roleOptions.find((option) => option.value === value);
 
    if (selectedOption) {
      const roleLabel = selectedOption.label;
      setRole(roleLabel);
 
      if (value === ROLES.FACULTY) {
        setShowBatchNo(false);
        setShowFaculty(false);
      } else if (value === ROLES.STUDENT) {
        setShowBatchNo(true);
        setShowFaculty(true);
      }
 
      form.setFieldsValue({ role: roleLabel });
    }
  };
 
const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

const options = alphabet.map(letter => ({
  value: letter,
  label: letter,
}));
 
 
  return (
    <ContentLayout title={mode === 'Add' ? 'Create User' : 'Edit User'}>
      <Form
        form={form}
        layout="vertical"
        onFinish={mode === 'Add' ? save : update}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: 'Please enter name' },
                { max: 100, message: 'Name should not be greater than 100 characters' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please enter email' },
                { max: 200, message: 'Email should not be greater than 200 characters' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[{ max: 20, message: 'Phone should not be greater than 20 characters' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="college"
              label="College"
              rules={[{ required: true, message: 'Please select college' }]}
            >
              <Select
                defaultValue={selectedCollege}
                onSelect={handleCollegeSelection}
                options={collegeOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="department"
              label="Department"
              rules={[{ required: true, message: 'Please select department' }]}
            >
              <Select
                defaultValue={selectedDepartment}
                options={departmentOptions}
                onChange={handleDepartmentSelection}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="section"
              label="Section"
              rules={[{ message: 'Please select section' }]}
            >
               <Select
            showSearch
            allowClear
            options={options}
            filterOption={(input, option) =>
              option ? option.label.toLowerCase().includes(input.toLowerCase()) : false
            }
          />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
                  name="role"
                  label="Role"
                  rules={[{ required: true, message: 'Please select role' }]}
                >
                  <Select
                    options={roleOptions}
                    onChange={(value) => handleRoleSelection(value)}
                  />
                </Form.Item>
              </Col>
 
          
        </Row>


        <Row gutter={16}>

{showFaculty && (
  <Col span={12}>
    <Form.Item
      name="mentor"
      label="Faculty Name"
      rules={[{ max: 250, message: 'Please enter faculty' }]}
    >
      <Input />
    </Form.Item>
  </Col>
)}
{showBatchNo && (
  <Col span={12} offset={0}>
    <Form.Item
      name="batch"
      label="Batch No"
      rules={[{ max: 250, message: 'Please enter batch no' }]}
    >
      <Input />
    </Form.Item>
  </Col>
)}
</Row>



        <Row gutter={16}>
      
          <Col span={12} offset={0}>
            <Form.Item
              name="registerNumber"
              label="Register Number / Faculty Id"
              rules={[
                { required: true, message: 'Please enter register number / faculty id' },
                { max: 25, message: 'Register number / Faculty Id should not be greater than 25 characters' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} offset={0}>
            <Form.Item
              name="linkedIn"
              label="LinkedIn Profile"
              rules={[
                { max: 250, message: 'LinkedIn Profile should not be greater than 250 characters' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>











        
 
      
      
 
        <Row gutter={16} justify='start'>
          <Col span={12} offset={0}>
            <Form.Item
              name="github"
              label="GitHub Profile"
              rules={[
                { max: 250, message: 'GitHub Profile should not be greater than 250 characters' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} offset={0}>
            <Form.Item name="active" label="Active" valuePropName="checked">
              <Checkbox>Active</Checkbox>
            </Form.Item>
          </Col>
        </Row>
 
        {mode === 'Edit' && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="changePassword"
              >
                <Checkbox
                  checked={changePassword}
                  onChange={(e) => setChangePassword(e.target.checked)}
                >
                  Change Password
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        )}
 
        {mode === 'Edit' && changePassword && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[{ required: true, message: 'Please enter new password' }]}
              >
                <Input
                  type="password"
                  placeholder="Please enter new password"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                rules={[{ required: true, message: 'Please confirm new password' }]}
              >
                <Input
                  type="password"
                  placeholder="Please confirm new password"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
 
        {mode !== 'View' ? (
          <Row justify='end'>
            <Col>
              <Space>
                <Button htmlType="submit" type="primary" loading={loading}>
                  Submit
                </Button>
                <Button htmlType="button" onClick={() => { navigate("/users") }}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        ) : ''}
      </Form>
    </ContentLayout>
  );
};
