import {
    MinusOutlined,
    PlusOutlined,
    UploadOutlined
} from '@ant-design/icons';
import { Button, Col, Row, Space, Table, Tooltip, Upload } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import qs from 'qs';
 
import Search from "antd/es/input/Search";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
 
import { useEffect, useState } from "react";
import { getCourseStudentQueryParams } from "../../../utils/queryparams";
import { CourseStudentTableParams } from "../../mcquestions";
import { enrollStudentsToCourse } from "../api/enrollStudentsToCourse";
import { getCourseStudents } from "../api/getCourseStudents";
import { removeStudentFromCourse } from "../api/removeStudentFromCourse";
import { unEnrollStudentsFromCourse } from "../api/unEnrollStudentsFromCourse";
import { uploadStudentsToCourse } from "../api/uploadStudentsToCourse";
import { CourseStudent } from "../types";
 
type CourseStudentsProps = {
    courseId: number;
    collegeId: number;
    data?: CourseStudent[];
}
 
export const CourseStudents = ({ courseId, collegeId }: CourseStudentsProps) => {
 
    const [data, setData] = useState<any>();
    const [searchInput, SetSearchInput] = useState<string>();
    const [rowCount, setRowCount] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState<number[]>();
    const [unenrollFile, setUnenrollFile] = useState<File | null>(null);
 
    const [tableParams, setTableParams] = useState<CourseStudentTableParams>({
        pagination: {
            current: 1,
            pageSize: 100, 
        },
        sortField: 'name',
        sortOrder: 'asc',
        searchInput: '',
        courseId: courseId,
        collegeId: collegeId
    });
 
    const columns: ColumnsType<CourseStudent> = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
            showSorterTooltip: true,
            width: 250
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: true,
            showSorterTooltip: true,
            width: 250
        },
        {
            title: 'Department',
            dataIndex: 'department',
            sorter: true,
            showSorterTooltip: true,
            width: 250
        },
        {
            title: 'Register Number',
            dataIndex: 'registerNumber',
            sorter: true,
            showSorterTooltip: true,
            width: 250
        },
        {
            title: 'Batch Number',
            dataIndex: 'batch',
            sorter: true,
            showSorterTooltip: true,
            width: 250,
            render: (batch) => (batch !== null ? batch : ''),
        },
        {
            title: 'Mentor Name',
            dataIndex: 'mentor',
            sorter: true,
            showSorterTooltip: true,
            width: 250,
            render: (mentor) => (mentor !== null ? mentor : ''),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            align: 'center',
            width: 150,
            render: (id, data) => {
                return (
                    <Space wrap>
                        {data.isTagged === true ? <Tooltip title="Remove Student"><Button onClick={() => removeStudent(data.userId)} icon={<MinusOutlined />} /> </Tooltip> : ''}
                    </Space>
                )
            },
        }
    ];
 
    useEffect(() => {
        fetchData();
    }, [courseId, collegeId, tableParams]);
 
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
            if (selectedRows) {
                const selectedIds = selectedRows.map((d: any) => d.userId)
                setSelectedStudents(selectedIds);
            }
        },
        getCheckboxProps: (record: any) => (
            {
                disabled: record.isTagged === true,
                name: record.name,
            }
        )
    };
 
    const enrollStudents = () => {
        const data = {
            id: courseId,
            studentIds: selectedStudents
        }
        enrollStudentsToCourse(data).then(res => {
            setSelectedStudents([]);
            fetchData();
        });
    }
 
    const removeStudent = (studentId: number) => {
        removeStudentFromCourse(courseId, studentId).then((res) => {
            fetchData();
        })
    }
 
    const onSearch = (searchInput: string) => {
        let query = ''
        if (searchInput !== '') {
            query = `name=like=${searchInput};email=like=${searchInput};department=like=${searchInput};registerNumber=like=${searchInput};batch=like=${searchInput};mentor=like=${searchInput}`
        }
        SetSearchInput(query);
        setTableParams({
            ...tableParams,
            pagination: {
                current: 1,
                pageSize: 10
            },
            searchInput: query
        })
    }
 
 
    const fetchData = () => {
        setLoading(true);
        getCourseStudents(qs.stringify(getCourseStudentQueryParams(tableParams))).then((res) => {
            setRowCount(res.totalElements);
            setLoading(false);
            const sortedData = applySorting(res.content, tableParams.sortField || 'defaultField', tableParams.sortOrder|| 'defaultField');
   
            setData(sortedData);
        }, (err) => {
            setLoading(false);
        });
    };
   
 
 
const applySorting = (data: CourseStudent[], sortField: string, sortOrder: string): CourseStudent[] => {
    if (sortField && sortOrder) {
        const sorter = (a: any, b: any) => {
            const aValue = a[sortField];
            const bValue = b[sortField];
 
            if (sortOrder === 'ascend') {
                return aValue > bValue ? 1 : -1;
            } else {
                return aValue < bValue ? 1 : -1;
            }
        };
 
        return [...data].sort(sorter);
    } else {
        return data;
    }
};
 
    const fileUploadRequest = ({ file, onSuccess, onError }: any) => {
        const formData = new FormData();
        formData.append('file', file);
        uploadStudentsToCourse(courseId, formData).then(res => {
            onSuccess('File successfully uploaded.')
            fetchData();
        }, err => {
            onError('Filed to upload file.')
        });
    };
 
    const unenrollFileUploadRequest = ({ file, onSuccess, onError }: any) => {
        const formData = new FormData();
        formData.append('file', file);
        unEnrollStudentsFromCourse(courseId, collegeId, formData).then(res => {
            onSuccess('File successfully uploaded.')
            fetchData();
        }, err => {
            onError('Filed to upload file.')
        });
    };
 
    const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any, extra: TableCurrentDataSource<CourseStudent>) => {
        let { sortField, sortOrder, searchInput } = tableParams;
        sortField = (sorter?.field) ? sorter.field : 'name';
        sortOrder = (sorter?.order) ? sorter.order : 'ascend';
        setTableParams({
            pagination,
            sortField,
            sortOrder,
            searchInput,
            collegeId,
            courseId
        });
    };
   
   
    return (
        <>
            <Row style={{ marginBottom: 16 }} gutter={[20, 25]} justify='end'>
                <Col>
                    <Button type="primary" disabled={selectedStudents?.length === 0} onClick={() => enrollStudents()} icon={<PlusOutlined />}>Enroll Students</Button>
                </Col>
                <Col>
                    <Search placeholder="Search" enterButton onSearch={onSearch} />
                </Col>
                <Col>
                    <Upload customRequest={fileUploadRequest}>
                        <Button icon={<UploadOutlined />}>Upload Students</Button>
                    </Upload>
                </Col>
                <Col>
                    <Upload customRequest={unenrollFileUploadRequest}>
                        <Button icon={<UploadOutlined />}>Unenroll Students</Button>
                    </Upload>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        rowSelection={{
                            ...rowSelection,
                        }}
                        columns={columns}
                        rowKey={(record) => record.userId}
                        dataSource={data}
                        pagination={{ ...tableParams.pagination, total: rowCount, showTotal: (rowCount, range) => `${range[0]}-${range[1]} of ${rowCount} items`, defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: [10, 25, 50, 100] }}
                        loading={loading}
                        size="small"
                        onChange={handleTableChange}
                    />
                </Col>
            </Row>
        </>
    )
}
