import { Layout } from "antd";
import { Footer } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import SideNav from "../sidenav/SideNav";
import TopBar from "../topbar/TopBar";

type MainLayoutProps = {
    children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
    const testPages = ['/student-mcq-test', '/student-coding-test'];
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();
    const [showMenu, setShowMenu] = useState<boolean>(true);

    const toggleSideBar = () => {
        setSidebarOpen(!sidebarOpen)
    }

    useEffect(() => {
        if (location && testPages.includes(location.pathname)) {
            setShowMenu(false);
        } else {
            setShowMenu(true);
        }
    }, [location.pathname])

    return (
        <Layout>
            {showMenu && showMenu === true ? <SideNav sidebarOpen={sidebarOpen} /> : ''}
            <Layout className="site-layout">
                {showMenu}
                {showMenu && showMenu === true ? <TopBar sidebarOpen={sidebarOpen} toggleSideBar={toggleSideBar} /> : ''}
                {children}
                <Footer style={{ paddingTop: 10, paddingBottom: 10, textAlign: 'center' }}>YellowMatics ©2023</Footer>
            </Layout>
        </Layout>
    );
};