import { axios } from "../../../lib/axios";
import { ApiResponse } from "../../../types";

export const uploadMultiChoiceQuestions = (data: FormData): Promise<ApiResponse> => {
  return axios.post(`/questions/multichoice/bulkUpload`, data, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  });
}
