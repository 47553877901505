import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { QuestionBank } from '../types';
import { ContentLayout } from '../../../components/layout/ContentLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createQuestionBank } from '../api/createQuestionBank';
import { updateQuestionBank } from '../api/updateQuestionBank';
import { getQuestionBankById } from '../api/getQuestionBankById';

export const QuestionBankForm = () => {

    const navigate = useNavigate();
    const [data, setData] = useState<QuestionBank>();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const [searchParams] = useSearchParams();
    const mode = searchParams.get('mode');
    const id = searchParams.get('id');

    const initialAnswers = [{ answer: '', correct: false, position: 1 }, { answer: '', correct: false, position: 2 }, { answer: '', correct: false, position: 3 }, { answer: '', correct: false, position: 4 }]

    const qid = (id !== null && id !== '') ? +id : 0;

    useEffect(() => {
        if (qid !== 0) {
            setLoading(true);
            getQuestionBankById(qid).then((res) => {
                setData(res);
                setLoading(false);
            }, err => {
                setLoading(false);
            })
        }

    }, [searchParams]);

    useEffect(() => {
        form.setFieldsValue({ ...data });
    }, [data, form])

    const save = (data: QuestionBank) => {
        setLoading(true);
        createQuestionBank(data).then((res) => {
            setLoading(false);
            form.resetFields();
            navigate('/questionbanks');
        }, err => {
            setLoading(false);
        });
    }

    const update = (data: QuestionBank) => {
        data.id = qid;
        setLoading(true);
        updateQuestionBank(data).then((res) => {
            setLoading(false);
            navigate('/questionbanks');
        }, err => {
            setLoading(false);
        });
    }

    return (
        <ContentLayout title="Question Bank">
            <Form
                form={form}
                layout="vertical"
                onFinish={mode === 'Add' ? save : update}
                autoComplete="off"
                initialValues={{ answers: initialAnswers }}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter name' }, { max: 100, message: 'Name should not be greater than 100 characters' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="type"
                            label="Type"
                            rules={[{ required: true, message: 'Please select type' }]}
                        >
                            <Select
                                options={[
                                    { value: 'Multi Choice', label: 'Multi Choice' },
                                    { value: 'Coding', label: 'Coding' }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="subject"
                            label="Subject"
                            rules={[{ required: true, message: 'Please enter subject' }, { max: 100, message: 'Subject should not be greater than 100 characters' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="topic"
                            label="Topic"
                            rules={[{ required: true, message: 'Please enter topic' }, { max: 100, message: 'Topic should not be greater than 100 characters' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="subTopic"
                            label="Sub Topic"
                            rules={[{ max: 100, message: 'Sub Topic should not be greater than 100 characters' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} justify='end' >
                    <Col>
                        <Space>
                            {mode === 'Add' ? (
                                <>
                                    <Button htmlType="submit" type="primary" loading={loading} disabled={loading}>
                                        Add
                                    </Button>
                                    <Button htmlType="button" onClick={() => navigate('/questionbanks')}>
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                data?.editable ? (
                                    <>
                                        <Button htmlType="submit" type="primary" loading={loading} disabled={loading}>
                                            Update
                                        </Button>
                                        <Button htmlType="button" onClick={() => navigate('/questionbanks')}>
                                            Cancel
                                        </Button>
                                    </>
                                ) : (
                                    <Button htmlType="button" onClick={() => navigate('/questionbanks')}>
                                        Back
                                    </Button>
                                )
                            )}

                        </Space>
                    </Col>
                </Row>
            </Form>
        </ContentLayout>
    );
}