import { Button, Input, Modal } from "antd";
import { useState } from "react";

interface ConfirmationModalProps {
    visible: boolean;
    onConfirmation: (value: string) => void;
    onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ onCancel, onConfirmation, visible }) => {
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState<string | null>(null);

    const handleComplete = () => {
        if (inputValue && inputValue.trim().toUpperCase() === "END") {
            setError(null);
            onConfirmation(inputValue);
        } else {
            setError("Invalid input. Please enter 'End'.");
        }
    };

    const handleCancel = () => {
        onCancel();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        setError(null);
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleComplete();
        }
    };

    return (
        <>
            <Modal
                title="Please confirm your action."
                onCancel={handleCancel}
                open={visible}
                width={400}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="complete" type="primary" onClick={handleComplete}>
                        Complete
                    </Button>,
                ]}
            >
                <p>If you wish to finish this test, please input the word 'End'.</p>
                <Input
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    className={error ? "error-input" : ""}
                />
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </Modal>
        </>
    );
};

export default ConfirmationModal;
