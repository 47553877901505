import { Button, Space, Table } from "antd"
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import {
    EditOutlined
} from '@ant-design/icons';


import { User } from "../types";
import { TableParams } from "../../../types";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";



type UserListProps = {
    rowCount?: number;
    data?: User[];
    loading: boolean;
    tableParams: TableParams;
    updateTableParams: (params: TableParams) => void;
    goToForm: (id: number, mode: string) => void;
}

export const UsersList = ({ rowCount, data, loading, tableParams, updateTableParams, goToForm }: UserListProps) => {

    const columns: ColumnsType<User> = [
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'email',
            title: 'Email',
            dataIndex: 'email',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'college',
            title: 'College',
            dataIndex: 'collegeName',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'department',
            title: 'Department',
            dataIndex: 'department',
            sorter: true,
            showSorterTooltip: false,
        },
        {
            key: 'role',
            title: 'User Type',
            dataIndex: 'role',
            sorter: true,
            showSorterTooltip: false,
        },
        {
            key: 'action',
            title: 'Action',
            dataIndex: 'id',
            align: 'center',
            width: 150,
            render: (id) => {
                return (
                    <Space wrap>
                        <Button icon={<EditOutlined />} onClick={() => { goToForm(id, 'Edit') }} />
                    </Space>
                )
            },

        }
    ];

    const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any, extra: TableCurrentDataSource<User>) => {

        let { sortField, sortOrder, searchInput } = tableParams;

        sortField = (sorter?.field) ? sorter.field : 'name';
        sortOrder = (sorter?.order) ? sorter.order : 'ascend';

        updateTableParams({
            pagination,
            sortField,
            sortOrder,
            searchInput
        });
    };

    return (
        <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={{ ...tableParams.pagination, total: rowCount, showTotal: (rowCount, range) => `${range[0]}-${range[1]} of ${rowCount} items`, defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: [10, 25, 50, 100] }}
            loading={loading}
            size="small"
            onChange={handleTableChange}
        />
    )
}