import { Button, Space, Table, Tooltip } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import {
    EditTwoTone
} from '@ant-design/icons';

import { College } from '../types';
import { FormMode, TableParams } from '../../../types';
import { FilterValue, TableCurrentDataSource } from 'antd/es/table/interface';


type CollegeListProps = {
    rowCount?: number;
    data?: College[];
    loading: boolean;
    tableParams: TableParams;
    updateTableParams: (params: TableParams) => void;
    handleEdit: (id: number, mode: FormMode) => void
}

export const CollegesList = ({ rowCount, data, loading, tableParams, updateTableParams, handleEdit }: CollegeListProps) => {

    const columns: ColumnsType<College> = [
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'email',
            title: 'Email',
            dataIndex: 'email',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'phone',
            title: 'Phone',
            dataIndex: 'phone',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'departments',
            title: 'Departments',
            dataIndex: 'departments',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'action',
            title: 'Action',
            dataIndex: 'id',
            align: 'center',
            width: 150,
            render: (id) => {
                return (
                    <Space wrap>
                        <Tooltip placement="top" title="Edit College">
                            <Button icon={<EditTwoTone />} onClick={() => { handleEdit(id, 'Edit') }} />
                        </Tooltip>
                    </Space>
                )
            },

        }
    ];

    const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any, extra: TableCurrentDataSource<College>) => {

        let { sortField, sortOrder, searchInput } = tableParams;

        sortField = (sorter?.field) ? sorter.field : 'name';
        sortOrder = (sorter?.order) ? sorter.order : 'ascend';

        updateTableParams({
            pagination,
            sortField,
            sortOrder,
            searchInput
        });
    };

    return (
        <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={{
                ...tableParams.pagination,
                total: rowCount,
                showTotal: (rowCount, range) => `${range[0]}-${range[1]} of ${rowCount} items`,
                defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: [10, 25, 50, 100]
            }}
            loading={loading}
            size='small'
            onChange={handleTableChange}
        />
    )
}