import React, { useState,useEffect } from 'react';
import { Modal, Tabs, Table, Card, Col, Button, notification } from 'antd';
import Search from 'antd/es/input/Search';
import styles from './StudentCategoryModal.module.css';
import { deleteTestForStudents } from '../api/deleteTestForStudents';
import { submitTest } from '../../studentcourses/api/submitTest';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { bulkretaketest } from '../api/bulkretaketest';


interface Props 
{
  visible: boolean;
  onClose: () => void;
  categoriesResponse: CategoriesResponse;
  submoduleId: number;
  testId: number;
  testType: string;
}
 
interface CategoriesResponse 
{
  excellentScores: Record<string, number[]>;
  goodScores: Record<string, number[]>;
  satisfactoryScores: Record<string, number[]>;
  interventionScores: Record<string, number[]>;
  otherScores: Record<string, string>;
  notStarted: Record<string, number>;
}
 
export const ResultAnalysisModal: React.FC<Props> = ({ visible, onClose, categoriesResponse, submoduleId, testId, testType }) => {
  const { excellentScores, goodScores, satisfactoryScores, interventionScores, otherScores, notStarted } = categoriesResponse;
  const [activeTab, setActiveTab] = useState<string>('testattempted');
  const [filteredData, setFilteredData] = useState<{ name: string; rollnumber: string; marks: any; }[] | undefined>(undefined);
  const [disabledEnrollmentIds, setDisabledEnrollmentIds] = useState<number[]>([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
  const [isSubmitted, setIsSubmmited] = useState(false);
  const [forceSubmitEnrollmentIds, setForceSubmitEnrollmentIds] = useState<number[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
 
  const getTableHeight = () => {
    const tableRowHeight = 23;
    const tableData = renderTableData();
    if (!tableData) return 500;
    const rowCount = tableData.length;
    const minHeight = 500;
    const calculatedHeight = tableRowHeight * rowCount;
    return Math.max(calculatedHeight, minHeight);
  };

  useEffect(() => {
    console.log("Selected students:", selectedStudents);
  }, [selectedStudents]);
 
  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setFilteredData(undefined);
  };
 
  const confirmRetake = (submoduleId: any, enrollmentId: any, record: any) => {
    Modal.confirm({
      title: `Are you sure you want to retake the test for ${record.name} (${record.rollnumber})?`,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk() {
        setDisabledEnrollmentIds([...disabledEnrollmentIds, enrollmentId]);
        deleteTestForStudents(submoduleId, enrollmentId)
          .then(() => {
            notification.success({
              message: `Retake Test`,
              description: `The user ${record.name} can retake the test`,
              duration: 3,
            });
          })
          .catch(error => {
            alert(error);
          });
      },
    });
  };
 
  const renderTableData = (): { name: string; rollnumber: string; marks: any }[] | undefined => {
    let data: { name: string; rollnumber: string; marks: any }[] | undefined;
    switch (activeTab) {
      case 'testattempted':
        data = filteredData || generateTableData(Object.entries({ ...excellentScores, ...goodScores, ...satisfactoryScores, ...interventionScores }));
        break;
      case 'not started':
        data = filteredData || generateTableData(Object.entries(notStarted));
        break;
      default:
        data = undefined;
    }
    if (data) {
      data = data.map((item, index) => ({ ...item, key: index.toString() }));
    }
 
    return data;
  };
 
 
  const generateTableData = (dataEntries: [string, any][]) => {
    return dataEntries.map(([key, value]) => {
      const [name, rollnumber] = key.split('-');
      const marks = Array.isArray(value) ? value[0] : 0;
      const enrollmentId = Array.isArray(value) ? value[1] : null;
      return { name, rollnumber, marks, enrollmentId };
    });
  };
 
  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: (a: any, b: any) => a.name.localeCompare(b.name) },
    { title: 'Roll Number', dataIndex: 'rollnumber', key: 'rollnumber' },
    { title: 'Marks', dataIndex: 'marks', key: 'marks', sorter: (a: any, b: any) => a.marks - b.marks },
    activeTab === 'testattempted' && {
      title: 'Action',
      key: 'action',
      render: (record: { enrollmentId: number, name: string, rollnumber: string }) => (
        <>
          <Button
            type="primary"
            onClick={() => handleRetakeClick(submoduleId, record.enrollmentId, record)}
            disabled={disabledEnrollmentIds.includes(record.enrollmentId)}
          >
            Retake Test
          </Button>
 
          &nbsp;&nbsp;&nbsp;
          <Button
            type="primary"
            onClick={() => { handleForceSubmit(record.enrollmentId, record) }}
            disabled={disabledEnrollmentIds.includes(record.enrollmentId) || forceSubmitEnrollmentIds.includes(record.enrollmentId)}
          >
            Force Submit
          </Button>
        </>
      ),
    },
  ].filter(Boolean) as any[];
 
  const handleRetakeClick = async (submoduleId: any, enrollmentId: any, record: any) => {
    try 
    {
      confirmRetake(submoduleId, enrollmentId, record);
    } 
    catch (error) 
    {

    }
  };
 
  const handleForceSubmit = async (enrollmentId: any, record: any) => {
    try {
      setIsSubmmited(true);
      setForceSubmitEnrollmentIds([...forceSubmitEnrollmentIds, enrollmentId]);
      confirmForceSubmit(enrollmentId, record);
    } catch (error) {
      setIsSubmmited(false);
    }
  };
 
  const confirmForceSubmit = async (enrollmentId: any, record: any) => {
      Modal.confirm({
        title: `Are you sure you want to force submit for this ${record.name} (${record.rollnumber})?`,
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk() {
          submitTest(enrollmentId)
            .then(() => {
              notification.success({
                message: `Force Submit`,
                description: `The particular user ${record.name}'s test has been forcefully submitted`,
                duration: 3,
              });
            })
            .catch(error => {
              alert(error);
            });
        },
        onCancel() {
          setForceSubmitEnrollmentIds(forceSubmitEnrollmentIds.filter(id => id !== enrollmentId));
        },
      });
  };
 
  const onSearch = (searchInput: string) => {
    let query = '';
    if (searchInput !== '') {
      query = searchInput.trim();
      const regex = new RegExp(query, 'i');
      let filteredOverviewData: { name: string; rollnumber: string; marks: any }[] = [];
 
      switch (activeTab) {
        case 'testattempted':
          filteredOverviewData = generateTableData(
            Object.entries({ ...excellentScores, ...goodScores, ...satisfactoryScores, ...interventionScores })
          ).filter(item => regex.test(item.name) || regex.test(item.rollnumber));
          break;
        case 'not started':
          filteredOverviewData = generateTableData(Object.entries(notStarted)).filter(
            item => regex.test(item.name) || regex.test(item.rollnumber)
          );
          break;
        default:
          break;
      }
 
      setFilteredData(filteredOverviewData);
    }
    else
    {
      setFilteredData(undefined);
    }
  };
 
 
  const testattemptedStudents = Object.values(categoriesResponse).reduce((total, category) => {
    if (category !== categoriesResponse.notStarted) {
      return total + Object.keys(category).length;
    }
    return total;
  }, 0);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      const selectedIds = selectedRows.map((d) => d.enrollmentId);
      setSelectedStudents(selectedIds);
      console.log("Selected students:", selectedIds);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.isTagged === true,
      name: record.name,
    }),
  };
  
  const handleBulkRetake = () => {
    if (selectedStudents.length > 0) {
      bulkretaketest(submoduleId, selectedStudents)
        .then(() => {
          notification.success({
            message: 'Bulk Retake',
            description: 'The selected students can retake the test',
            duration: 3,
          });
        })
        .catch((error) => {
          notification.error({
            message: 'Bulk Retake Failed',
            description: `Error: ${error.message}`,
            duration: 3,
          });
        });
    } else {
      notification.warning({
        message: 'No Students Selected',
        description: 'Please select at least one student to retake the test',
        duration: 3,
      });
    }
  };
  

  const items = [
    {
      key: 'testattempted',
      label: 'Test Attempted Student',
      children: (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col style={{ flex: 1, textAlign: 'left', fontSize: '18px' }}>
              Total Students: <span style={{ color: 'green' }}>{testattemptedStudents}</span>
            </Col>
            <Col style={{ flex: 1, textAlign: 'right' }}>
              <Search placeholder="Search" enterButton onSearch={onSearch} style={{ width: 250 }} />
            </Col>
            <Button
                type="primary"
                onClick={handleBulkRetake}
                disabled={selectedStudents.length === 0}
              >
                Retake Selected Tests
              </Button>
          </div>
          <br />
          <Card className={styles.card} style={{ height: `${getTableHeight()}px` }}>
            <Table 
             rowSelection={{
              ...rowSelection,
          }}
            dataSource={renderTableData()} 
            columns={columns} 
            />
          </Card>
        </>
      ),
    },
    {
      key: 'not started',
      label: 'Not Started',
      children: (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Col style={{ flex: 1, textAlign: 'left', fontSize: '18px' }}>
              Total Students: <span style={{ color: 'green' }}>{notStarted ? Object.keys(notStarted).length : 0}</span>
            </Col>
            <Col style={{ flex: 1, textAlign: 'right' }}>
              <Search placeholder="Search" enterButton onSearch={onSearch} style={{ width: 250 }} />
            </Col>
          </div>
          <br />
          <Card className={styles.card}>
            <Table dataSource={renderTableData()} columns={columns} />
          </Card>
        </>
      ),
    },
  ];
 
 
  return (
    <>
      <ConfirmationModal
        visible={confirmModalVisible}
        onCancel={() => setConfirmModalVisible(false)}
        onConfirmation={(value) => {
          setConfirmModalVisible(false);
        }}
      />
      <Modal
        title="View Result"
        open={visible}
        width={1300}
        onCancel={onClose}
        footer={null}
        className={styles.modalContent}
        style={{ borderRadius: "8px" }}
        forceRender
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        bodyStyle={{ border: "none", boxShadow: "none", borderRadius: "8px", maxHeight: '75vh', overflowY: 'auto' }}
      >
        <Tabs activeKey={activeTab} onChange={handleTabChange} items={items} />
      </Modal>
    </>
  );
};
 