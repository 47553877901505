import { Button, Modal, Space, Table, Tooltip, Select } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { EditOutlined, CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Course } from "../types";
import { getColleges } from "../../colleges/api/getColleges";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import { TableParams } from "../../../types";
import { useState, useEffect } from "react";
import qs from 'qs';
import { College } from "../../colleges/types";
 
const { Option } = Select;
 
type CourseListProps = {
    rowCount?: number;
    data?: Course[];
    loading: boolean;
    tableParams: TableParams;
    updateTableParams: (params: TableParams) => void;
    goToForm: (id: number, collegeId: number, mode: string) => void;
    handleCloneCourse: (courseId: number, collegeId: number) => void;
};
 
const getQueryParams = (params: TableParams) => ({
    limit: params.pagination?.pageSize,
    offset: params.pagination?.current,
    orderBy: params.sortField,
    order: params.sortOrder,
    searchInput: params.searchInput
});
 
export const CoursesList = ({
    rowCount,
    data,
    loading,
    tableParams,
    updateTableParams,
    goToForm,
    handleCloneCourse,
}: CourseListProps) => {
    const [isCloneModalVisible, setIsCloneModalVisible] = useState(false);
    const [selectedCollegeId, setSelectedCollegeId] = useState<number | null>(null);
    const [courseId, setCourseId] = useState<number>(0);
    const [rowCount1, setRowCount1] = useState<number>(0);
    const [data1, setData1] = useState<College[]>();
    const [loading1, setLoading1] = useState(false);
 
    useEffect(() => {
        fetchData();
    }, []);
 
    const fetchData = () => {
        setLoading1(true);
        getColleges(qs.stringify(getQueryParams(tableParams)))
            .then((res) => {
                setRowCount1(res.totalElements);
                setLoading1(false);
                setData1(res.content);
            })
            .catch((err) => {
                console.error("Error fetching colleges:", err);
                setLoading1(false);
            });
    };
 
    const handleAdminClone = () => {
        if (selectedCollegeId !== null) {
            handleCloneCourse(courseId, selectedCollegeId);
            setIsCloneModalVisible(false);
        }
    };
 
    const columns: ColumnsType<Course> = [
        {
            key: 'name',
            title: 'Course Name',
            dataIndex: 'name',
            sorter: true,
            showSorterTooltip: false,
            width: 250,
            fixed: 'left'
        },
        {
            key: 'courseCode',
            title: 'Course Code',
            dataIndex: 'courseCode',
            sorter: true,
            showSorterTooltip: false,
            width: 250,
            fixed: 'left'
        },
        {
            key: 'collegeName',
            title: 'Campus',
            dataIndex: 'collegeName',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'publishingType',
            title: 'Publishing Type',
            dataIndex: 'publishingType',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'startDate',
            title: 'Start Date',
            dataIndex: 'startDate',
            sorter: true,
            showSorterTooltip: false,
            width: 250,
            render: (id, data) => {
                return moment(data.startDate).format("DD/MM/YYYY");
            }
        },
        {
            key: 'endDate',
            title: 'End Date',
            dataIndex: 'endDate',
            sorter: true,
            showSorterTooltip: false,
            width: 250,
            render: (id, data) => {
                return moment(data.endDate).format("DD/MM/YYYY");
            }
        },
        {
            key: 'action',
            title: 'Action',
            dataIndex: 'id',
            align: 'center',
            width: 150,
            render: (id, data) => {
                return (
                    <Space wrap>
                        <Tooltip placement="top" title="Edit Course">
                            <Button icon={<EditOutlined />} onClick={() => { goToForm(id, data.collegeId, 'Edit') }} />
                        </Tooltip>
                        <Tooltip placement="top" title="Clone Course">
                            <Button icon={<CopyOutlined />} onClick={() => { showConfirmModal(id, data.faculty, data.collegeId) }} />
                        </Tooltip>
                    </Space>
                );
            },
        }
    ];
 
    const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any, extra: TableCurrentDataSource<Course>) => {
        let { sortField, sortOrder, searchInput } = tableParams;
 
        sortField = (sorter?.field) ? sorter.field : 'name';
        sortOrder = (sorter?.order) ? sorter.order : 'ascend';
 
        updateTableParams({
            pagination,
            sortField,
            sortOrder,
            searchInput
        });
    };
 
    const handleSelectChange = (value: number) => {
        setSelectedCollegeId(value);
    };
 
    const showConfirmModal = (courseId: number, isFaculty: boolean, collegeId?: number) => {
        const message = isFaculty ? "faculty side" : "admin side";
        setCourseId(courseId);
 
        if (isFaculty) {
            Modal.confirm({
                title: "Confirm Action",
                content: `Are you sure you want to clone this course?`,
                onOk: () => {
                    if (courseId !== null) {
                        handleCloneCourse(courseId, collegeId || 0);
                    }
                }
            });
        } else {
            setCourseId(courseId);
            setIsCloneModalVisible(true);
            setSelectedCollegeId(collegeId || null);
        }
    };
 
    return (
        <>
            <Table
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={data}
                pagination={{
                    ...tableParams.pagination,
                    total: rowCount,
                    showTotal: (rowCount, range) => `${range[0]}-${range[1]} of ${rowCount} items`,
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: [10, 25, 50, 100]
                }}
                loading={loading}
                size="small"
                onChange={handleTableChange}
            />
            <Modal
                open={isCloneModalVisible}
                onCancel={() => setIsCloneModalVisible(false)}
                onOk={handleAdminClone}
                title="Confirm Action"
                okText="Clone"
                cancelText="Cancel"
            >
                <div>
                    <p>Select the college to clone:</p>
                    <Select
                        showSearch
                        defaultValue={null}
                        style={{ width: 400 }}
                        onChange={(value) => {
                            if (typeof value === 'number') {
                                handleSelectChange(value);
                            }
                        }}
                        filterOption={(input, option) =>
                            option && option.children ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) !== -1 : false
                        }
                    >
                        {data1 && data1
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(college => (
                                <Option
                                    key={college.id}
                                    value={college.id}
                                >
                                    {college.name}
                                </Option>
                            ))
                        }
                    </Select>
                </div>
            </Modal>
        </>
    );
}
 