import React, { useEffect, useState, useRef } from 'react';
import { ContentLayout } from '../../../components/layout/ContentLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassEnd, faList, faPercent, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { faIdCardClip } from '@fortawesome/free-solid-svg-icons';
import { faChalkboardUser } from '@fortawesome/free-solid-svg-icons';
import { Course, CourseStats } from '../../courses';
import { TableParams } from '../../../types';
import { getCourses } from '../../courses/api/getCourses';
import { getQueryParams } from '../../../utils/queryparams';
import qs from 'qs';
import { downloadCourseResult } from '../api/downloadCourseResult';
import { Button, Modal, Spin, Tree, Card } from 'antd';
import {getCollegeDetails} from "../api/getCollegeDetails";
import { getCourseStats } from '../../courses/api/getCourseStatus';
import { getSubmodulesTree } from '../../courses/api/getSubmodulesTree';
import CategoryModal from './CategoryModal';
import {  Table } from 'antd';

 
export const FacultyDashboard: React.FC = () => {
    const [rowCount, setRowCount] = useState<number>(0);
    const [data, setData] = useState<Course[]>();
    const [loading, setLoading] = useState(false);
    const [courseStats, setCourseStats] = useState<CourseStats | null>(null);
    const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
    const [submodulesTree, setSubmodulesTree] = useState<any[]>([]);
    const [selectedSubmodules, setSelectedSubmodules] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submodulesLoading, setSubmodulesLoading] = useState(false);
    const [collegeDetails, setCollegeDetails] = useState<CollegeDetails | null>(null);
    const [secondModalOpen, setSecondModalOpen] = useState(false);
    const [liveStatusModalVisible, setLiveStatusModalVisible] = useState(false);
    const [departmentModalVisible, setDepartmentModalVisible] = useState(false);
 
    useEffect(() => {
        fetchCourseStats();
    }, []);
 
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 100,
        },
        sortField: 'name',
        sortOrder: 'asc',
        searchInput: ''
    });
 
    const fetchData = () => {
        setLoading(true);
        getCourses(qs.stringify(getQueryParams(tableParams))).then((res) => {
            setRowCount(res.totalElements);
            setLoading(false);
            setData(res.content);
        }, () => {
            setLoading(false);
        });
    };
 
    useEffect(() => {
        fetchData();
    }, [tableParams]);
 
 
    const handleCourseItemClick = async (course: Course) => {
        setSelectedSubmodules([]);
        setSelectedCourse(course);
        setSubmodulesLoading(true);
        await fetchSubmodulesTree(course.id);
        setSubmodulesLoading(false);
        setIsModalOpen(true);
    };
 
    const fetchSubmodulesTree = async (courseId: number) => {
        try {
            const response = await getSubmodulesTree(courseId);
            const treeData = response.map((module) => ({
                key: `module-${module.moduleId}`,
                title: module.moduleName,
                children: module.submodules.map((submodule) => ({
                    key: `submodule-${submodule.submoduleId}`,
                    title: submodule.submoduleName.trim()
                }))
            }));
            setSubmodulesTree(treeData);
            handleExportButtonClick();
        } catch (error) {
            console.error("Error fetching submodule tree:", error);
        }
    };
 
    const handleSubmoduleSelect = (
        selectedKeys: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] },
        info: any
    ) => {
        if ('checkedNodes' in info) {
            const checkedKeys = info.checkedNodes.map((node: any) => node.key.toString());
            const deselectedKeys = selectedSubmodules.filter((key) => !checkedKeys.includes(key));
            const updatedSelectedSubmodules = [...selectedSubmodules];
            deselectedKeys.forEach((key) => {
                const index = updatedSelectedSubmodules.indexOf(key);
                if (index !== -1) {
                    updatedSelectedSubmodules.splice(index, 1);
                }
            });
 
            updatedSelectedSubmodules.push(...checkedKeys);
 
            setSelectedSubmodules(updatedSelectedSubmodules);
        } else if (Array.isArray(selectedKeys)) {
            setSelectedSubmodules(selectedKeys.map((key) => key.toString()));
        }
    };
 
    const handleExportButtonClick = () => {
        if (selectedCourse) {
            setIsModalOpen(true);
        }
    };
 
    const handleExcelDownload = async (courseId: number, courseName: string, selectedSubmodules: string[]) => {
        try {
            const response: any = await downloadCourseResult(courseId, selectedSubmodules);
            const blobData: BlobPart[] = [response];
            const blob = new Blob(blobData, {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
 
            const url = window.URL.createObjectURL(blob);
 
            const link = document.createElement('a');
            link.href = url;
            link.download = `${courseName}_results.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
 
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error exporting Excel:", error);
        }
    };
 
    // const fetchCourseStats = () => {
    //     Promise.all([getCourseStats(), getCollegeDetails()])
    //     .then(([CourseStats, CollegeDetails]) => {
    //         setCourseStats(CourseStats);
    //         setCollegeDetails(CollegeDetails);
    //     })
    //     .catch((error) => {
    //         console.error("Error fetching course stats:", error);
    //     });
    // };
   
 
    const fetchCourseStats = async () => {
        try {
            const [courseStatsResponse, collegeDetailsResponse] = await Promise.all([
                getCourseStats(),
                getCollegeDetails()
            ]);
            const { studentCount, facultyCount, ...rest } = collegeDetailsResponse;
 
            const mappedCollegeDetails: CollegeDetails = {
                studentCount,
                DepartmentCount: 0,
                facultyCount,
                // cse: 0,
                // CSE: 0,
                // EEE: 0,
                // ECE: 0,
                // IT: 0,
                ...rest
            };
 
            setCollegeDetails(mappedCollegeDetails);
            setCourseStats(courseStatsResponse);
        } catch (error) {
            console.error("Error fetching course stats:", error);
        }
    };
   
 
    const handleOk = () => {
        if (selectedCourse && selectedSubmodules.length > 0) {
            const selectedSubmoduleNames = selectedSubmodules
                .filter((key) => key.startsWith('submodule-'))
                .map((key) => {
                    const [, submodule] = key.split('-');
                    return submodule;
                });
 
            handleExcelDownload(selectedCourse.id, selectedCourse.name, selectedSubmoduleNames);
        }
        setIsModalOpen(false);
    };
 
    const handleCancel = () => {
        setSelectedSubmodules([]);
        setIsModalOpen(false);
    };
 
    const handleSelectAll = () => {
        const allSubmoduleKeys = submodulesTree
            .flatMap((module) => module.children.map((submodule: any) => submodule.key));
 
        setSelectedSubmodules(allSubmoduleKeys);
    };
 
    const handleClearAll = () => {
        setSelectedSubmodules([]);
    };
 
 
const [selectedCourseIndex, setSelectedCourseIndex] = useState<number | null>(null);
 
 
const handleSecondModalOpen = (index: number) => {
    setSelectedCourseIndex(index);
     setSecondModalOpen(true);
    setLiveStatusModalVisible(true);
};
 
 
const handleSecondModalClose = () => {
    setSelectedCourseIndex(null);
    setSecondModalOpen(false);
    setLiveStatusModalVisible(false);
};
 
const handleCourseSet = async(course:Course) => {
    setSelectedCourse(course);
  };
 
  const handleStudentCountClick = () => {
    setDepartmentModalVisible(true);
};
 
 
const handleCloseDepartmentModal = () => {
    setDepartmentModalVisible(false);
};
 
interface CollegeDetails {
    [key: string]: number;
  }
 
  const DepartmentColumn = [
    {
      title: 'Department Name',
      dataIndex: 'departmentName',
    },
    {
      title: 'Students Count',
      dataIndex: 'studentCount',
    },
  ];
 
 
  const normalizeDepartmentName = (name: string): string => {
    return name.trim().toUpperCase().replace(/[\s-]+/g, '-');
  };
 
  const departmentData = collegeDetails
    ? Object.entries(collegeDetails)
        .filter(([key]) => !["studentCount", "facultyCount", "DepartmentCount"].includes(key))
        .reduce<CollegeDetails>((acc, [key, count]) => {
          const normalizedKey = normalizeDepartmentName(key);
          if (acc[normalizedKey]) {
            acc[normalizedKey] += count;
          } else {
            acc[normalizedKey] = count;
          }
          return acc;
        }, {})
    : {};
 
  const formattedDepartmentData = Object.entries(departmentData).map(([departmentName, studentCount]) => ({
    departmentName,
    studentCount,
  }));
 
 
    return (
        <ContentLayout title="Dashboard" type="nocard">
            <div className="status-card-container">
                <div className='status-card-row'>
                <div className="status-card shadow">
                    <div className="status-card-content">
                        <div className="status-card-header">
                            TOTAL COURSES
                        </div>
                        <div className="status-card-body">
                            {courseStats?.totalCourses}
                        </div>
                    </div>
                    <div className="status-card-icon bg-yellow text-white rounded-circle shadow-light">
                        <FontAwesomeIcon icon={faList} size="2x" />
                    </div>
                </div>
                <div className="status-card shadow">
                    <div className="status-card-content">
                        <div className="status-card-header">
                            IN PROGRESS
                        </div>
                        <div className="status-card-body">
                            {courseStats?.inProgressCount}
                        </div>
                    </div>
                    <div className="status-card-icon bg-blue text-white rounded-circle shadow-light">
                        <FontAwesomeIcon icon={faPercent} size="2x" />
                    </div>
                </div>
                <div className="status-card shadow">
                            <div className="status-card-content">
                                <div className="status-card-header">
                                    EXPIRED
                                </div>
                                <div className="status-card-body">
                                    {courseStats?.expiredCount}
                                </div>
                            </div>
                            <div className="status-card-icon bg-red text-white rounded-circle shadow-light">
                                <FontAwesomeIcon icon={faHourglassEnd} size="2x" />
                            </div>
                </div>
               
               
 
                </div>
                <div className='status-card-row'>
                    {/* <div className="status-card shadow" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <div className="status-card-content">
                            <div className="status-card-header">
                                STUDENT COUNT
                            </div>
                            <div className="status-card-body">
                                {collegeDetails && collegeDetails.studentCount}
                            </div>
                        </div>
                        <div className="status-card-icon bg-purple text-white rounded-circle shadow-light">
                            <FontAwesomeIcon icon={faCircleUser}  size="2x" />
                        </div>
                    </div>
                    {departmentModel && <DepartmentModal departmentData={collegeDetails} onClose={() => setDepartmentModel(false)} />} */}
                    <div className="status-card-container">
                            <div className='status-card-row'>
 
                            <div className="status-card shadow">
                                <div className="status-card-content">
                                    <div className="status-card-header">
                                    FACULTY COUNT
                                    </div>
                                    <div className="status-card-body">
                                    {collegeDetails && collegeDetails.facultyCount}
                                    </div>
                                </div>
                                <div className="status-card-icon bg-green text-white rounded-circle shadow-light">
                                    <FontAwesomeIcon icon={faIdCardClip} size="2x" />
                                </div>
                            </div>
                                <div className="status-card shadow" onClick={handleStudentCountClick}>
                                    <div className="status-card-content">
                                        <div className="status-card-header">
                                            STUDENT COUNT
                                        </div>
                                        <div className="status-card-body">
                                            {collegeDetails && collegeDetails.studentCount}
                                        </div>
                                    </div>
                                    <div className="status-card-icon bg-purple text-white rounded-circle shadow-light">
                                        <FontAwesomeIcon icon={faCircleUser} size="2x" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Department Modal */}
                        <Modal
                            title="Department Data"
                            open={departmentModalVisible}
                            onCancel={handleCloseDepartmentModal}
                            footer={null}
                        >
                        {/* Add this debug statement to see if collegeDetails is being passed correctly */}
                        {/* <div>College Details: {JSON.stringify(collegeDetails)}</div>*/}
 
                            {/* <table>
                                <thead>
                                    <tr>
                                        <th>Department</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {collegeDetails && Object.entries(collegeDetails)
                                        .filter(([key]) => !["studentCount", "facultyCount", "DepartmentCount"].includes(key))
                                        .map(([department, count]) => (
                                            <tr key={department}>
                                                <td>{department.toUpperCase()}</td>
                                                <td>{count}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table> */}
                            <Card style={{maxHeight: '400px', overflowY: 'auto'}}>
                                <Table dataSource={formattedDepartmentData} columns={DepartmentColumn} pagination={false} />
                            </Card>
                        </Modal>
                <div className="status-card shadow">
                    <div className="status-card-content">
                        <div className="status-card-header">
                            DEPARTMENT COUNT
                        </div>
                        <div className="status-card-body">
                        {collegeDetails && collegeDetails.DepartmentCount}
                        </div>
                    </div>
                    <div className="status-card-icon bg-pink text-white rounded-circle shadow-light">
                        <FontAwesomeIcon icon={faChalkboardUser} size="2x" />
                    </div>
                </div>
            </div>
            </div>
            {/* <div style={{ marginTop: 25, marginBottom: 20, fontSize: 18, fontWeight: 600 }}>
                Courses
            </div> */}
       
            <div className='course-card-row'>
            <div className='course-card shadow' style={{maxHeight:"550px", overflowY:"auto"}}>
                <div className='course-card-header'>
                    Courses
                </div>
                <div className="course-card-body" >
                    <div className="course-list" style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {data?.map((course, index) => (
                            <div
                                key={course.id}
                                onClick={() => handleCourseItemClick(course)}
                                className="course-item"
                            >
                                {course.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
       
        {/* <div className='course-card shadow' style={{maxHeight:"550px", overflowY:"auto"}}>
            <div className='course-card-header'>
                Courses
            </div>
            <div className="course-card-body" >
                <div className="course-list" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {data?.map((course, index) => (
                        <div
                            key={course.id}
                            onClick={() => {
                                 handleSecondModalOpen(index);
                                 setSelectedCourse(course)
                            }}
                            className="course-item"
                        >
                            {course.name}
                        </div>
                    ))}
                </div>
            </div>
        </div> */}
        </div>
            <Modal
                title={
                    <div style={{ marginBottom: 28 }}>
                        <FontAwesomeIcon icon={faFileExcel} className="green-icon" style={{ marginRight: '8px' }} />
                        <span style={{ marginBottom: '80px' }}>Export Excel for {selectedCourse?.name}</span>
                    </div>
                }
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                bodyStyle={{ height: '400px', overflowY: 'auto' }}
                footer={[
                    <Button key="selectAll" onClick={handleSelectAll}>
                        Select All
                    </Button>,
                    <Button key="clearAll" onClick={handleClearAll}>
                        Clear All
                    </Button>,
                    <Button type='primary' key="ok" onClick={handleOk}>
                        OK
                    </Button>,
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                ]}
            >
                {submodulesLoading ? (
                    <Spin size="large" />
                ) : (
                    <Tree
                        checkable
                        onCheck={handleSubmoduleSelect}
                        checkedKeys={selectedSubmodules}
                        treeData={submodulesTree}
                        defaultExpandAll={true}
                    />
                )}
            </Modal>
 
                {/* {selectedCourse && (
                   <FreshCategoryModal
                   visible={liveStatusModalVisible}
                   onClose={() => setLiveStatusModalVisible(false)}
                   courseId={selectedCourse.id}
                   
                    />
                 
                 <CategoryModal
                    visible={liveStatusModalVisible}
                    onClose={() => setLiveStatusModalVisible(false)}
                    courseId={selectedCourse.id}
                />
                // )}
 
                  */}
           
        </ContentLayout >
    );
};
 
export default FacultyDashboard;
 
 
 
 
               