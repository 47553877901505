import { Button, Col, Drawer, Form, Input, Row, Space, Checkbox } from 'antd';
import { useState } from 'react'; // Import useState hook
import { useAuth } from '../../../lib/auth';
import { updateUser } from '../api/updateUser';
import { PasswordUpdate, User } from '../types';
import { toast } from 'react-toastify';

export const UserProfile = (props: { open: boolean, onClose: any }) => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [form] = Form.useForm();

    const initialValues = {
        name: user?.name,
        email: user?.email,
        phone: user?.phone,
        newPassword: '', // Initialize newPassword and confirmPassword with empty strings
        confirmPassword: '', // to avoid validation errors on prepopulated values
    };


    const onClose = () => {
        props.onClose(false);
    };

    const updateProfile = (userDetails: any) => {
        setLoading(true);
        const id = user?.id;

        const passwordUpdate: PasswordUpdate = {
            password: changePassword ? userDetails.newPassword : '',
            isPasswordUpdate: changePassword,
            isProfileUpdate: true,
        };

        // Check if changePassword is true and passwords match
        if (changePassword) {
            if (userDetails.newPassword !== userDetails.confirmPassword) {
                toast.error('Passwords do not match.');
                return;
            }
        }

        // Send the update request
        updateUser({ id, ...userDetails, ...passwordUpdate }).then((res: any) => {
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            toast.error(error.message || 'An error occurred while updating the profile.');
        });
    };

    const onFormClose = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Drawer
            title="User Profile"
            width={720}
            onClose={onClose}
            open={props.open}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={updateProfile}
                autoComplete="off"
                initialValues={initialValues}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter name' }]}
                        >
                            <Input placeholder="Please enter name" defaultValue={user?.name} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="email"
                            label="Email"
                        >
                            <Input placeholder="Please enter email" disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="phone"
                            label="Phone"
                            rules={[{ required: true, message: 'Please enter phone' }]}
                        >
                            <Input placeholder="Please enter phone" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="changePassword">
                            <Checkbox
                                checked={changePassword}
                                onChange={(e) => setChangePassword(e.target.checked)}
                            >
                                Change Password
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>

                {changePassword && ( // Render the "New Password" and "Confirm Password" fields when the checkbox is checked
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="newPassword"
                                label="New Password"
                                rules={[{ required: true, message: 'Please enter new password' }]}
                            >
                                <Input
                                    type="password"
                                    placeholder="Please enter new password"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="confirmPassword"
                                label="Confirm Password"
                                rules={[{ required: true, message: 'Please confirm new password' }]}
                            >
                                <Input
                                    type="password"
                                    placeholder="Please confirm new password"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row justify='end' >
                    <Col>
                        <Space>
                            <Button htmlType="submit" type="primary" loading={loading}>
                                Submit
                            </Button>
                            <Button htmlType="button" onClick={onFormClose}>
                                Cancel
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
};
