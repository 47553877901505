import { useEffect, useState } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout"
import { TableParams } from "../../../types";
import { UsersList } from "../components/UsersList"
import { User } from "../types";
import { getUsers } from "../api/getUsers";
import qs from 'qs';
import { Button, Col, Form, Modal, Row, Select, Space } from "antd";
import {
  PlusOutlined,
  UploadOutlined
} from '@ant-design/icons';
import Search from "antd/es/input/Search";
import { useNavigate } from "react-router-dom";
import { getColleges } from "../../colleges/api/getColleges";
import { College } from "../../colleges";
import { uploadUsers } from "../api/uploadUsers";


const getQueryParams = (params: TableParams) => ({
  limit: params.pagination?.pageSize,
  offset: params.pagination?.current,
  orderBy: params.sortField,
  order: params.sortOrder,
  searchInput: params.searchInput
});

export const Users = () => {

  /* State for UserList */
  const [searchInput, SetSearchInput] = useState<string>();
  const [rowCount, setRowCount] = useState<number>(0);
  const [data, setData] = useState<User[]>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [uploadForm] = Form.useForm();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [colleges, setColleges] = useState<College[]>([] as College[]);
  const [selectedCollegeId, setSelectedCollegeId] = useState<number>();
  const [collegeOptions, setCollegeOptions] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: 'name',
    sortOrder: 'asc',
    searchInput: ''
  });

  useEffect(() => {
    fetchData();
  }, [tableParams]);

  useEffect(() => {
    if (openModal) {
      getCollegesList();
    }
  }, [openModal]);

  /* Functions for UserList */
  const fetchData = () => {
    setLoading(true);
    getUsers(qs.stringify(getQueryParams(tableParams))).then((res) => {
      setRowCount(res.totalElements);
      setLoading(false)
      setData(res.content)
    }, err => {
      setLoading(false)
    });
  };



  const updateTableParams = (params: TableParams) => {
    setTableParams({
      ...params,
      searchInput
    });
  }

  const onSearch = (searchInput: string) => {
    let query = ''
    if (searchInput !== '') {
      query = `name=like=${searchInput};email=like=${searchInput};department=like=${searchInput};college=like=${searchInput}`
    }
    SetSearchInput(query);
    setTableParams({
      ...tableParams,
      searchInput: query
    })
  }

  const goToForm = (id: number, mode: string) => {
    const params = { id, mode };
    navigate({
      pathname: '/user-form',
      search: qs.stringify(params),
    });
  }

  const handleCancel = () => {
    uploadForm.resetFields();
    setOpenModal(false);
  }

  const upload = (data: any) => {
    if (selectedFile && selectedCollegeId) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      uploadUsers(selectedCollegeId, formData).then(res => {
        fetchData();
        uploadForm.resetFields();
        setOpenModal(false);
      });
    }
  }

  const selectFile = (event: any) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  }

  const getCollegesList = () => {
    const params: TableParams = {
      pagination: {
        current: 1,
        pageSize: 100,
      },
      sortField: 'name',
      sortOrder: 'asc',
      searchInput: ''
    }

    getColleges(qs.stringify(getQueryParams(params))).then((res) => {
      setColleges(res.content);
      const collegeOptions = res.content.map((item) => {
        return { value: item.id, label: item.name }
      });
      setCollegeOptions(collegeOptions);
    });
  }

  const handleCollegeSelection = (value: number) => {
    setSelectedCollegeId(value);
  }

  return (
    <>
      <ContentLayout title="Users">
        <Row style={{ marginBottom: 16 }} gutter={[20, 25]} justify='end'>
          <Col>
            <Search placeholder="Search" enterButton onSearch={onSearch} />
          </Col>
          <Col>
            <Button type='primary' icon={<PlusOutlined />} onClick={() => goToForm(0, 'Add')}>Create User</Button>
          </Col>
          <Col>
            <Button icon={<UploadOutlined />} onClick={() => setOpenModal(true)}>Upload Users</Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <UsersList data={data} loading={loading} tableParams={tableParams} rowCount={rowCount} updateTableParams={updateTableParams} goToForm={goToForm} />
          </Col>
        </Row>
      </ContentLayout>
      <Modal
        title="Upload Users"
        open={openModal}
        onCancel={handleCancel}
        footer={null}
        width={400}
      >
        <Form
          form={uploadForm}
          layout="vertical"
          autoComplete="off"
          onFinish={upload}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="college"
                label="College"
                rules={[{ required: true, message: 'Please select college' }]}
              >
                <Select
                  placeholder="Select College"
                  onSelect={handleCollegeSelection}
                  options={collegeOptions}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="file"
                label="Choose File"
                rules={[{ required: true, message: 'Please choose file' }]}
              >
                <input type="file" name="file" onChange={selectFile} />
              </Form.Item>
            </Col>

          </Row>

          <Row justify='end' >
            <Col>
              <Space>
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
                <Button htmlType="button" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
