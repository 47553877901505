import { Button, Card, Checkbox, Col, DatePicker, Form, Input, List, Modal, Row, Select, Space, Table, Tooltip } from "antd"
import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    CaretRightOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
 
import { useEffect, useState } from "react";
import { Course, CourseModule, CourseSubModule } from "../types";
import { getCourseModules } from "../api/getCourseModules";
import { createCourseModule } from "../api/createCourseModule";
import { createCourseSubModule } from "../api/createCourseSubModule";
import { getCourseSubModules } from "../api/getCourseSubModules";
import { deleteCourseModule } from "../api/deleteCourseModule";
import moment from "moment";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import qs from 'qs';
import './CourseModules.css';
import { getCourseModuleById } from "../api/getCourseModuleById";
import { updateCourseModule } from "../api/updateCourseModule";
import { getCourseSubModuleById } from "../api/getCourseSubModuleById";
import { updateCourseSubModule } from "../api/updateCourseSubModule";
import { deleteCourseSubModule } from "../api/deleteCourseSubModule";
import { Test } from "../../tests";
import { TableParams } from "../../../types";
import { getTests } from "../../tests/api/getTests";
import { getQueryParams } from "../../../utils/queryparams";
import { getCourseById } from "../api/getCourseById";
import { getMCQuestionsOfTest } from "../../tests/api/getMCQuestionsOfTest";
import { MultiChoiceQuestion } from "../../mcquestions";
import { CodingQuestion } from "../../codingquestions";
import { getCodingQuestionsOfTest } from "../../tests/api/getCodingQuestionsOfTest";
import { getStudentCategories } from "../api/getStudentCategories";
import { StudentCategoryModal } from "./StudentCategoryModal";
import { ResultAnalysisModal } from "./ResultAnalysisModal";
import { useAuth } from "../../../lib/auth";
 
dayjs.extend(customParseFormat);
 
const { RangePicker } = DatePicker;
 
type CourseModuleProps = {
    courseId: number;
}
 

 
export const CourseModules = ({ courseId }: CourseModuleProps) => {
 
    const [moduleForm] = Form.useForm();
    const [subModuleForm] = Form.useForm();
    const [modules, setModules] = useState<CourseModule[]>([]);
    const [subModules, setSubModules] = useState<CourseSubModule[]>([]);
    const [openModuleModal, setOpenModuleModal] = useState<boolean>(false);
    const [openSubModuleModal, setOpenSubModuleModal] = useState<boolean>(false);
    const [selectedModuleId, setSelectedModuleId] = useState<number>(0);
    const [moduleMode, setModuleMode] = useState<string>('Add');
    const [courseModule, setCourseModule] = useState<CourseModule>();
    const [selectedSubModuleId, setSelectedSubModuleId] = useState<number>(0);
    const [subModuleMode, setSubModuleMode] = useState<string>('Add');
    const [subModule, setSubModule] = useState<CourseSubModule>();
    const [tests, setTests] = useState<Test[]>([]);
    const [testOptions, setTestOptions] = useState<{ value: number; label: string }[]>([]);
    const [selectedTest, setSelectedTest] = useState<Test>();
    const [course, setCourse] = useState<Course>();
    const [dateRangeErr, setDateRangeErr] = useState<boolean>(false);
    const [selectedTestValue, setSelectedTestValue] = useState<any>();
 
    const [visibleMCQModal, setVisibleMCQModal] = useState(false);
    const [visibleCodingModal, setVisibleCodingModal] = useState(false);
    const [mcqQuestions, setMCQQuestions] = useState<MultiChoiceQuestion[]>([]);
    const [codingQuestions, setCodingQuestions] = useState<CodingQuestion[]>([]);

    const [categoriesResponse, setCategoriesResponse] = useState<any>(null);
    const [selectedSubmoduleId, setSelectedSubmoduleId] = useState<number | null>(null); // State to store selected submoduleId


    const [submoduleid, setSubModuleId]=useState<number |null>(null);
    const [testid, setTestId]=useState<number |null>(null);
    const [testType, setTestType]=useState<String |null>(null);

    const [liveStatusModalVisible, setLiveStatusModalVisible] = useState(false);
    const [resultModalVisible, setResultModalVisible] = useState(false);

    const [userTimeZone, setUserTimeZone] = useState<string>(''); 

    
    const {user} = useAuth()
    const openMCQModal = () => setVisibleMCQModal(true);
    const openCodingModal = () => setVisibleCodingModal(true);
    const closeMCQModal = () => setVisibleMCQModal(false);
    const closeCodingModal = () => setVisibleCodingModal(false);
 
    useEffect(() => {
        getCourseModuleList();
    }, [courseId]);
    
 
    useEffect(() => {
        getCourseDetails();
        getCourseSubModuleList();
    }, [selectedModuleId])
 
    useEffect(() => {
        if (courseModule) {
            moduleForm.setFieldsValue({ ...courseModule })
            const start = dayjs.utc(courseModule.startDate).local();
        const end = dayjs.utc(courseModule.endDate).local();
            moduleForm.setFieldValue('moduleValidity', [start, end])
        }
    }, [courseModule])
 
 
    useEffect(() => {
        if (subModule) {
            subModuleForm.setFieldsValue({ ...subModule });
            setTestOptions([{ value: subModule.testId, label: subModule.testName }]);
            setSelectedTestValue(subModule.testId);
        }
    }, [subModule])


    useEffect(() => {
        if (user) {
            const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setUserTimeZone(detectedTimeZone);
        }
    }, [user]);

 
    const getCourseDetails = () => {
        getCourseById(courseId).then(res => {
            setCourse(res);
        });
    }


 
    const getCourseModuleList = () => {
        setModules([]);
        getCourseModules(courseId).then(res => {
            if (res) {
                setModules(res);
            }
        });
        getCourseSubModuleList();
    }
 
    const getCourseSubModuleList = () => {
        setSubModules([]);
        getCourseSubModules(courseId, selectedModuleId).then(res => {
            if (res) {
              
                setSubModules(res);
            }
        });
    };
    
 
    const areDatesInRange = (moduleStart: Date, moduleEnd: Date, courseStart: Date, courseEnd: Date): boolean => {
        const validDateRange = moduleStart >= courseStart && moduleEnd <= courseEnd;
        setDateRangeErr(!validDateRange)
        return validDateRange;
    }
 
 
    const saveModule = (data: CourseModule) => {
        const validity = moduleForm.getFieldValue('moduleValidity');
        data.startDate = validity[0];
        data.endDate = validity[1];
        if (course && areDatesInRange(new Date(data.startDate), new Date(data.endDate), new Date(course?.startDate), new Date(course?.endDate))) {
            createCourseModule(courseId, data).then(res => {
                setOpenModuleModal(false);
                getCourseModuleList();
                moduleForm.resetFields();
            });
        }
 
    }
 
    const deleteModule = (moduleId: number) => {
        deleteCourseModule(moduleId).then(res => {
            getCourseModuleList();
        });
    }
 
    const deleteSubModule = (moduleId: number) => {
        deleteCourseSubModule(moduleId).then(res => {
            getCourseSubModuleList();
        });
    }
 
    const handleUpdate = (moduleId: number) => {
        setSelectedModuleId(moduleId);
        getCourseModuleById(moduleId).then(res => {
            setCourseModule(res);
            setModuleMode('Edit');
            setOpenModuleModal(true);
        });
    }
 
    const updateModule = (data: CourseModule) => {
        data.id = selectedModuleId;
        const validity = moduleForm.getFieldValue('moduleValidity');
        data.startDate = validity[0];
        data.endDate = validity[1];
 
        const formattedStartDate = moment(validity[0]).format('YYYY-MM-DD HH:mm');
        const formattedEndDate = moment(validity[1]).format('YYYY-MM-DD HH:mm');
 
      
        if (course && areDatesInRange(new Date(data.startDate), new Date(data.endDate), new Date(course?.startDate), new Date(course?.endDate))) {
            updateCourseModule(courseId, data).then(res => {
                setModuleMode('Add');
                setOpenModuleModal(false);
                getCourseModuleList();
            });
        }
    }
 
    const saveCourseSubModule = (data: CourseSubModule) => {
        if (selectedTest) {
            data.testId = selectedTest.id;
        }
        createCourseSubModule(courseId, selectedModuleId, data).then(res => {
            getCourseSubModuleList();
            setOpenSubModuleModal(false);
            subModuleForm.resetFields();
        }).catch(e => {
 
        });
    }
 
    const handleCancel = () => {
        moduleForm.resetFields();
        subModuleForm.resetFields();
        setOpenModuleModal(false);
        setOpenSubModuleModal(false);
        setModuleMode('Add')
    }
 
    const addCousreSubModule = (moduleId: number) => {
        setSelectedModuleId(moduleId);
        setOpenSubModuleModal(true)
    }
 
    const handleSubModuleUpdate = (id: number) => {
        setSelectedSubModuleId(id);
        getCourseSubModuleById(id).then(res => {
            setSubModule(res);
            setSubModuleMode('Edit');
            setOpenSubModuleModal(true);
        });
    }
 
    const updateSubModule = (data: CourseSubModule) => {
        if (selectedTest) {
            data.testId = selectedTest?.id;
        }
        data.id = selectedSubModuleId;
        data.moduleId = selectedModuleId;
        updateCourseSubModule(data).then(res => {
            setSubModuleMode('Add');
            setOpenSubModuleModal(false);
            getCourseSubModuleList();
        });
    }
 
    const handleTestSearch = (searchInput: string) => {
        setTestOptions([]);
        if (searchInput !== '') {
            const query = `name=like=${searchInput};testCode=like=${searchInput}`
            const tableParams: TableParams = {
                pagination: {
                    current: 1,
                    pageSize: 10,
                },
                sortField: 'name',
                sortOrder: 'asc',
                searchInput: query
            };
 
            getTests(qs.stringify(getQueryParams(tableParams))).then((res) => {
                if (res && res.content) {
                    setTests(res.content);
                    const testOptions = res.content.map((t) => {
                        return { value: t.id, label: t.name }
                    });
                    setTestOptions(testOptions);
                }
            }, () => {
            });
        } else {
            setTestOptions([]);
        }
    }
 
    const handleTestSelection = (value: number) => {
        const selectedTest = tests.filter(t => t.id === value);
        if (selectedTest) {
            setSelectedTest(selectedTest[0]);
        }
    }
 
    const previewQuestions = async (testId: number, type: string) => {
        try {
            if (type === 'Multi Choice') {
                const res = await getMCQuestionsOfTest(testId);   
                setMCQQuestions(res);
                openMCQModal();
            }
 
            if (type === 'Coding') {
                const res = await getCodingQuestionsOfTest(testId);
                setCodingQuestions(res);
                openCodingModal();
            }
        } catch (error) {
           
        }
    };
    const handleSubmoduleDetails=(submoduleId:number,testId:number, testType:string)=>{
        setSubModuleId(submoduleId);
        setTestId(testId);
        setTestType(testType);
        goToLiveStatus(courseId,submoduleId);
      }


      const goToLiveStatus = async (courseId: number, submoduleId: number) => {
        try {
            const response = await getStudentCategories(courseId, submoduleId);
            setCategoriesResponse(response);
            setSelectedSubmoduleId(submoduleId);
            setLiveStatusModalVisible(true);
        } catch (error) {
           
        }
    };
    
    const goToResult = async (courseId: number, submoduleId: number) => {
        try {
            const response = await getStudentCategories(courseId, submoduleId);
            setCategoriesResponse(response);
            setSelectedSubmoduleId(submoduleId);
            setResultModalVisible(true);
        } catch (error) {
          
        }
    };
    
    const handleliveStatus = (courseId:any, item:any) => {
        goToLiveStatus(courseId, item.id);
        setSubModuleId(item.id); 
        setTestId(item.testId); 
        setTestType(item.testType);
        setLiveStatusModalVisible(true); 
    };

    const handleSubmoduleClick = (event:any) => {
        event.stopPropagation();
    };

    const handleResultStatusButtonClick = (courseId:any, item:any) => {
        goToResult(courseId, item.id);
        setSubModuleId(item.id); 
        setTestId(item.testId); 
        setTestType(item.testType); 
        setResultModalVisible(true);
    };
    
    
      
    const MultiChoiceModal = ({ visible, onCancel, questions }: any) => {
        return (
            <Modal
                title="Multi Choice Questions Preview"
                open={visible}
                onCancel={onCancel}
                footer={null}
                width={800}
            >
                <div style={{ height: 500, border: '1px solid #e6e6e6', borderRadius: '1rem', marginTop: 10, padding: 15, overflowY: 'scroll' }}>
                    {
                        mcqQuestions?.map(q => (
                            <Card key={q.id} size="small" style={{ marginTop: 10, border: '1px solid #e6e6e6', width: '100%', padding: 10 }}>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Name: </span>{q.name}
                                    </Col>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Difficulty : </span>{q.difficultyLevel}
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Category: </span>{q.category}
                                    </Col>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Sub Category: </span>{q.subCategory}
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={24}>
                                        <span style={{ fontWeight: 500 }}>Question: </span><div style={{ border: '1px solid #e6e6e6', borderRadius: 6, padding: 10, backgroundColor: '#e6e6e6' }} dangerouslySetInnerHTML={{
                                            __html: q.question || "",
                                        }}></div>
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={24}>
                                        <span style={{ fontWeight: 500 }}>Answers: </span>
 
                                        {q.answers.map((a: any, index: number) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                <span dangerouslySetInnerHTML={{ __html: `<strong>${index + 1}.</strong>` }} style={{ marginRight: '8px' }} />
                                                <span dangerouslySetInnerHTML={{ __html: a.answer || "" }} style={{ flex: 1 }} />
                                                {a.correct && <CheckCircleOutlined style={{ color: 'green', fontSize: '20px', marginLeft: '8px' }} />}
                                            </div>
                                        ))}
                                    </Col>
                                </Row>
                            </Card>
                        ))
                    }
                </div>
            </Modal>
        );
    };


    const sortedSubModules = subModules?.slice().sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
 
    const CodingModal = ({ visible, onCancel, questions }: any) => {
 
        const columns = [
            {
                title: 'Input',
                dataIndex: 'input',
                key: 'input',
            },
            {
                title: 'Output',
                dataIndex: 'output',
                key: 'output',
            },
            {
                title: 'Sample Test Cases',
                dataIndex: 'sample',
                key: 'sample',
                render: (sample: boolean) => (sample ? 'Yes' : 'No'),
            }
        ];
 
        return (
            <Modal
                title="Coding Questions Preview"
                open={visible}
                onCancel={onCancel}
                footer={null}
                width={800}
            >
                <div style={{ height: 500, border: '1px solid #e6e6e6', borderRadius: '1rem', marginTop: 10, padding: 15, overflowY: 'scroll' }}>
                    {
                        codingQuestions.map(q => (
                            <Card key={q.id} size="small" style={{ marginTop: 10, border: '1px solid #e6e6e6', width: '100%' }}>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Name: </span>{q.name}
                                    </Col>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Difficulty : </span>{q.difficultyLevel}
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Category: </span>{q.category}
                                    </Col>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Sub Category: </span>{q.subCategory}
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={24}>
                                        <span style={{ fontWeight: 500 }}>Question: </span><div style={{ border: '1px solid #e6e6e6', borderRadius: 6, padding: 10, backgroundColor: '#e6e6e6' }} dangerouslySetInnerHTML={{
                                            __html: q.problemStatement || "",
                                        }}></div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div style={{ fontWeight: 800, marginBottom: 10 }}>Test Cases: </div>
                                        <Table columns={columns} dataSource={q.testCases} pagination={false} style={{ width: '100%' }} />
                                    </Col>
                                </Row>
 
                            </Card>
                        ))
                    }
                </div>
            </Modal>
        );
    };
 
    return (
        <>
            <Row>
                <Col xs={6} style={{ height: '100%' }} >
                    <div>
                        <Button type='primary' icon={<PlusOutlined />} onClick={() => { setDateRangeErr(false); setOpenModuleModal(true) }}>Create Module</Button>
                        <List
                            className="mod-list"
                            dataSource={modules}
                            renderItem={(item, key) => (
                                <List.Item key={key} className={selectedModuleId === item.id ? "mod-list-item selected" : "mod-list-item"} onClick={() => { setSelectedModuleId(item.id) }}>
                                    <div className="title">{item.name}</div>
                                    <div className="date">{moment(item.startDate).format('DD/MM/YYYY')} - {moment(item.endDate).format('DD/MM/YYYY')}</div>
                                    <div className="buttons">
                                        <Space>
                                            <Button icon={<EditOutlined />} onClick={() => handleUpdate(item.id)} />
                                            <Tooltip placement="topLeft" title="Delete Module">
                                                <Button icon={<DeleteOutlined onClick={() => deleteModule(item.id)} />} />
                                            </Tooltip>
                                            <Tooltip placement="topLeft" title="Add Sub Module">
                                                <Button icon={<PlusOutlined />} onClick={() => { addCousreSubModule(item.id) }} />
                                            </Tooltip>
                                        </Space>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                </Col>
                <Col xs={18}>
                <div className="module-list-container" style={{ margin: 20, marginTop: 50 }}>
{selectedModuleId !== 0 && (
      <List
        className="mod-list"
        dataSource={sortedSubModules}
        renderItem={(item, key) => (
          <List.Item
            key={key}
            className={selectedModuleId === item.id ? "mod-list-item selected" : "mod-list-item"}
            onClick={() => {
              handleSubmoduleDetails(item.id, item.testId, item.testType);
            }}
          >
            <div className="content" onClick={(event) => handleSubmoduleClick(event)}>
              <div className="module-info">
                <div className="title">{item.name}</div>
                <div className="icons">
                  <Space>
                    <Button className="edit-button" icon={<EditOutlined />} onClick={() => handleSubModuleUpdate(item.id)} />
                    <Tooltip placement="topLeft" title="Delete Sub Module">
                      <Button className="delete-button" icon={<DeleteOutlined />} onClick={() => deleteSubModule(item.id)} />
                    </Tooltip>
                    <Tooltip placement="topLeft" title="Preview Questions">
                      <Button className="preview-button" icon={<CaretRightOutlined />} onClick={() => previewQuestions(item.testId, item.testType)} />
                    </Tooltip>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'right', paddingLeft: 350, paddingBottom: 20 }}>
                      <div>
                        <Button
                          htmlType="button"
                          type="primary"
                          onClick={() => handleliveStatus(courseId, item)}
                        >
                          Live Status
                        </Button>
                      </div>
                      <div style={{ left: 70, paddingLeft: 20 }}>
                        <Button
                          htmlType="button"
                          type="primary"
                          onClick={() => handleResultStatusButtonClick(courseId, item)}
                        >
                          View Result
                        </Button>
                      </div>
                    </div>
                  </Space>
                </div>
              </div>
            </div>
          </List.Item>
        )}
      />
    )}
            {liveStatusModalVisible && categoriesResponse && (
                <StudentCategoryModal
                    visible={liveStatusModalVisible}
                    onClose={() => setLiveStatusModalVisible(false)}
                    categoriesResponse={categoriesResponse}
                    submoduleId={submoduleid??0}
                    testId={testid??0}
                    testType={testType ? testType.toString() : ""}
                    onRender={()=>{}}
                />
            )}
            {resultModalVisible && categoriesResponse && (
                <ResultAnalysisModal
                    visible={resultModalVisible} 
                    onClose={() => setResultModalVisible(false)}
                    categoriesResponse={categoriesResponse} 
                    submoduleId={submoduleid ?? 0} 
                    testId={testid ?? 0}
                    testType={testType ? testType.toString() : ""} />
            )}

        </div>
 
            </Col>
            </Row>
            <Modal
                title={moduleMode + " Module"}
                open={openModuleModal}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={moduleForm}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={moduleMode === 'Add' ? saveModule : updateModule}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[{ required: true, message: 'Please enter name' }, { max: 100, message: 'Name should not be greater than 100 chars' }]}
                            >
                                <Input placeholder="Please enter name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="moduleValidity"
                                label="Module Validity"
                                rules={[{ required: true, message: 'Please select module validity' }]
                                }
                            >
                                <RangePicker
                                    style={{ width: '100%' }}
                                    showTime={{
                                        format: 'HH:mm',
                                    }}
                                    format="YYYY-MM-DD HH:mm"
                                />
                            </Form.Item>
                            {
                                dateRangeErr ? <p style={{ color: 'red' }}>The module's start and end dates should fall within the range of the course's start and end dates.</p> : ''
                            }
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col>
                            <Form.Item name="gamify" label="Gamify" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='end' >
                        <Col>
                            <Space>
                                <Button htmlType="submit" type="primary">
                                    Submit
                                </Button>
                                <Button htmlType="button" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title="Add Sub Module"
                open={openSubModuleModal}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={subModuleForm}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={subModuleMode === 'Add' ? saveCourseSubModule : updateSubModule}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[{ required: true, message: 'Please enter name' }, { max: 100, message: 'Name should not be greater than 100 chars' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="test"
                                label="Select Test"
                                rules={[{ required: true, message: 'Please select test' }]}
                            >
                                <Select
                                    showSearch
                                    defaultValue={selectedTestValue}
                                    placeholder="Search Test"
                                    optionFilterProp="children"
                                    onChange={handleTestSelection}
                                    onSearch={handleTestSearch}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={testOptions}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="attemptsAllowed"
                                label="Number of Attemps Allowed"
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                        <Col span={12} offset={0}>
                            <Form.Item name="timer" label="Enable Timer?" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12} offset={0}>
                            <Form.Item name="questionsShuffle" label="Shuffle Questions?" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>
                        <Col span={12} offset={0}>
                            <Form.Item name="showSolution" label="Show Solution?" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='end' >
                        <Col>
                            <Space>
                                <Button htmlType="submit" type="primary">
                                    Submit
                                </Button>
                                <Button htmlType="button" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <MultiChoiceModal
                visible={visibleMCQModal}
                onCancel={closeMCQModal}
                questions={mcqQuestions}
            />
            <CodingModal
                visible={visibleCodingModal}
                onCancel={closeCodingModal}
                questions={codingQuestions}
            />
        </>
    )
}
 