import { useEffect, useState } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout"
import { TableParams } from "../../../types";

import { TestsList } from "../components/TestsList";
import { Test } from "../types";
import qs from 'qs';
import { Button, Col, Row } from "antd";
import {
    PlusOutlined
} from '@ant-design/icons';
import Search from "antd/es/input/Search";
import { useNavigate } from "react-router-dom";
import { getTests } from "../api/getTests";
import { cloneTest } from "../api/cloneTest";


const getQueryParams = (params: TableParams) => ({
    limit: params.pagination?.pageSize,
    offset: params.pagination?.current,
    orderBy: params.sortField,
    order: params.sortOrder,
    searchInput: params.searchInput
});

export const Tests = () => {

    const navigate = useNavigate();
    const [searchInput, SetSearchInput] = useState<string>();
    const [rowCount, setRowCount] = useState<number>(0);
    const [data, setData] = useState<Test[]>();
    const [loading, setLoading] = useState(false);

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sortField: 'name',
        sortOrder: 'asc',
        searchInput: ''
    });


    /* Functions for TestList */
    const fetchData = () => {
        setLoading(true);
        getTests(qs.stringify(getQueryParams(tableParams))).then((res) => {
            setRowCount(res.totalElements);
            setLoading(false)
            setData(res.content)
        }, () => {
            setLoading(false)
        });
    };

    useEffect(() => {
        fetchData();
    }, [tableParams]);


    const updateTableParams = (params: TableParams) => {
        setTableParams({
            ...params,
            searchInput
        });
    }

    const onSearch = (searchInput: string) => {
        let query = ''
        if (searchInput !== '') {
            query = `name=like=${searchInput};testCode=like=${searchInput};sectionName=like=${searchInput}`
        }
        SetSearchInput(query);
        setTableParams({
            ...tableParams,
            searchInput: query
        })
    }

    const goToForm = (id: number, mode: string) => {
        const params = { id, mode };
        navigate({
            pathname: '/test-form',
            search: qs.stringify(params),
        });
    }

    const goToQuestions = (id: number, type: string) => {
        const params = { id, type };
        navigate({
            pathname: '/test-questions',
            search: qs.stringify(params),
        });
    }

    const handleCloneTest = (testId: number) => {
        cloneTest(testId).then((res) => {
            fetchData();
        }, err => {
        });
    }

    return (
        <ContentLayout title="Tests">
            <Row style={{ marginBottom: 16 }} gutter={[20, 25]} justify='end'>
                <Col>
                    <Search placeholder="Search" enterButton onSearch={onSearch} />
                </Col>
                <Col>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => goToForm(0, 'Add')}>Create Test</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TestsList data={data} loading={loading} tableParams={tableParams} rowCount={rowCount} updateTableParams={updateTableParams} goToForm={goToForm} goToQuestions={goToQuestions} handleCloneTest={handleCloneTest} />
                </Col>
            </Row>
        </ContentLayout>
    )
}