import { Button, Card, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import { CodingQuestion, TestCase } from '../types';
 
import {
    MinusCircleOutlined,
    PlusOutlined
} from '@ant-design/icons';
 
import qs from 'qs';
 
import TextArea from 'antd/es/input/TextArea';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CodeEditor from '../../../components/codeeditor/CodeEditor';
import { ContentLayout } from '../../../components/layout/ContentLayout';
import TextEditor from '../../../components/texteditor/TextEditor';
import { SOLUTION_LANGUAGES } from '../../../utils/constants';
import { createCodingQuestion } from '../api/createCodingQuestion';
import { getCodingQuestionById } from '../api/getCodingQuestionById';
import { getTestCasesByQuestionId } from '../api/getTestCasesByQuestionId';
import { runTestCases } from '../api/runTestCases';
import { updateCodingQuestion } from '../api/updateCodingQuestion';
 
const columns = [
    {
        title: 'Input',
        dataIndex: 'input',
        key: 'input',
        render: (text: string) => <pre>{text}</pre>,
    },
    {
        title: 'Expected Output',
        dataIndex: 'output',
        key: 'output',
        render: (text: string) => <pre>{text}</pre>,
    },
    {
        title: 'Actual Output',
        dataIndex: 'actualOutput',
        key: 'output',
       render: (text: string) => <pre>{text}</pre>,
    },
    {
        title: 'Sample',
        dataIndex: 'sample',
        key: 'sample',
        render: (text: boolean) => (text === true ? 'Yes' : 'No'),
    },
    {
        title: 'Verified',
        dataIndex: 'status',
        key: 'status',
        render: (status: string) => (
            status === 'Success' ? <p style={{ color: 'green' }}>Success</p> : <p style={{ color: 'red' }}>Failed</p>
        ),
    },
];
 
 
 
export const CodingQuestionForm = () => {
 
    const navigate = useNavigate();
    const [data, setData] = useState<CodingQuestion>();
    const [loading, setLoading] = useState<boolean>(false);
    const [runLoading, setRunLoading] = useState<boolean>(false);
    const [solution, setSolution] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [testCases, setTestCases] = useState<TestCase[]>();
    const [languageMode, setLanguageMode] = useState<string>();
    const [form] = Form.useForm();
    const solutionLanguages = SOLUTION_LANGUAGES;
    const [compilationErrMsg, setCompilationErrMsg] = useState<string>('');
    const [compilationErrModalVisible, setCompilationErrModalVisible] = useState<boolean>(false);
 
    const [enableHeaderFooter, setEnableHeaderFooter] = useState(false);
    const [header, setHeader] = useState<string>('');
    const [footer, setFooter] = useState<string>('');
 
    const [searchParams] = useSearchParams();
    const mode = searchParams.get('mode');
    const id = searchParams.get('id');
    let qbankkId = searchParams.get('qbkId');
    const qbkId = (qbankkId !== null && qbankkId !== '') ? +qbankkId : 0;
    const [testCaseCardSize, setTestCaseCardSize] = useState({ height: 'auto', width: 'auto' });
 
 
    const initialTestCases = [{ input: '', output: '', sample: false, position: 1 }, { input: '', output: '', sample: false, position: 1 }]
 
    const qid = (id !== null && id !== '') ? +id : 0;
 
    useEffect(() => {
        if (qid !== 0) {
            setLoading(true);
            getCodingQuestionById(qid).then((res) => {
                setData(res);
                setLoading(false);
            }, err => {
                setLoading(false);
            })
        }
    }, [searchParams]);
 
    useEffect(() => {
        form.setFieldsValue({ ...data });
        const testCases = data?.testCases.sort((a, b) => a.position - b.position);
        form.setFieldValue('testCases', testCases);
        if(data?.enableHeaderFooter){
            setEnableHeaderFooter(data.enableHeaderFooter);
        }
    }, [data, form])
 
 
   
    const save = (data: CodingQuestion) => {
        if (!solution) {
            form.setFields([
                {
                    name: 'solution',
                    errors: ['Please enter solution'],
                },
            ]);
            return
        }
 
        setLoading(true);
        data.qbkId = qbkId;
        data.solution = solution;
        createCodingQuestion(data).then((res) => {
            setLoading(false);
            form.resetFields();
            if (res && res.id) {
                goToForm(res.id, 'Edit');
            }
        }, err => {
            setLoading(false);
        });
    }
 
    const update = (data: CodingQuestion) => {
        data.id = qid;
        setLoading(true);
        updateCodingQuestion(data).then((res) => {
            setLoading(false);
        }, err => {
            setLoading(false);
        });
    }
 
    const updateAndRunTestCases = () => {
        form.validateFields().then(() => {
            const updatedData: CodingQuestion = form.getFieldsValue()
            updatedData.id = qid;
            setLoading(true);
            updateCodingQuestion(updatedData).then((res) => {
                setLoading(false);
                runTests();
            }, err => {
                setLoading(false);
            });
        }).catch((error) => {
            toast.error('Please fill all mandatory fields.')
        })
    }
 
    const gotoCodingQuestionsGrid = () => {
        const params = { qbkId }
        navigate({
            pathname: '/coding-questions',
            search: qs.stringify(params),
        });
    }
 
    const handleCodeEditorChange = (data: string) => {
        setSolution(data);
        if (solution) {
            form.setFields([
                {
                    name: 'solution',
                    errors: [],
                },
            ]);
        }
    }
 
    const handleOk = () => {
        setIsModalOpen(false);
    };
 
    const handleCancel = () => {
        setIsModalOpen(false);
    };
 
    const runTests = async () => {
        try {
            setRunLoading(true);
 
            // Execute the test cases and wait for the result.
            const testResults = await runTestCases(qid);
 
            // Fetch the updated test cases based on the question ID.
            const res = await getTestCasesByQuestionId(qid);
 
            if (res) {
                if (res.compilationError) {
                    setCompilationErrMsg(res.compilationError);
                    setCompilationErrModalVisible(true);
                    setIsModalOpen(false);
                } else {
                    setCompilationErrMsg('');
                    setCompilationErrModalVisible(false);
                    setTestCases(res.testCases);
                    setIsModalOpen(true);
                }
            }
            setRunLoading(false);
        } catch (err) {
            console.log(err);
            setRunLoading(false);
        }
    };
 
 
    const handleLanguageSelection = (lan: string) => {
        if (lan === 'C++') {
            setLanguageMode('c_cpp');
        }
 
        if (lan === 'C') {
            setLanguageMode('c_cpp');
        }
 
        if (lan === 'Java') {
            setLanguageMode('java');
        }
 
        if (lan === 'JavaScript') {
            setLanguageMode('javascript');
        }
 
        if (lan === 'Python') {
            setLanguageMode('python');
        }
 
        if (lan === 'C#') {
            setLanguageMode('csharp');
        }
 
        if (lan === 'Go Lang') {
            setLanguageMode('golang');
        }
 
        if (lan === 'Scala') {
            setLanguageMode('scala');
        }
 
        if (lan === 'SQL') {
            setLanguageMode('sql');
        }
    }
 
    const goToForm = (id: number, mode: string) => {
        const params = { id, mode, qbkId };
        navigate({
            pathname: '/coding-question-form',
            search: qs.stringify(params),
        });
    }
 
    const handleHeaderFooterCheckboxChange = (e: any) => {
        setEnableHeaderFooter(e.target.checked);
    };
 
    return (
        <ContentLayout title="Coding Questions">
        <Modal
          title="Test Cases - Results"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={800}
         
        >
          <Card
            style={{ height: testCaseCardSize.height, width: testCaseCardSize.width,  maxHeight: '500px',  overflowY: 'auto'}}>
            <Table
              dataSource={testCases}
              columns={columns}
              pagination={false}
              scroll={{ x: true }}
            />
          </Card>
        </Modal>
            <Form
                form={form}
                layout="vertical"
                onFinish={mode === 'Add' ? save : update}
                autoComplete="off"
                initialValues={{ answers: initialTestCases }}
            >
 
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter name' }, { max: 100, message: 'Name should not be greater than 100 characters' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="inputFormat"
                            label="Input Format"
                            rules={[{ required: true, message: 'Please enter input format' }, { max: 1000, message: 'Input format should not be greater than 1000 characters' }]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
 
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="outputFormat"
                            label="Output Format"
                            rules={[{ required: true, message: 'Please enter output format' }, { max: 1000, message: 'Output format should not be greater than 1000 characters' }]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="constraints"
                            label="Constraints"
                            rules={[{ required: true, message: 'Please enter constraints' }, { max: 1000, message: 'Constraints should not be greater than 1000 characters' }]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="language"
                            label="Solution Language"
                            rules={[{ required: true, message: 'Please select language' }]}
                        >
                            <Select
                                onSelect={handleLanguageSelection}
                                style={{ width: '100%' }}
                                options={solutionLanguages.map((item) => ({
                                    value: item,
                                    label: item,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="category"
                            label="Category"
                            rules={[{ required: true, message: 'Please enter category' }, { max: 100, message: 'Category should not be greater than 100 characters' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
 
                    <Col span={12}>
                        <Form.Item
                            name="subCategory"
                            label="Sub Category"
                            rules={[{ required: true, message: 'Please enter sub category' }, { max: 100, message: 'Sub Category should not be greater than 100 characters' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="difficultyLevel"
                            label="Difficulty Level"
                            rules={[{ required: true, message: 'Please select difficulty level' }]}
                        >
                            <Select
                                options={[
                                    { value: 'Low', label: 'Low' },
                                    { value: 'Medium', label: 'Medium' },
                                    { value: 'High', label: 'High' }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12} >
                        <Form.Item
                            name="problemStatement"
                            label="Problem Statement"
                            rules={[{ required: true, message: 'Please enter problem statement' }]}
                        >
                            {/* @ts-ignore */}
                            <TextEditor />
                        </Form.Item>
                    </Col>
 
                    <Col span={12}>
                        <Form.Item
                            name="mark"
                            label="Score"
                            rules={[{ required: true, message: 'Please enter score' }]}
                        >
                            <Input type="number" min={10} max={100} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="enableHeaderFooter"
                            valuePropName="checked"
                        >
                            <Checkbox onChange={handleHeaderFooterCheckboxChange}>Enable Header and Footer?</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
 
                <Row gutter={16}>
                    <Col span={12}>
 
                        {enableHeaderFooter && (
                            <Form.Item name="header" label="Header">
                                {/* @ts-ignore */}
                                <CodeEditor mode={languageMode} value={header} onChange={(value) => setHeader(value)} height={200} />
                            </Form.Item>
                        )}
 
                        <Form.Item name="solution" label="Solution">
                            {/* @ts-ignore */}
                            <CodeEditor mode={languageMode} value={solution} onChange={handleCodeEditorChange} />
                        </Form.Item>
                        {enableHeaderFooter && (
                            <Form.Item name="footer" label="Footer">
                                {/* @ts-ignore */}
                                <CodeEditor mode={languageMode} value={footer} onChange={(value) => setFooter(value)} height={200} />
                            </Form.Item>
                        )}
 
                        {compilationErrModalVisible ?
                            <div className="message-container">
                                <h2 className="title">Compilation Error!</h2>
                                <p className="message">{compilationErrMsg}</p>
                            </div> : ''
                        }
                    </Col>
                    <Col span={12}>
                        {/* Right Side */}
                        <Form.List name="testCases">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Space key={field.key} direction="vertical" size="middle" style={{ display: 'flex', marginBottom: 16 }}>
                                            <Card style={{ paddingTop: 16 }} size="small">
                                                <Form.Item name={[index, 'input']} style={{ marginBottom: 5 }}>
                                                    <TextArea placeholder="Input" rows={4} />
                                                </Form.Item>
                                                <Form.Item name={[index, 'output']} style={{ marginBottom: 5 }}>
                                                    <TextArea placeholder="Output" rows={4} />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[index, 'sample']}
                                                    style={{ marginBottom: 5 }}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox>Mark as Sample Test Case</Checkbox>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(index)} />
                                            </Card>
                                        </Space>
                                    ))}
                                    {(mode === 'Add' || (data?.editable && mode === 'Edit')) && (
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add({ input: '', output: '', sample: false, position: fields.length + 1 })}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add Test Cases
                                            </Button>
                                        </Form.Item>
                                    )}
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
 
 
                <Row gutter={16} justify='end' >
                    <Col>
                        <Space>
                            {data?.editable && mode === 'Edit' && (
                                <>
                                    <Button
                                        htmlType="button"
                                        type="primary"
                                        onClick={() => updateAndRunTestCases()}
                                        loading={runLoading}
                                        disabled={runLoading}
                                    >
                                        Update & Run Tests
                                    </Button>
                                    <Button htmlType="submit" type="primary" loading={loading} disabled={loading}>
                                        Update
                                    </Button>
                                    <Button htmlType="button" onClick={() => gotoCodingQuestionsGrid()}>
                                        Cancel
                                    </Button>
                                </>
                            )}
 
                            {mode === 'Add' && (
                                <>
                                    <Button htmlType="submit" type="primary" loading={loading} disabled={loading}>
                                        ADD
                                    </Button>
                                    <Button htmlType="button" onClick={() => gotoCodingQuestionsGrid()}>
                                        Cancel
                                    </Button>
                                </>
                            )}
 
                            {!data?.editable && mode === 'Edit' && (
                                <Button htmlType="button" onClick={() => gotoCodingQuestionsGrid()}>
                                    Back
                                </Button>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Form>
 
        </ContentLayout>
    );
}