import { AppProvider } from "./providers/AppProvider";
import { AppRoutes } from "./routes";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";


import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChartBar, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';


// Add the icons you want to use to the library
library.add(faChartBar, faEdit, faTrash);

export default function App() {
  return (
    <div>
      <AppProvider>
        <AppRoutes />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </AppProvider>
    </div>
  );
}