import { axios } from "../../../lib/axios";
import { ApiResponse } from "../../../types";

export const uploadUsers = (collegeId: number, data: FormData): Promise<ApiResponse> => {
  return axios.post(`/users/${collegeId}/bulkUpload`, data, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  });
}
