import { axios } from "../../../lib/axios";
import { ApiResponse } from "../../../types";

export const unEnrollStudentsFromCourse = (courseId: number, collegeId: number, data: FormData): Promise<ApiResponse> => {
    return axios.post(`/courses/${courseId}/colleges/${collegeId}/students/bulk-unenroll`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
