import { useEffect, useState } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout"
import { TableParams } from "../../../types";

import { getQuestionBanks } from "../api/getQuestionBanks";

import { QuestionBanksList } from "../components/QuestionBanksList";

import { QuestionBank } from "../types";
import qs from 'qs';
import { Button, Card, Col, Modal, Row } from "antd";
import {
    PlusOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import Search from "antd/es/input/Search";
import { useNavigate } from "react-router-dom";
import { getMultiChoiceQuestions } from "../../mcquestions/api/getMultiChoiceQuestions";
import { getCodingQuestions } from "../../codingquestions/api/getCodingQuestions";
import { MultiChoiceQuestion } from "../../mcquestions";
import { CodingQuestion } from "../../codingquestions";


const getQueryParams = (params: TableParams) => ({
    limit: params.pagination?.pageSize,
    offset: params.pagination?.current,
    orderBy: params.sortField,
    order: params.sortOrder,
    searchInput: params.searchInput
});

export const QuestionBanks = () => {

    const navigate = useNavigate();
    const [searchInput, SetSearchInput] = useState<string>();
    const [rowCount, setRowCount] = useState<number>(0);
    const [data, setData] = useState<QuestionBank[]>();
    const [loading, setLoading] = useState(false);
    const [mcQuestions, setMCQuestions] = useState<MultiChoiceQuestion[]>([])
    const [codingQuestions, setCodingQuestions] = useState<CodingQuestion[]>([]);

    const [visibleMCQModal, setVisibleMCQModal] = useState(false);
    const [visibleCodingModal, setVisibleCodingModal] = useState(false);

    const openMCQModal = () => setVisibleMCQModal(true);
    const openCodingModal = () => setVisibleCodingModal(true);
    const closeMCQModal = () => setVisibleMCQModal(false);
    const closeCodingModal = () => setVisibleCodingModal(false);


    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sortField: 'name',
        sortOrder: 'asc',
        searchInput: ''
    });


    /* Functions for QuestionBankList */
    const fetchData = () => {
        setLoading(true);
        getQuestionBanks(qs.stringify(getQueryParams(tableParams))).then((res) => {
            setRowCount(res.totalElements);
            setLoading(false)
            setData(res.content)
        }, () => {
            setLoading(false)
        });
    };

    useEffect(() => {
        fetchData();
    }, [tableParams]);


    const updateTableParams = (params: TableParams) => {
        setTableParams({
            ...params,
            searchInput
        });
    }

    const onSearch = (searchInput: string) => {
        let query = ''
        if (searchInput !== '') {
            query = `name=like=${searchInput};type=like=${searchInput};subject=like=${searchInput};topic=like=${searchInput};subTopic=like=${searchInput}`
        }
        SetSearchInput(searchInput);
        setTableParams({
            ...tableParams,
            searchInput: query
        })
    }

    const goToForm = (id: number, mode: string) => {
        const params = { id, mode };
        navigate({
            pathname: '/questionbank-form',
            search: qs.stringify(params),
        });
    }

    const previewQuestions = async (qbkId: number, type: string) => {
        try {
            if (type === 'Multi Choice') {
                const mcqparams = {
                    limit: 1000,
                    offset: 1,
                    orderBy: 'name',
                    order: 'asc',
                    qbkId,
                    answers: true
                };

                const res = await getMultiChoiceQuestions(qs.stringify(mcqparams));
                setMCQuestions(res.content);
                openMCQModal();
            }

            if (type === 'Coding') {
                const cqparams = {
                    limit: 1000,
                    offset: 1,
                    orderBy: 'name',
                    order: 'asc',
                    qbkId
                };

                const res = await getCodingQuestions(qs.stringify(cqparams));
                setCodingQuestions(res.content);
                openCodingModal();
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const renderMCQModal = () => {
        return (
            <Modal
                title="Multi Choice Questions"
                open={visibleMCQModal}
                onCancel={closeMCQModal}
                footer={null}
                width={800}
            >
                <div style={{ height: 500, border: '1px solid #e6e6e6', borderRadius: '1rem', marginTop: 10, padding: 15, overflowY: 'scroll' }}>
                    {
                        mcQuestions?.map(q => (
                            <Card key={q.id} size="small" style={{ marginTop: 10, border: '1px solid #e6e6e6', width: '100%', padding: 10 }}>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Name: </span>{q.name}
                                    </Col>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Difficulty : </span>{q.difficultyLevel}
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Category: </span>{q.category}
                                    </Col>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Sub Category: </span>{q.subCategory}
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={24}>
                                        <span style={{ fontWeight: 500 }}>Question: </span><div style={{ border: '1px solid #e6e6e6', borderRadius: 6, padding: 10, backgroundColor: '#e6e6e6' }} dangerouslySetInnerHTML={{
                                            __html: q.question || "",
                                        }}></div>
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={24}>
                                        <span style={{ fontWeight: 500 }}>Answers: </span>

                                        {q.answers.map((a: any, index: number) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                <span dangerouslySetInnerHTML={{ __html: `<strong>${index + 1}.</strong>` }} style={{ marginRight: '8px' }} />
                                                <span dangerouslySetInnerHTML={{ __html: a.answer || "" }} style={{ flex: 1 }} />
                                                {a.correct && <CheckCircleOutlined style={{ color: 'green', fontSize: '20px', marginLeft: '8px' }} />}
                                            </div>
                                        ))}
                                    </Col>
                                </Row>
                            </Card>
                        ))
                    }
                </div>
            </Modal>
        );
    };

    const renderCodingModal = () => {
        return (
            <Modal
                title="Coding Questions"
                open={visibleCodingModal}
                onCancel={closeCodingModal}
                footer={null}
                width={800}
            >
                <div style={{ height: 500, border: '1px solid #e6e6e6', borderRadius: '1rem', marginTop: 10, padding: 15, overflowY: 'scroll' }}>
                    {
                        codingQuestions.map(q => (
                            <Card key={q.id} size="small" style={{ marginTop: 10, border: '1px solid #e6e6e6', width: '100%' }}>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Name: </span>{q.name}
                                    </Col>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Difficulty : </span>{q.difficultyLevel}
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Category: </span>{q.category}
                                    </Col>
                                    <Col span={12}>
                                        <span style={{ fontWeight: 500 }}>Sub Category: </span>{q.subCategory}
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', marginBottom: 12 }}>
                                    <Col span={24}>
                                        <span style={{ fontWeight: 500 }}>Question: </span><div style={{ border: '1px solid #e6e6e6', borderRadius: 6, padding: 10, backgroundColor: '#e6e6e6' }} dangerouslySetInnerHTML={{
                                            __html: q.problemStatement || "",
                                        }}></div>
                                    </Col>
                                </Row>
                            </Card>
                        ))
                    }
                </div>
            </Modal>
        );
    };

    return (
        <ContentLayout title="Question Bank">
            <Row style={{ marginBottom: 16 }} gutter={[20, 25]} justify='end'>
                <Col>
                    <Search placeholder="Search" enterButton onSearch={onSearch} />
                </Col>
                <Col>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => goToForm(0, 'Add')}>Create Question Bank</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <QuestionBanksList data={data} loading={loading} tableParams={tableParams} rowCount={rowCount} updateTableParams={updateTableParams} goToForm={goToForm} previewQuestions={previewQuestions} />
                </Col>
            </Row>
            {renderMCQModal()}
            {renderCodingModal()}
        </ContentLayout>
    )
}