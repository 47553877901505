import React, { useEffect, useRef, useState } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout";
import { Button, Card, Checkbox, Col, Modal, Row, Space, Statistic,Radio } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import qs from "qs";
import { MultiChoiceQuestion } from "../../mcquestions";
import { getMultiChoiceQuestionsForTest } from "../api/getMultiChoiceQuestionsForTest";
import { saveMCQTestDetails } from "../api/saveMCQTestDetails";
import { MCQTest } from "../types";
import { submitTest } from "../api/submitTest";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { updateTimeSpentForTest } from "../api/updateTimeSpentForTest";
import { notification } from 'antd';
import { toast } from "react-toastify";
 
const { Countdown } = Statistic;
 
export const StudentMCQ: React.FC = () => {
  const [rerender, setRerender] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("enrollmentId");
  const sid = searchParams.get("subModuleId");
  const tid = searchParams.get("transactionId");
  const enrollmentId = id ? +id : 0;
  const subModuleId = sid ? +sid : 0;
  const transactionId = tid || "";
  const [selectedQuestion, setSelectedQuestion] = useState<number>(0);
  const [mcQuestions, setMcQuestions] = useState<MultiChoiceQuestion[]>([]);
  const [mcQuestionTestDetail, setMcQuestionTestDetail] = useState<MCQTest>();
  const [currentQuestion, setCurrentQuestion] = useState<MultiChoiceQuestion>();
  const [key, setKey] = useState<number>(1);
  const [deadline, setDeadline] = useState<number>(0);
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
  const [timeSpent, setTimeSpent] = useState<number>(0);
  const [checkedStatus, setCheckedStatus] = useState<Record<number, boolean>>({});
 
  const [markReviewClickCount, setMarkReviewClickCount] = useState<Record<number, number>>({});
  const [answeredQuestionsStatus, setAnsweredQuestionsStatus] = useState<Record<number, boolean>>({});
  const [currentQuestionAnswered, setCurrentQuestionAnswered] = useState<boolean>(false);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(0);
  const [reviewedQuestions, setReviewedQuestions] = useState<Set<number>>(new Set());
  const [skippedQuestions, setSkippedQuestions] = useState<Set<number>>(new Set());
  const mcQuestionTestDetailRef = useRef<MCQTest | undefined>(undefined);
  const timeSpentRef = useRef<number>(0);
  const [showDeveloperToolsAlert, setShowDeveloperToolsAlert] = useState(false);
 
  const seconds = timeSpent % 60;
  const minutes = Math.floor(timeSpent / 60);
  let isNavigating = false;
 
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 123) {
        event.preventDefault();
        setShowDeveloperToolsAlert(true);
      }
    };
 
    const handleRightClick = (event: MouseEvent) => {
      event.preventDefault();
      setShowDeveloperToolsAlert(true);
    };
 
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('contextmenu', handleRightClick);
 
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('contextmenu', handleRightClick);
    };
  }, [enrollmentId]);
 
  useEffect(() => {
    if (showDeveloperToolsAlert) {
      notification.warning({
        message: "Warning",
        description: "Right click and developer tools are disabled",
        onClose: () => setShowDeveloperToolsAlert(false)
      });
    }
  }, [showDeveloperToolsAlert]);
 
  useEffect(() => {
    window.onbeforeunload = function () {
      updateTimeSpent();
      return true;
    };
 
    return () => {
      window.onbeforeunload = null;
    };
  }, []);
 
 
  useEffect(() => {
    const cleanupFunction = () => {
      updateTimeSpent();
    };
 
    window.addEventListener("beforeunload", cleanupFunction);
 
    return () => {
      window.removeEventListener("beforeunload", cleanupFunction);
    };
  }, []);
 
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeSpent((prevSeconds) => prevSeconds + 1);
    }, 1000);
 
    return () => {
      clearInterval(timer);
      updateTimeSpent();
    };
  }, []);
 
  useEffect(() => {
    mcQuestionTestDetailRef.current = mcQuestionTestDetail;
    timeSpentRef.current = timeSpent;
  }, [mcQuestionTestDetail, timeSpent]);
 
 
  useEffect(() => {
    if (enrollmentId !== 0 && subModuleId !== 0) {
      getMultiChoiceQuestionsForTest(enrollmentId, subModuleId, transactionId)
        .then((res) => {
          setMcQuestionTestDetail(res);
          const initialReviewedQuestions = new Set<number>();
          const initialSkippedQuestions = new Set<number>();
          res.questions.forEach((question) => {
            if (question.markReview) {
              initialReviewedQuestions.add(question.id);
            }
            if (question.skip) {
              initialSkippedQuestions.add(question.id);
            }  
            const isSelected = question.answers.some(answer => answer.selected);
            if (isSelected) {
              setAnsweredQuestionsStatus(prev => ({
                ...prev,
                [question.id]: true
              }));
            }
          });
          setReviewedQuestions(initialReviewedQuestions);
          setSkippedQuestions(initialSkippedQuestions);
        })
        .catch(() => {
          goToCourseMainPage();
        });
    }
  }, [enrollmentId, subModuleId]);
 
 
  useEffect(() => {
    if (mcQuestionTestDetail) {
      setMcQuestions(mcQuestionTestDetail.questions);
 
      setSelectedQuestion(mcQuestionTestDetail.questions[0]?.id || 0);
      setDeadline(Date.now() + 1000 * 60 * mcQuestionTestDetail.duration);
      setTimeSpent(mcQuestionTestDetail.duration);
      const initialAnsweredQuestions = new Set<number>();
 
      mcQuestionTestDetail.questions.forEach((question) => {
        if (question.answers.some((answer) => answer.selected && answer.correct)) {
          initialAnsweredQuestions.add(question.id);
        }
      });
 
      setAnsweredQuestionsStatus((prev) => ({
        ...prev,
        ...Array.from(initialAnsweredQuestions).reduce((obj, id) => {
          obj[id] = true;
          return obj;
        }, {} as Record<number, boolean>),
      }));    
    }
  }, [mcQuestionTestDetail]);
 
  useEffect(() => {
    if (selectedQuestion !== 0) {
      const question = mcQuestions?.find((q) => q.id === selectedQuestion);
      setCurrentQuestion(question);
    }
  }, [selectedQuestion, mcQuestions]);
 
  const countDown = () => {
    let secondsToGo = 5;
 
    const instance = modal.success({
      title: 'Your time is up!',
      content: `This page will be closed in ${secondsToGo} seconds.`,
      footer: null
    });
 
    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({
        content: `This page will be closed in ${secondsToGo} seconds.`,
      });
    }, 1000);
 
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
      handleCompleteTestConfirmation();
    }, secondsToGo * 1000);
  };
 
  const goToCourseMainPage = () => {
    clearState();
    const params = { enrollmentId };
    navigate({
      pathname: "/student-course-main",
      search: qs.stringify(params),
    });
  };
 
  const selectQuestion = (questionId: number, index: number) => {
    setSelectedQuestion(questionId);
    setCurrentIndex(index);
    setKey(index + 1);
    const updatedCheckedStatus = { ...checkedStatus };
    updatedCheckedStatus[questionId] = updatedCheckedStatus[questionId] || false;
    setCheckedStatus(updatedCheckedStatus);
    setActiveQuestionIndex(index);
    updateChoiceBoxColor(index + 1, false);
  };
 
 
  const updateChoiceBoxColor = (questionNumber: number, skip: boolean) => {
    const isCheckboxClicked = checkedStatus[questionNumber];
    const isAnswered = answeredQuestionsStatus[questionNumber];
    const isSkipped = skippedQuestions.has(questionNumber);
    const optionBox = document.querySelector(`.option-box-${questionNumber}`);
   
    let updatedChoice = '';
    if (isSkipped) {
      updatedChoice = 'skipped';
      optionBox?.classList.remove("answered", "reviewed");
      optionBox?.classList.add("skipped");
    } else if (isAnswered) {
      updatedChoice = 'answered';
      optionBox?.classList.remove("reviewed", "skipped");
      optionBox?.classList.add("answered");
    } else if (isCheckboxClicked) {
      const count = markReviewClickCount[questionNumber] || 0;
      if (count % 2 === 0) {
        updatedChoice = 'answered';
        optionBox?.classList.remove("skipped", "reviewed");
        optionBox?.classList.add("answered");
      } else {
        updatedChoice = 'reviewed';
        optionBox?.classList.remove("answered", "skipped");
        optionBox?.classList.add("reviewed");
      }
    } else {
      updatedChoice = 'unanswered';
      optionBox?.classList.remove("answered", "reviewed", "skipped");
      optionBox?.setAttribute("style", "background-color: white;");
    }
    const statusLabel = skip ? 'Skipped' : updatedChoice.charAt(0).toUpperCase() + updatedChoice.slice(1);
  };
 
 
const navigateToNextQuestion = ( questionNumber: number) => {
  const nextIndex = currentIndex + 1;
  if (nextIndex < mcQuestions.length) {
    selectQuestion(mcQuestions[nextIndex].id, nextIndex);
    updateChoiceBoxColor(questionNumber + 1, false);
  }
};
 
 
const navigateToPreviousQuestion = ( questionNumber: number) => {
  const previousIndex = currentIndex - 1;
  if (previousIndex >= 0) {
    selectQuestion(mcQuestions[previousIndex].id, previousIndex);
    updateChoiceBoxColor(questionNumber - 1,false);
  }
};
 
 
const navigateToMarkReview = (questionId: number) => {
  const isCurrentlyReviewed = reviewedQuestions.has(questionId);
  if (isCurrentlyReviewed) {
    reviewedQuestions.delete(questionId);
  } else {
    reviewedQuestions.add(questionId);
  }
  postAnswer(mcQuestions[currentIndex], !isCurrentlyReviewed);
  setRerender(!rerender);
  navigateToNextQuestion(questionId);
};
 
 
 
const navigateToSkip = (questionNumber: number) => {
  setSkippedQuestions((prev) => new Set([...prev, questionNumber]));
  postAnswer(mcQuestions[currentIndex], false, true);
    updateChoiceBoxColor(questionNumber, true);
     if (currentIndex === mcQuestions.length - 1) {
  } else {
    navigateToNextQuestion(questionNumber);
  }
};
 
const handleNextButtonClick = (questionNumber: number) => {
  setCurrentQuestionAnswered(false);
  navigateToNextQuestion(questionNumber);
};
 
  const onTestTimeUp = () => {
    countDown();
  };
 
 
 
const setAnswer = (questionId: number, answerId: number, isRadio: boolean = false, markReview: boolean | undefined = undefined) => {
  const questionIndex = mcQuestions.findIndex((q) => q.id === questionId);
  const answerIndex = mcQuestions[questionIndex].answers.findIndex((a) => a.id === answerId);
  const updatedQuestions = [...mcQuestions];
  if (isRadio) {
      updatedQuestions[questionIndex].answers.forEach((a) => {
          a.selected = false;
      });
  }
  updatedQuestions[questionIndex].answers[answerIndex].selected = !updatedQuestions[questionIndex].answers[answerIndex].selected;
  setMcQuestions(updatedQuestions);
  setRerender(!rerender);  
  if (skippedQuestions.has(questionId)) {
      setSkippedQuestions((prev) => new Set([...prev].filter((id) => id !== questionId)));
      setAnsweredQuestionsStatus((prev) => ({ ...prev, [questionId]: true }));
      updateChoiceBoxColor(questionId, false);
  }
  postAnswer(updatedQuestions[questionIndex], markReview);
  const updatedCheckedStatus = { ...checkedStatus };
  updatedCheckedStatus[questionId] = !updatedCheckedStatus[questionId];
  setCheckedStatus(updatedCheckedStatus);
  setCurrentQuestionAnswered(true);
  setAnsweredQuestionsStatus((prev) => ({
      ...prev,
      [questionId]: true,
  }));
};
 
 
 
  const postAnswer = (mcQuestion: MultiChoiceQuestion, markReview: boolean | undefined = undefined, skip: boolean = false) => {
    const enrollmentDetailsId = mcQuestionTestDetail?.enrollmentDetailsId;
    const questionId = mcQuestion.id;
    const mcqAnswers = mcQuestion.answers.filter(a => a.selected === true).map(a => a.id);
    const previousMarkReview = reviewedQuestions.has(questionId);
    if (markReview !== undefined) {
      setReviewedQuestions((prev) => (markReview ? new Set([...prev, questionId]) : new Set([...prev].filter((id) => id !== questionId))));
    } else if (previousMarkReview && markReview === undefined) {
      markReview = true;
    }
    const data = {
      enrollmentDetailsId,
      questionId,
      mcqAnswers,
      markReview: markReview || false,
      skip,
    };
    saveMCQTestDetails(data)
    .then(() => {
      })
      .catch(error => {
        toast.dismiss();
        Modal.error({
            title: 'Error',
            content: 'The test has ended. Please contact the admin or faculty',
            centered: true,
            style: {
                width: '70%',
                height: 'auto',
            },
            onOk: () => {
              const params = { enrollmentId: id };
              isNavigating = true;
              navigate({
                  pathname: '/student-course-main',
                  search: qs.stringify(params),
              });
          }
        });
                 
      })
    }
   
  const completeTest = () => {
    setConfirmModalVisible(true);
  };
 
  const handleCompleteTestConfirmation = () => {
    if (mcQuestionTestDetail) {
      submitTest(mcQuestionTestDetail?.enrollmentDetailsId).then(res => {
        goToCourseMainPage();
      });
    }
  }
 
  const clearState = () => {
    setCurrentQuestion(undefined);
    setMcQuestions([]);
    setMcQuestionTestDetail(undefined);
    setSelectedQuestion(0);
  };
 
  const closeConfirmationModal = () => {
    setConfirmModalVisible(false);
  };
 
  const updateTimeSpent = () => {
    if (mcQuestionTestDetailRef.current) {
      updateTimeSpentForTest(mcQuestionTestDetailRef.current.enrollmentDetailsId, timeSpentRef.current).then(res => {
      });
    }
  }
 
  return (
    <ContentLayout title="" type="nocard">
      <ConfirmationModal visible={confirmModalVisible} onCancel={closeConfirmationModal} onConfirmation={handleCompleteTestConfirmation} />
      <div style={{ paddingLeft: 10, fontSize: 24 }}>
        {mcQuestionTestDetail?.subModuleName}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 20,
          marginBottom: 20,
        }}
      >
        {mcQuestionTestDetail?.practiceTest ? (
          <Space>
            <span style={{ fontSize: 18, fontWeight: 600 }}>Time: {minutes} minutes {seconds} seconds</span>
            <Button htmlType="button" className="button-1" onClick={goToCourseMainPage}>
              Pause Test
            </Button>
          </Space>
        ) : (
          <Countdown value={deadline} onFinish={onTestTimeUp} />
        )}
 
        <Button htmlType="button" className="button-1" onClick={completeTest}>
          Submit Test
        </Button>
      </div>
      <Row gutter={8}>
        <Col span={10}>
          <Card
            headStyle={{ height: 65, fontSize: 18 }}
            bodyStyle={{ padding: "0", marginBottom: 66, bottom: 0, height:395 }}
            style={{
              borderRadius: "1rem",
              overflowY: 'auto',
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                background: '#fff',
                padding: '16px',
                borderBottom: '1px solid #f0f0f0',
              }}
            >
              <span style={{ fontSize: 18 }}>
                Questions: {key ? key : 0} / {mcQuestions?.length}
              </span>
            </div>
            <div style={{ flex: 1, padding: 16 }}>
              <p
                style={{ margin: 16, fontSize: 18 }}
                dangerouslySetInnerHTML={{
                  __html: currentQuestion?.question || "",
                }}
              ></p>
            </div>
          </Card>
          <Card style={{ marginTop: 10, textAlign: 'left' }}>
              <Button
                 onClick={() => navigateToPreviousQuestion(mcQuestions[currentIndex]?.id)}
                disabled={currentIndex === 0}
                className="button-1"
              >
                Previous
              </Button>
              <Button
              onClick={() => handleNextButtonClick(mcQuestions[currentIndex]?.id)}
              disabled={currentIndex === mcQuestions.length - 1}
              className="button-1"
              style={{  marginLeft: '400px' }}
            >
              Next
            </Button>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            headStyle={{ height: 65, fontSize: 18 }}
            bodyStyle={{ padding: "0", bottom: 0 }}
            style={{
              borderRadius: "1rem",
              overflowY: 'auto',
              display: "flex",
              flexDirection: "column",
            }} >            
            <div
               style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                background: '#fff',
                padding: '16px',
                borderBottom: '1px solid #f0f0f0',
              }}
            >
              <span style={{ fontSize: 18 }}>
                Answer Here:
              </span>
            </div>
         
             <StudentMCQAnswers
              question={currentQuestion}
              setAnswer={setAnswer}
              setActiveCheckboxQuestion={() => { }}
            />
 
          </Card>
          <Card style={{ marginTop: 10, textAlign: 'left' }}>
            <Button
              onClick={() => navigateToMarkReview(mcQuestions[currentIndex]?.id)}
              disabled={currentIndex === mcQuestions.length}
              className="button-1"
              style={{ marginRight: 16 }}
            >
              Mark as review
            </Button>
            <Button
              onClick={() => navigateToSkip(mcQuestions[currentIndex]?.id)}
              disabled={currentIndex === mcQuestions?.length || answeredQuestionsStatus[mcQuestions[currentIndex]?.id]}
              className="button-1"
              style={{  marginLeft: '440px' }}
            >
              Skip
            </Button>
          </Card>
        </Col>
        <Col span={2}>
          <div className="choice-box" style={{maxHeight:"550px", overflowY:"scroll"}}>
       
          {mcQuestions?.map((data, index) => (
            <div
              key={data.id}
              className={`option-box ${
                data.id === selectedQuestion
                  ? "active"
                  : reviewedQuestions.has(data.id)
                  ? "reviewed"
                  : skippedQuestions.has(data.id)
                  ? "skipped"
                  : answeredQuestionsStatus[data.id]
                  ? "answered"
                  : index === currentIndex
                  ? "current"
                  : ""
              }`}
              onClick={() => selectQuestion(data.id, index)}
            >
              {index + 1}
            </div>
          ))}
        </div>
 
       
     
        </Col>
      </Row>
      <div style={{ position: "relative", width: "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
        <div style={{ background: "white", border: "1px solid #f0f0f0", marginTop: "8px", borderRadius: "6px", paddingLeft: "20px", paddingRight: "20px" }} className="sample-box">
          <div>
            <span className="sample-choice-box description answered" style={{ marginLeft: "5px" }}></span>
            <span style={{ fontSize: "14px" }}>  Answered</span>
          </div>
          <div>
            <span className="sample-choice-box description unanswered" style={{ marginLeft: "5px" }}></span>
            <span style={{ fontSize: "14px" }}>  Unanswered</span>
          </div>
          <div>
            <span className="sample-choice-box description reviewed" style={{ marginLeft: "5px" }}></span>
            <span style={{ fontSize: "14px" }}>  Mark as Review</span>
          </div>
          <br />
          <div>
            <span className="sample-choice-box description skipped" style={{ marginLeft: "5px" }}></span>
            <span style={{ fontSize: "14px" }}>  Skipped</span>
          </div>
        </div>
      </div>
      {contextHolder}
    </ContentLayout>
  );
};
 
type StudentMCQAnswersProps = {
  question?: MultiChoiceQuestion;
  setAnswer: (questionId: number, answerId: number, isRadio: boolean) => void;
  setActiveCheckboxQuestion: (questionId: number) => void;
};
 
export const StudentMCQAnswers = ({
  question,
  setAnswer,
  setActiveCheckboxQuestion,
}: StudentMCQAnswersProps) => {
  const [selectedAnswers, setSelectedAnswers] = useState<Record<number, boolean>>({});
  const [buttonType, setButtonType] = useState<"radio" | "checkbox">("radio");
 
  useEffect(() => {
    const initialSelectedAnswers: Record<number, boolean> = {};
    question?.answers?.forEach((answer) => {
      initialSelectedAnswers[answer.id] = answer.selected || false;
    });
    setSelectedAnswers(initialSelectedAnswers);
    const numberOfAnswers = question?.noofanswers || 0;
    setButtonType(numberOfAnswers > 1 ? "checkbox" : "radio");
  }, [question]);
 
  const handleRadioChange = (answerId: number) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const newSelectedAnswers: Record<number, boolean> = {};
      Object.keys(prevSelectedAnswers).forEach((id) => {
        newSelectedAnswers[parseInt(id)] = parseInt(id) === answerId;
      });
      return newSelectedAnswers;
    });
    setAnswer(question?.id || 0, answerId, true);
  };
 
 
 
  const handleCheckboxChange = (answerId: number) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [answerId]: !prev[answerId],
    }));
    setAnswer(question?.id || 0, answerId, false);
  };
 
  const renderButton = (data: any) => {
    if (buttonType === "radio") {
      return (
        <Radio
          checked={selectedAnswers[data.id]}
          onChange={() => handleRadioChange(data.id)}
          style={{ marginRight: 10 }}
        />
      );
    } else {
      return (
        <Checkbox
          checked={selectedAnswers[data.id]}
          onChange={() => handleCheckboxChange(data.id)}
          style={{ marginRight: 10 }}
        />
      );
    }
  };
 
  const numberOfAnswers = question?.noofanswers || 0;
 
  return (
    <div style={{ padding: 16, maxHeight: 400, overflowY: "auto" }}>
      {question?.answers?.map((data, index) => (
        <Card
          key={index}
          style={{ marginBottom: 10, backgroundColor: "#dfeef5", cursor: "pointer" }}
          onClick={() => {
            setActiveCheckboxQuestion(question?.id || 0);
            numberOfAnswers === 1 ? handleRadioChange(data.id) : handleCheckboxChange(data.id);
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }}>
            {renderButton(data)}
            <div dangerouslySetInnerHTML={{ __html: data?.answer || "" }}></div>
          </div>
        </Card>
      ))}
    </div>
  );
};