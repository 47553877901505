import { Button, Modal, Space, Table, Tooltip } from "antd"
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import {
    EditOutlined,
    ArrowRightOutlined,
    EyeOutlined,
    CopyOutlined
} from '@ant-design/icons';


import { Test } from "../types";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import { TableParams } from "../../../types";
import { useState } from "react";
import { cloneTest } from "../api/cloneTest";


type TestListProps = {
    rowCount?: number;
    data?: Test[];
    loading: boolean;
    tableParams: TableParams;
    updateTableParams: (params: TableParams) => void;
    goToForm: (id: number, mode: string) => void;
    goToQuestions: (id: number, type: string) => void;
    handleCloneTest: (courseId: number) => void;
}

export const TestsList = ({ rowCount, data, loading, tableParams, updateTableParams, goToForm, goToQuestions,  handleCloneTest}: TestListProps) => {

    const [isCloneModalVisible, setIsCloneModalVisible] = useState(false);

    const columns: ColumnsType<Test> = [
        {
            key: 'name',
            title: 'Test Name',
            dataIndex: 'name',
            sorter: true,
            showSorterTooltip: false,
            width: 250,
            fixed: 'left',
            render: (id, data) => {
                return <div style={{display: 'flex', direction: 'ltr', gap: '5px', alignContent: 'center'}}>{data.name} {data.editable === false ? <div className="circle"> </div> : ''}</div>
            }
        },
        {
            key: 'testCode',
            title: 'Test Code',
            dataIndex: 'testCode',
            sorter: true,
            showSorterTooltip: false,
            width: 250,
            fixed: 'left'
        },
        {
            key: 'noOfQuestions',
            title: 'No of Questions',
            dataIndex: 'noOfQuestions',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'duration',
            title: 'Duration',
            dataIndex: 'duration',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'sectionName',
            title: 'Type',
            dataIndex: 'sectionName',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'createdBy',
            title: 'Created By',
            dataIndex: 'createdBy',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'updatedBy',
            title: 'Last Edited By',
            dataIndex: 'lastModifiedBy',
            sorter: true,
            showSorterTooltip: false,
            width: 250
        },
        {
            key: 'action',
            title: 'Action',
            dataIndex: 'id',
            align: 'center',
            width: 150,
            render: (id, data) => {
                return (
                    <Space wrap>
                        <Tooltip placement="top" title={data.editable ? "Edit Test" : "View Test"}>
                            <Button icon={data.editable ? <EditOutlined /> : <EyeOutlined />}  onClick={() => { goToForm(id, 'Edit') }} />
                        </Tooltip>
                        <Tooltip placement="top" title="Go To Questions">
                            <Button icon={<ArrowRightOutlined />} onClick={() => { goToQuestions(id, data.sectionName) }} />
                        </Tooltip>
                        <Tooltip placement="top" title="Clone Test">
                            <Button icon={<CopyOutlined />} onClick={() => { showConfirmModal(id) }} />
                        </Tooltip>
                    </Space>
                )
            },
        }
    ];

    const showConfirmModal = (testId: number) => {
        Modal.confirm({
            title: "Confirm Action",
            content: "Are you sure you want to clone this Test?",
            onOk: () => {
                setIsCloneModalVisible(false);
                if (testId !== null) {
                    handleCloneTest(testId);
                }
            },
            onCancel: () => {
                setIsCloneModalVisible(false);
            },
        });
    };

    const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any, extra: TableCurrentDataSource<Test>) => {
        let { sortField, sortOrder, searchInput } = tableParams;

        sortField = (sorter?.field) ? sorter.field : 'name';
        sortOrder = (sorter?.order) ? sorter.order : 'ascend';

        updateTableParams({
            pagination,
            sortField,
            sortOrder,
            searchInput
        });
    };

    return (
        <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={{ ...tableParams.pagination, total: rowCount, showTotal: (rowCount, range) => `${range[0]}-${range[1]} of ${rowCount} items`, defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: [10, 25, 50, 100] }}
            loading={loading}
            size="small"
            onChange={handleTableChange}
        />
    )
}