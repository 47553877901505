import { useCallback, useEffect, useRef, useState } from "react";
 import { ContentLayout } from "../../../components/layout/ContentLayout"
import { CodingTest } from "../types";
import qs from 'qs';
import { Button, Card, Col, Modal, Row, Select, Space, Statistic, Table, message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import CodeEditor from "../../../components/codeeditor/CodeEditor";
import { getCodingQuestionsForTest } from "../api/getCodingQuestionsForTest";
import { CodingQuestion, TestCase } from "../../codingquestions";
import { saveCodingTestDetails } from "../api/saveCodingTestDetails";
import { getCodingAnswer } from "../api/getCodingAnswer";
import { compileAndRunCode } from "../api/compileAndRunCode";
import { submitCode } from "../api/submitCode";
import { submitTest } from "../api/submitTest";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast } from "react-toastify";
import { updateTimeSpentForTest } from "../api/updateTimeSpentForTest";
import { notification } from "antd";
// import 'antd/dist/antd.css';

 
const { Countdown } = Statistic;
 
const testCaseCardSize = { height: '100px', width: '200px' }; // Define the size of the test case cards
 
const sampleTestCasecolumns = [
    {
      title: 'Input',
      dataIndex: 'input',
      key: 'input',
      render: (text: string) => (
        <div style={{ whiteSpace: 'pre-line', maxHeight: '500px', overflowY: 'auto' }}>
          {text}
        </div>
      ),
      width: 200, // Set a specific width
    },
    {
      title: 'Expected Output',
      dataIndex: 'output',
      key: 'output',
      render: (text: string) => (
        <div style={{ whiteSpace: 'pre-line', maxHeight: '500px', overflowY: 'auto' }}>
          {text}
        </div>
      ),
      width: 300, // Set a specific width
    },
    {
      title: 'Actual Output',
      dataIndex: 'actualOutput',
      key: 'actualOutput',
      render: (text: string) => (
        <div style={{ whiteSpace: 'pre-line', maxHeight: '500px', overflowY: 'auto' }}>
          {text}
        </div>
      ),
      width: 300, // Set a specific width
    },
    {
      title: 'Verified',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) =>
        status === 'Success' ? (
          <p style={{ color: 'green' }}>Success</p>
        ) : (
          <p style={{ color: 'red' }}>Failed</p>
        ),
      width: 100, // Set a specific width
    },
  ];
 
 
 
const nonSampletestCaseColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Verified',
        dataIndex: 'status',
        key: 'status',
        render: (status: string) => { return status === 'Success' ? <p style={{ color: 'green' }}>Success</p> : <p style={{ color: 'red' }}>Failed</p> },
    },
];
 
export const StudentCoding = () => {
 
    const [searchParams] = useSearchParams();
 
    const id = searchParams.get("enrollmentId");
    const sid = searchParams.get("subModuleId");
    const tid = searchParams.get("transactionId");
 
    const enrollmentId = id ? +id : 0;
    const subModuleId = sid ? +sid : 0;
    const transactionId = tid || "";
 
    const navigate = useNavigate();
    const [codingQuestionTestDetail, setCodingQuestionTestDetail] = useState<CodingTest>();
    const [codingQuestions, setCodingQuestions] = useState<CodingQuestion[]>([]);
    const [selectedQuestion, setSelectedQuestion] = useState<number>();
    const [codingAnswer, setCodingAnswer] = useState<string>('');
    const [key, setKey] = useState<number>(1);
    const [deadline, setDeadLine] = useState<number>(0);
    const [currentQuestion, setCurrentQuestion] = useState<CodingQuestion>();
    const [modal] = Modal.useModal();
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [languageMode, setLanguageMode] = useState<string>();
    const [solutionLanguages, setSolutionLanguages] = useState<string[]>([]);
    const [selectedLanguage, setSelectedLanguage] = useState<string>('');
 
    const [sampleTestCaseModalVisible, setSampleTestCaseModalVisible] = useState(false);
    const [nonSampleTestCaseModalVisible, setNonSampleTestCaseModalVisible] = useState(false);
    const [testCases, setTestCases] = useState<TestCase[]>();
 
    const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
    const [compilationErrMsg, setCompilationErrMsg] = useState<string>('');
    const [compilationErrModalVisible, setCompilationErrModalVisible] = useState<boolean>(false);
    const [timeSpent, setTimeSpent] = useState<number>(0);
    const [showDeveloperToolsAlert, setShowDeveloperToolsAlert] = useState(false);
 
 
    const codingQuestionTestDetailRef = useRef(codingQuestionTestDetail);
    const timeSpentRef = useRef(timeSpent);
 
    const [modalShown, setModalShown] = useState(false);
    const [errorOccurred, setErrorOccurred] = useState(false);
 
    const seconds = timeSpent % 60;
    const minutes = Math.floor(timeSpent / 60);
 
  const tableRef = useRef<HTMLDivElement>(null);

  const [scrollY, setScrollY] = useState<number | undefined>(undefined);
  const [scrollX, setScrollX] = useState<number | undefined>(undefined);

  const [hasUserTyped, sethasUserTyped]=useState(false);
  const [countdown, setCountdown] = useState(0);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false); 
  const [hoverTime, setHoverTime] = useState('');

  const [actionType, setActionType] = useState('');
  const [countdownLabel, setCountdownLabel] = useState('');

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
const [compileButtonDisabled, setCompileButtonDisabled] = useState(false);
  

 

const startCountdown = (actionType:any) => {
    setCountdown(5);
    setCountdownLabel(actionType);

    if (actionType === 'Submit Code') {
        setSubmitButtonDisabled(true);
    } else if (actionType === 'Compile and Run') {
        setCompileButtonDisabled(true);
    }

    const timer = setInterval(() => {
        setCountdown((prevCount) => {
            if (prevCount === 1) {
                clearInterval(timer);
                if (actionType === 'Submit Code') {
                    setSubmitButtonDisabled(false);
                } else if (actionType === 'Compile and Run') {
                    setCompileButtonDisabled(false);
                }
            }
            return prevCount - 1;
        });
    }, 1000);
};


  useEffect(() => {
        return () => {
            setModalShown(false);
        };
    }, []);
 
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.keyCode === 123) {
            event.preventDefault();
            setShowDeveloperToolsAlert(true);
          }
          else if (event.keyCode === 27) { // Escape key
            event.preventDefault();
          }
        };
   
        const handleRightClick = (event: MouseEvent) => {
          event.preventDefault();
          setShowDeveloperToolsAlert(true);
        };
   

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('contextmenu', handleRightClick);
   
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
          window.removeEventListener('contextmenu', handleRightClick);
        };
      }, [enrollmentId]);

   
      useEffect(() => {
        if (showDeveloperToolsAlert) {
          notification.warning({
            message: "Warning",
            description: "Right click and developer tools are disabled",
            onClose: () => setShowDeveloperToolsAlert(false)
          });
        }
      }, [showDeveloperToolsAlert]);

 
    useEffect(() => {
        window.onbeforeunload = function () {
            updateTimeSpent()
            return true;
        };
 
        return () => {
            window.onbeforeunload = null;
        };
    }, []);
 
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeSpent((prevSeconds) => prevSeconds + 1);
        }, 1000);
 
        return () => {
            clearInterval(timer);
            updateTimeSpent();
        };
    }, []);

    useEffect(() => {
        codingQuestionTestDetailRef.current = codingQuestionTestDetail;
        timeSpentRef.current = timeSpent;
    }, [codingQuestionTestDetail, timeSpent]);
 
    const languageOptions = solutionLanguages.map((language) => (
        <Select.Option key={language} value={language}>
            {language}
        </Select.Option>
    ));
 
    useEffect(() => {
        if (enrollmentId && enrollmentId !== 0 && subModuleId && subModuleId !== 0) {
            getCodingQuestionsForTest(enrollmentId, subModuleId, transactionId).then(res => {
                setCodingQuestionTestDetail(res);
            })
            .catch(error => {
                goToCourseMainPage();
            });
        }
    }, [enrollmentId, subModuleId])

 
    useEffect(() => {
        if (codingQuestionTestDetail) {
            setCodingQuestions(codingQuestionTestDetail.questions);
            setSelectedQuestion(codingQuestionTestDetail.questions[0].id);
            setDeadLine(() => {
                return Date.now() + 1000 * 60 * codingQuestionTestDetail.duration;
            });
            setTimeSpent(codingQuestionTestDetail.duration);
        }
    }, [codingQuestionTestDetail])
    
 
    useEffect(() => {
        if (selectedQuestion && selectedQuestion !== 0) {
            const questions = codingQuestions?.filter(q => q.id === selectedQuestion);
            if (questions && questions.length > 0) {
                setCurrentQuestion(questions[0]);
            }
        }
    }, [selectedQuestion])
 
    useEffect(() => {
    
        if (currentQuestion) {
            console.log("currentQuestion is present", currentQuestion);
    
            if (codingQuestionTestDetail?.solutionLanguages) {
                setSolutionLanguages(codingQuestionTestDetail.solutionLanguages.split(','));
            } else {
                setSolutionLanguages([currentQuestion.language]);
            }
            clearCompilationError();
    
            const enrollmentDetailsId = codingQuestionTestDetail?.enrollmentDetailsId;
            if (enrollmentDetailsId) {
                getCodingAnswer(enrollmentDetailsId, currentQuestion.id).then(res => {
                    setCodingAnswer(res.answerText ? res.answerText : '');
                    setSelectedLanguage(res.language || '');
                })
            }
            console.log(currentQuestion);
        } else {
            console.log("currentQuestion is null or undefined");
        }
    }, [currentQuestion]);



 

    // useEffect(() => {
    //     const handleFullscreenChange = () => {
    //       if (!document.fullscreenElement) {
    //         requestFullScreen();
    //       }
    //     };
    
    //     document.addEventListener("fullscreenchange", handleFullscreenChange);
    
    //     return () => {
    //       document.removeEventListener("fullscreenchange", handleFullscreenChange);
    //     };
    //   }, []);
    
    //   const requestFullScreen = () => {
    //     const element = document.documentElement;
    //     if (element.requestFullscreen) 
    //     {
    //       element.requestFullscreen();
    //     } 
    //     else if ((element as any).mozRequestFullScreen) 
    //     { // Firefox
    //       (element as any).mozRequestFullScreen();
    //     } 
    //     else if ((element as any).webkitRequestFullscreen) 
    //     { // Chrome, Safari and Opera
    //       (element as any).webkitRequestFullscreen();
    //     } 
    //     else if ((element as any).msRequestFullscreen) 
    //     { // IE/Edge
    //       (element as any).msRequestFullscreen();
    //     }
    //   };

    //   const handlers = {
    //     ESCAPE: () => {
    //       if (document.fullscreenElement) {
    //         requestFullScreen();
    //         toast.error('Attempted to exit fullscreen mode!');
    //       }
    //     },
    //   };
    
    //   useEffect(() => {
    //     const handleFullscreenChange = () => {
    //       console.log('Fullscreen change detected');
    //       if (!document.fullscreenElement) {
    //         console.log('Entering fullscreen mode');
    //         requestFullScreen();
    //         toast.error('Exited fullscreen mode!');
    //       }
    //     };
    
    //     document.addEventListener('fullscreenchange', handleFullscreenChange);
    
    //     return () => {
    //       document.removeEventListener('fullscreenchange', handleFullscreenChange);
    //     };
    //   }, []);
      
      
 
    useEffect(() => {
        if (selectedLanguage) {
            let mode = '';
 
            if (selectedLanguage === 'C++' || selectedLanguage === 'C') {
                mode = 'c_cpp';
            } else if (selectedLanguage === 'Java') {
                mode = 'java';
            } else if (selectedLanguage === 'JavaScript') {
                mode = 'javascript';
            } else if (selectedLanguage === 'Python') {
                mode = 'python';
            }
 
            setLanguageMode(mode);
        }
    }, [selectedLanguage]);
 
    useEffect(() => {
        if (tableRef.current) {
          const tableHeight = tableRef.current.scrollHeight;
          const tableWidth = tableRef.current.scrollWidth;
          const cardHeight = tableRef.current.clientHeight;
          const cardWidth = tableRef.current.clientWidth;
          if (tableHeight > cardHeight) {
            setScrollY(cardHeight - 16);
          } else {
            setScrollY(undefined);
          }
          if (tableWidth > cardWidth) {
            setScrollX(cardWidth - 16); 
          } else {
            setScrollX(undefined);
          }
        }
      }, [sampleTestCaseModalVisible]); 
 
    const countDown = () => 
        {
        let secondsToGo = 5;
 
        const instance = modal.success({
            title: 'Your time is up!',
            content: `This page will be closed in ${secondsToGo} seconds.`,
            footer: null
        });
 
        const timer = setInterval(() => {
            secondsToGo -= 1;
            instance.update({
                content: `This page will be closed in ${secondsToGo} seconds.`,
            });
        }, 1000);
 
        setTimeout(() => {
            clearInterval(timer);
            instance.destroy();
            handleCompleteTestConfirmation();
        }, secondsToGo * 1000);
    };
 
   
    const goToCourseMainPage = () => 
    {
        saveCodingTestAnswer(() => {});
        clearState();
        const params = { enrollmentId };
        navigate({
        pathname: '/student-course-main',
        search: qs.stringify(params),
        });
    };
 

    const selectQuestion = (questionId: number, key: number) => 
    {
        saveCodingTestAnswer(() => {
            setSelectedQuestion(questionId);
            setCurrentIndex(() => {
                return codingQuestions.findIndex((m) => {
                    return m.id === questionId;
                });
            });
            setKey(key);
        });
    }
 
    const next = () => {
        sethasUserTyped(false)
        saveCodingTestAnswer(()=>{})
        if (currentIndex < codingQuestions.length - 1) {
            const k = key + 1;
            const next = currentIndex + 1;
            setSelectedQuestion(() => {
                return codingQuestions[next].id;
            });
            setKey(k);
            setCurrentIndex(next);
        }
    }
 
    const previous = () => {
        saveCodingTestAnswer(()=>{})
        if (currentIndex > 0) {
            const k = key - 1;
            const pre = currentIndex - 1;
            setSelectedQuestion(() => {
                return codingQuestions[pre].id;
            });
            setKey(k);
            setCurrentIndex(pre);
        }
    }
 
    const onTestTimeUp = () => {
        countDown();
    }
 
    const clearState = () => {
        setCurrentQuestion({} as CodingQuestion);
        setCodingQuestions([]);
        setCodingQuestionTestDetail({} as CodingTest);
        setSelectedQuestion(0);
    }

    const saveCode = useCallback(() => {
        saveCodingTestAnswer(() => { })
    }, [codingQuestionTestDetail, selectedQuestion, codingAnswer]);
 
    const handleCodeEditorChange = (value: string) => {
        setCodingAnswer(value)
        sethasUserTyped(true);

    }
 
    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;
    
        if (hasUserTyped) {
            intervalId = setInterval(saveCode, 60000); // Change interval to 60 seconds
        }
    
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [hasUserTyped, saveCode]);
    

    // useEffect(() => {
    //     const interval = setInterval(saveCode, 15000);
 
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [saveCode]);
 
    const compileAndRun = () => {
        if (selectedLanguage === '') {
            toast.error('Please select a language before compiling & running.');
            return;
        }
    
        if (!codingAnswer.trim()) {
            toast.error('Please type your code before compiling & running.');
            return;
        }
    
        clearCompilationError();
    
        saveCodingTestAnswer(() => {
            if (codingQuestionTestDetail && selectedQuestion) {
                compileAndRunCode(codingQuestionTestDetail?.enrollmentDetailsId, selectedQuestion, selectedLanguage).then(res => {
                    if (res) {
                        if (res.compilationError) {
                            setCompilationErrMsg(res.compilationError);
                            setCompilationErrModalVisible(true);
                        } else {
                            setTestCases(res.testCases);
                            setNonSampleTestCaseModalVisible(false);
                            setSampleTestCaseModalVisible(true);
                        }
                    }
    
                    startCountdown('Compile and Run');
                });
            }
        });
    

        setButtonDisabled(true);
    };
   
 
    const submitCodeForStudent = () => {
        if (selectedLanguage === '') {
            toast.error('Please select a language before submitting code.');
            return;
        }
       
        if (!codingAnswer.trim()) {
            toast.error('Please type your code before submitting.');
            return;
        }
        clearCompilationError();
        saveCodingTestAnswer(() => {
            if (codingQuestionTestDetail && selectedQuestion) {
                submitCode(codingQuestionTestDetail?.enrollmentDetailsId, selectedQuestion, selectedLanguage)
                .then(res => {
                    if (res) {
                        if (res.compilationError) {
                            setCompilationErrMsg(res.compilationError);
                            setCompilationErrModalVisible(true);
                        } else {
                            const testCases: any[] = res.testCases.map((m, index) => {
                                return { name: `Test ${index + 1}`, status: m.status || "" };
                            });
                            setTestCases(testCases);
                            setSampleTestCaseModalVisible(false);
                            setNonSampleTestCaseModalVisible(true);
                     
                        }
                    }
                    startCountdown('Submit Code');
                });
            }
        });
        setButtonDisabled(true);
    };
 
 
    const saveCodingTestAnswer = useCallback((callback: any) => {
        const enrollmentDetailsId = codingQuestionTestDetail?.enrollmentDetailsId;
        const questionId = selectedQuestion;
        const answerText = codingAnswer || '';
        const data = { enrollmentDetailsId, questionId, answerText };
 
        saveCodingTestDetails(data)
            .then(res => {
                callback();
            })
            .catch(error => {
                if (!modalShown && !errorOccurred) {
                    toast.dismiss();
                    const params = { enrollmentId: id };
                    navigate({
                        pathname:'/student-course-main',
                        search: qs.stringify(params),
                    })
                    toast.dismiss();
                    setModalShown(true);
                    setErrorOccurred(true);
                    toast.dismiss();
                    Modal.error({
                        title: 'Error',
                        content: 'The test has ended. Please contact the admin or faculty',
                        centered: true,
                        style: {
                            width: '70%',
                            height: 'auto',
                        },
                        onOk: () => {
                            setModalShown(false);
                           
                            navigate({
                                pathname: '/student-course-main',
                                search: qs.stringify(params),
                            });
                        },
                       
                    });
                }
               
                    }
               
                );
                },[codingQuestionTestDetail, selectedQuestion, codingAnswer, modalShown, errorOccurred]);
   
   
    const completeTest = () => {
        saveCodingTestAnswer(()=>{})
        setConfirmModalVisible(true);
    };
 
    const handleCompleteTestConfirmation = () => {
        if (codingQuestionTestDetail) {
            submitTest(codingQuestionTestDetail?.enrollmentDetailsId).then(res => {
                goToCourseMainPage();
            });
        }
    }
 
    const handleOk = () => {
        setSampleTestCaseModalVisible(false);
    };
 
    const handleCancel = () => {
        setSampleTestCaseModalVisible(false);
    };
 
    const handleModalOk = () => {
        setNonSampleTestCaseModalVisible(false);
    };
 
    const handleModalCancel = () => {
        setNonSampleTestCaseModalVisible(false);
    };
 
    const closeConfirmationModal = () => {
        setConfirmModalVisible(false);
    };
 
    const clearCompilationError = () => {
        setCompilationErrMsg('');
        setCompilationErrModalVisible(false);
    }
 
    const updateTimeSpent = () => {
        if (codingQuestionTestDetailRef.current) {
            updateTimeSpentForTest(codingQuestionTestDetailRef.current.enrollmentDetailsId, timeSpentRef.current).then(res => {
            });
        }
    }
 

    const handleHover = () => {
        // if (actionTriggered) {
        //     setHoverTime(`${countdown} seconds`); // Display countdown time on hover
        // }
    };




    return (
        <ContentLayout title="" type="nocard">
            <ConfirmationModal visible={confirmModalVisible} onCancel={closeConfirmationModal} onConfirmation={handleCompleteTestConfirmation} />
            <Modal
                title="Test Cases - Results"
                open={sampleTestCaseModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                style={{ minWidth: '800px', minHeight: '400px' }}
                >
                <Card style={{ height: '100%', maxHeight: '500px', overflow: 'auto' }}>
                    <div ref={tableRef}>
                    <Table
                        dataSource={testCases}
                        columns={sampleTestCasecolumns}
                        pagination={false}
                        scroll={{ y: scrollY, x: scrollX }}
                    />
                    </div>
                </Card>
                </Modal>
                <Modal title="Test Cases - Results" open={nonSampleTestCaseModalVisible} onOk={handleModalOk} onCancel={handleModalCancel} >
                <Card >
                    <Table dataSource={testCases} columns={nonSampletestCaseColumns} pagination={false} style={{maxHeight:"60vh", overflowY:"auto"}} />
                </Card>
                </Modal>
            <div style={{ paddingLeft: 10, fontSize: 24 }}>{codingQuestionTestDetail?.subModuleName}</div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 20, marginBottom: 20 }}>
                {codingQuestionTestDetail?.practiceTest ? (
                    <Space>
                        <span style={{ fontSize: 18, fontWeight: 600 }}>Time: {minutes} minutes {seconds} seconds</span>
                        <Button htmlType="button" className="button-1" onClick={goToCourseMainPage}>
                            Pause Test
                        </Button>
                    </Space>
                ) : (
                    <Countdown value={deadline} onFinish={onTestTimeUp} />
                )}
                <Button htmlType="button" className="button-1" onClick={completeTest} >Submit Test</Button>
            </div>
            <Row gutter={8}>
                <Col span={10} >
                <Card
                    headStyle={{ height: 65, fontSize: 18 }}
                    bodyStyle={{ padding: "0" }}
                    style={{
                        borderRadius: "1rem",
                        height: 650,
                        display: "flex",
                        flexDirection: "column"
                    }}
                    title={"Questions : " + (key ? key : 0) + " / " + codingQuestions?.length}
                    >
                        <div style={{ flex: 1, padding: 16, height: 500, overflowY: 'auto' }}>
                            <p style={{ margin: 16, fontSize: 20, fontWeight: 'bold' }}>{currentQuestion?.name}</p>
                            <p style={{ margin: 16, fontSize: 18 }} dangerouslySetInnerHTML={{ __html: (currentQuestion && currentQuestion.problemStatement) ? currentQuestion.problemStatement : '' }}></p>
                            <p style={{ margin: 16, fontSize: 20, fontWeight: 'bold' }}>Input Format</p>
                            <p style={{ margin: 16, fontSize: 18 }}>{currentQuestion?.inputFormat}</p>
                            <p style={{ margin: 16, fontSize: 20, fontWeight: 'bold' }}>Output Format</p>
                            <p style={{ margin: 16, fontSize: 18 }}>{currentQuestion?.outputFormat}</p>
                            <p style={{ margin: 16, fontSize: 20, fontWeight: 'bold' }}>Constraint</p>
                            <Row gutter={16} style={{ marginBottom: 10 }}>
                                <Col>
                                    <Card style={{ borderRadius: '1rem', backgroundColor: '#dfeef5' }}>
                                        {currentQuestion?.constraints === null ? 'null' : currentQuestion?.constraints}
                                    </Card>
                                </Col>
                            </Row>

                          
                            <p style={{ margin: 16, fontSize: 20, fontWeight: 'bold' }}>Sample Test Cases</p>
                            {
                                currentQuestion?.testCases.map((data, k) => (
                                    <Row gutter={16} style={{ marginBottom: 10 }}>
                                        <Col span={12}>
                                            <Card style={{ borderRadius: '1rem', backgroundColor: '#dfeef5' }}>
                                                <Meta title="Input" />
                                                {/* {data.input} */}
 
                                                {data.input.split('\n').map((line, index) => (
                                                    <div key={index}>{line}</div>
                                                ))}
                                            </Card>
                                        </Col>
                                        <Col span={12}>
                                            <Card style={{ borderRadius: '1rem', backgroundColor: '#dfeef5' }}>
                                                <Meta title="Output" />
                                                {data.output.split('\n').map((line, index) => (
                                                    <div key={index}>{line}</div>
                                                ))}
                                            </Card>
                                        </Col>
                                    </Row>
                                ))
                            }
 
                        </div>
                        <div style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            height: 75,
                            borderTop: '1px solid #f0f0f0',
                            padding: 16,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Button onClick={previous} disabled={currentIndex === 0} className="button-1" style={{ alignSelf: 'flex-start' }}>Previous</Button>
                                <Button onClick={next} disabled={currentIndex === codingQuestions?.length - 1} className="button-1" style={{ alignSelf: 'flex-end' }}>Next</Button>
                            </Space>
                        </div>
 
 
                    </Card>
                </Col>
                <Col span={12} >
                        <Card
                        headStyle={{ height: 65, fontSize: 18 }}
                        bodyStyle={{ padding: 0}}
                        style={{
                            borderRadius: "1rem",
                            // minHeight: 550,
                            // height: 650,
                            // display: "flex",
                            // flexDirection: "column"
                        }}
                        title="Type Your Code Here"
                        extra={
                        <div>
                            <span style={{ marginRight: '10px', fontSize: 18, fontWeight: 600 }}>Choose Language:</span>
                            <Select
                                style={{ width: '150px' }}
                                value={selectedLanguage}
                                onChange={(value) => setSelectedLanguage(value)}
                            >
                                {languageOptions}
                            </Select>
                        </div>
                    }>
                        <div style={{ flex: 1, padding: 16 }}>
                            {currentQuestion?.enableHeaderFooter && (
                                <div style={{ marginBottom: 20 }}> <h3>Header:</h3>
                                    {/* @ts-ignore */}
                                    <CodeEditor mode={languageMode} value={currentQuestion?.header} onChange={null} height={350} readonly={true} />
                                </div>
                            )}
                          <div style={{ marginBottom: 20 }}>
                            <h3>Type Your Answer Here:</h3>
                            {/* @ts-ignore */}
                            <CodeEditor mode={languageMode} 
                                value={codingAnswer} 
                                onChange={handleCodeEditorChange} 
                                overflowYScroll={false} 
                                disablePaste={true} 
                            />
                            </div>

                           
                            {compilationErrModalVisible ?
                                <div className="message-container">
                                    <h2 className="title">Compilation Error!</h2>
                                    <p className="message">{compilationErrMsg}</p>
                                </div> : ''
                            }
 
                            {currentQuestion?.enableHeaderFooter && (
                                <div style={{ marginBottom: 20 }}> <h3>Footer:</h3>
                                    {/* @ts-ignore */}
                                    <CodeEditor mode={languageMode} value={currentQuestion?.footer} onChange={null} height={250} readonly={true} />
                                </div>
                            )}
 
                           
                        </div>
                        {currentQuestion?.header !== null && currentQuestion?.header !== null ?(
                        <div>
                            <br /><br /><br /><br />
                        <div style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            height: 75,
                            borderTop: '1px solid #f0f0f0',
                            padding: 16
                        }}>
                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                               
                                <Button onClick={submitCodeForStudent}  className={`button-1 ${submitButtonDisabled ? 'disabled' : ''}`} disabled={submitButtonDisabled}  onMouseEnter={handleHover}>
                                    Submit Code
                                </Button>
                                {countdown > 0 && countdownLabel === 'Submit Code' && (
                                    <p>Countdown ({countdownLabel}): {countdown} seconds</p>
                                )}
                                <Space>
                                    <Button
                                        onClick={compileAndRun}
                                        className={`button-1 ${compileButtonDisabled ? 'disabled' : ''}`}
                                        disabled={compileButtonDisabled}
                                        onMouseEnter={handleHover}
                                    >
                                        {hoverTime ? hoverTime : 'Compile & Run'}
                                    </Button>
                                    {countdown > 0 && countdownLabel === 'Compile and Run' && (
                                        <p>Countdown ({countdownLabel}): {countdown} seconds</p>
                                    )}
                                </Space>
                            </Space>
                        </div>
                        </div>
                        ):(
                            <div style={{
                                position: 'relative',
                                bottom: 0,
                                width: '100%',
                                padding: '10px 0',
                                borderTop: '1px solid #f0f0f0',
                            }}
                            >
                                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between', flex: 1, padding: 16 }}>
                                <Button onClick={submitCodeForStudent}  className={`button-1 ${submitButtonDisabled ? 'disabled' : ''}`} disabled={submitButtonDisabled}  onMouseEnter={handleHover}>
                                    Submit Code
                                </Button>
                                {countdown > 0 && countdownLabel === 'Submit Code' && (
                                    <p>Countdown ({countdownLabel}): {countdown} seconds</p>
                                )}
                                <Space>
                                    <Button
                                        onClick={compileAndRun}
                                        className={`button-1 ${compileButtonDisabled ? 'disabled' : ''}`}
                                        disabled={compileButtonDisabled}
                                        onMouseEnter={handleHover}
                                    >
                                        {hoverTime ? hoverTime : 'Compile & Run'}
                                    </Button>
                                    {countdown > 0 && countdownLabel === 'Compile and Run' && (
                                        <p>Countdown ({countdownLabel}): {countdown} seconds</p>
                                    )}
                                </Space>
                            </Space>
                                </div>
                        )}
                         {/* <ToastContainer /> */}
 
                    <div >  
                    </div>    
 
                    </Card>
                </Col>
                <Col span={2} >
                    <div className="choice-box" style={{ maxHeight: '500px',overflowY:"auto" }}>
                        {codingQuestions.map((data, k) => (
                            <div className={data.id === selectedQuestion ? "option-box active" : "option-box"} onClick={() => {
                                selectQuestion(data.id, k + 1)
                                sethasUserTyped(false)
                            }}> {k + 1}</div>
                        ))}
                    </div>
                </Col>
            </Row>
        </ContentLayout>
        
    )
}
 