import React from 'react';
import "./Title.css";

type HeadProps = {
    title: string;
}

export const Head = ({ title }: HeadProps) => (
    <div className="head">
        <h2>{title}</h2>
    </div>
);
