import React, { useState, useEffect } from 'react';
import { ContentLayout } from "../../../components/layout/ContentLayout";
import { Select, Card, Row, Col, Divider, Button, Space, Table } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import CodeEditor from "../../../components/codeeditor/CodeEditor";
import './ResponseInNewTab.css';
import "../../../index.css";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
 
interface ResponseProps {
  mcQuestionResult?: any;
  codingQuestionTestDetail?: any;
  testType?: string;
}
 
export const ResponseInNewTab: React.FC<ResponseProps> = ({ mcQuestionResult, codingQuestionTestDetail,testType }) => {
  const [toggleButtonText, setToggleButtonText] = useState<string>('Show Solution');
  const [isSolutionVisible, setIsSolutionVisible] = useState<boolean>(true);
 
  useEffect(() => {
    const removeAceActiveLine = () => {
      const activeLines = document.querySelectorAll('.ace_active_line');
      activeLines.forEach(activeLine => {
        activeLine.classList.remove('ace_active_line');
      });
    };
    removeAceActiveLine();
    return () => {
      removeAceActiveLine();
    };
  }, []);
 
  const handleToggleSolution = () => {
    setIsSolutionVisible(!isSolutionVisible);
    setToggleButtonText(isSolutionVisible ? 'Hide Solution' : 'Show Solution');
  };
 
 
return (
 
  <ContentLayout title="Course Results" type="nocard">
      {/* {testType === 'Coding' && (
        <Row gutter={16} style={{ marginBottom: 16  }}>
          <Col span={4}>
            <Button type="primary" onClick={handleToggleSolution}>
              {toggleButtonText}
            </Button>
          </Col>
        </Row>
      )} */}
     {mcQuestionResult && (
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={6}>
            <Card title="Total Questions" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {mcQuestionResult.totalQuestions}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Answered Questions" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {mcQuestionResult.answeredQuestions}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Skipped Questions" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {mcQuestionResult.skippedQuestions}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Score" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {mcQuestionResult.score} %
            </Card>
          </Col>
        </Row>
      )}
      {/* if selectedTestType == 'MultiChoice' render below block */}
 
      {testType === 'Multi Choice' && mcQuestionResult?.questionInfoList.map((question:any, index:any) => (
        <>
          <Card
            headStyle={{ height: 65, fontSize: 18 }}
            bodyStyle={{ padding: "0" }}
            style={{
              borderRadius: "1rem",
              minHeight: 550,
              display: "flex",
              flexDirection: "column",
            }}
            title={`Question ${index + 1}`}
          >
            <Row gutter={8} key={index} style={{ marginBottom: 16 }}>
              <Col span={12}>
                <div style={{ flex: 1, padding: 16 }}>
                  <p
                    style={{ margin: 16, fontSize: 18 }}
                    dangerouslySetInnerHTML={{
                      __html: question.questionText || "",
                    }}
                  ></p>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ padding: 16 }}>
                  {question.answers.map((data:any, ansIndex:any) => (
                    <div
                      key={ansIndex}
                      style={{
                        marginBottom: 10,
                        backgroundColor: data.correctAnswer ? "#baffb0" : data.studentAnswered ? "#ffd700" : "#dfeef5",
                        padding: "10px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: data?.answerText || "" }}
                        style={{ flex: 1 }}
                      />
                      {(data.correctAnswer && data.correctAnswer === data.studentAnswered) && (
                        <CheckCircleOutlined style={{ color: "green", fontSize: 25, marginLeft: 15 }} />
                      )}
                      {(!data.correctAnswer && data.studentAnswered) && (
                        <CloseCircleOutlined style={{ color: "red", fontSize: 25, marginLeft: 15 }} />
                      )}
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Card>
          <Divider style={{ margin: "16px 0" }} />
        </>
      ))}
 
      {codingQuestionTestDetail && (
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={6}>
            <Card title="Total Questions" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {codingQuestionTestDetail.totalQuestions}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Answered Questions" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {codingQuestionTestDetail.answeredQuestions}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Skipped Questions" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {codingQuestionTestDetail.skippedQuestions}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Score" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {codingQuestionTestDetail.score} %
            </Card>
          </Col>
        </Row>
      )}
 
      {/* if selectedTestType == 'Coding' render below block */}
      {testType === 'Coding' && codingQuestionTestDetail?.codingQuestionsInfo.map((question:any, index:any) => (
        <>
          <Card
            headStyle={{ height: 65, fontSize: 18 }}
            bodyStyle={{ padding: "0" }}
            style={{
              borderRadius: "1rem",
              minHeight: 550,
              display: "flex",
              flexDirection: "column",
            }}
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>
                  {`Question ${index + 1}`}
                </div>
                <div>
                  {`Answered Language: ${question.answeredLanguage}`}
                </div>
              </div>
            }
          >
                 <Row gutter={8} key={index} style={{ marginBottom: 16 }}>
                 <Col span={12}>
                      {/* Displaying the question text */}
                      <div style={{ flex: 1, margin: 20, padding: 16, maxHeight: 410, overflowY: 'scroll', border: "1px solid #dbdbd9", borderRadius: "1rem" }}>
                        <p
                          style={{ margin: 16, fontSize: 18 }}
                          dangerouslySetInnerHTML={{
                            __html: question.questionText || "",
                          }}
                        ></p>
                      </div>
                      {isSolutionVisible  && (
                        <div style={{ marginLeft: 20 }}>
                        <h3>Solution:</h3>
                        {/* @ts-ignore */}
                        <CodeEditor mode='java'  value={question.solution}   onChange={null}
                          width={575}
                          showGutter={false}
                          overflowYScroll={true} // Set overflowYScroll to true or false based on your requirement
                          style={{ maxHeight: 300, maxWidth: 300 }}
                        />
                      </div>
                     
                      )}
                    </Col>
 
              <Col span={12} >
                {question?.enableHeaderFooter && (
                  <div style={{ marginBottom: 20 }}> <h3>Header:</h3>
                    {/* @ts-ignore */}
                    <CodeEditor mode='java' value={question?.header} onChange={null} height={250} readonly={true} />
                  </div>
                )}
                <div style={{ marginBottom: 20,marginLeft:30 }}> <h3>Your Answer:</h3>
                  {/* @ts-ignore */}
                  <CodeEditor mode='java' value={question.studentAnswer} onChange={null}  showGutter={false}   overflowYScroll={true}  />
                </div>
                {question?.enableHeaderFooter && (
                  <div style={{ marginBottom: 20 }}> <h3>Footer:</h3>
                    {/* @ts-ignore */}
                    <CodeEditor mode='java' value={question?.header} onChange={null} height={250} readonly={true} />
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div style={{ padding: 25 }}>
                  {/* Display Sample Test Cases */}
                  <h3>Sample Test Cases:</h3>
                  <Table
                    dataSource={question.answers.filter((testCase: any) => testCase.sampleTestCase)}
                    columns={[
                      { title: 'Input', dataIndex: 'input', key: 'input' },
                      { title: 'Expected Output', dataIndex: 'expectedOutput', key: 'expectedOutput' },
                      { title: 'Actual Output', dataIndex: 'actualOutput', key: 'actualOutput' },
                      {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (text) => (
                          <span style={{ color: text === 'Success' ? 'green' : 'red' }}>
                            {text}
                          </span>
                        )
                      }
                    ]}
                    pagination={false}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div style={{ padding: 25 }}>
                  {/* Display Weighted Test Cases */}
                  <h3>Weighted Test Cases:</h3>
                  <Table
                    dataSource={question.answers.filter((testCase:any) => !testCase.sampleTestCase)}
                    columns={[
                      {
                        title: 'Test Case',
                        dataIndex: 'input',
                        key: 'input',
                        render: (text, record, index) => `Test Case ${index + 1}`
                      },
                      {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (text) => (
                          <span style={{ color: text === 'Success' ? 'green' : 'red' }}>
                            {text}
                          </span>
                        )
                      }
                    ]}
                    pagination={false}
                  />
                </div>
              </Col>
            </Row>
          </Card>
          <Divider style={{ margin: "16px 0" }} />
        </>
      ))}
    </ContentLayout>
  );
};
 
 
 