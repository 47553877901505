import { useEffect, useState } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout"

import { getMultiChoiceQuestions } from "../api/getMultiChoiceQuestions";

import { MultiChoiceQuestionsList } from "../components/MultiChoiceQuestionsList";

import { MCQTableParams, MultiChoiceQuestion } from "../types";
import qs from 'qs';
import { Button, Col, Row, Upload } from "antd";
import {
    PlusOutlined,
    UploadOutlined
} from '@ant-design/icons';
import Search from "antd/es/input/Search";
import { useNavigate, useSearchParams } from "react-router-dom";
import { uploadMultiChoiceQuestions } from "../api/uploadMultiChoiceQuestions";
import { getQuestionBankById } from "../../questionbanks/api/getQuestionBankById";


const getQueryParams = (params: MCQTableParams) => ({
    limit: params.pagination?.pageSize,
    offset: params.pagination?.current,
    orderBy: params.sortField,
    order: params.sortOrder,
    searchInput: params.searchInput,
    qbkId: params.qbkId
});


export const MultiChoiceQuestions = () => {

    const navigate = useNavigate();
    const [searchInput, SetSearchInput] = useState<string>();
    const [rowCount, setRowCount] = useState<number>(0);
    const [data, setData] = useState<MultiChoiceQuestion[]>();
    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const [tableParams, setTableParams] = useState<MCQTableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sortField: 'name',
        sortOrder: 'asc',
        searchInput: '',
        qbkId: 0
    });


    const [searchParams] = useSearchParams();
    const id = searchParams.get('qbkId');
    const qbkId = (id !== null && id !== '') ? +id : 0;

    useEffect(() => {
        if (qbkId !== 0) {
            getQuestionBankById(qbkId).then(res => {
                setEditable(res.editable);
            })
            setTableParams({
                ...tableParams,
                qbkId: qbkId
            })
        }
    }, [searchParams]);


    /* Functions for MultiChoiceQuestionList */
    const fetchData = () => {
        setLoading(true);
        getMultiChoiceQuestions(qs.stringify(getQueryParams(tableParams))).then((res) => {
            setRowCount(res.totalElements);
            setLoading(false)
            setData(res.content)
        }, () => {
            setLoading(false)
        });
    };

    useEffect(() => {
        if (tableParams.qbkId !== 0) {
            fetchData();
        }
    }, [tableParams]);


    const updateTableParams = (params: MCQTableParams) => {
        setTableParams({
            ...params,
            searchInput
        });
    }

    const onSearch = (searchInput: string) => {
        let query = ''
        if (searchInput !== '') {
            query = `name=like=${searchInput};category=like=${searchInput};subCategory=like=${searchInput};difficultyLevel=like=${searchInput}`
        }
        SetSearchInput(query);
        setTableParams({
            ...tableParams,
            searchInput: query
        })
    }

    const goToForm = (id: number, mode: string) => {
        const params = { id, mode, qbkId };
        navigate({
            pathname: '/mcquestion-form',
            search: qs.stringify(params),
        });
    }

    const fileUploadRequest = ({ file, onSuccess, onError }: any) => {
        if (qbkId && qbkId !== 0) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('questionbank_id', qbkId.toString());
            uploadMultiChoiceQuestions(formData).then(res => {
                onSuccess('File successfully uploaded.')
                fetchData();
            }, err => {
                onError('Filed to upload file.')
            });
        }
    };

    const goToQuestionBankMain = () => {
        navigate('/questionbanks');
    }

    return (
        <ContentLayout title="Multi Choice Questions">
            <Row style={{ marginBottom: 16 }} gutter={[20, 25]} justify='end'>
                <Col>
                    <Button onClick={goToQuestionBankMain}>Back</Button>
                </Col>
                <Col>
                    <Search placeholder="Search" enterButton onSearch={onSearch} />
                </Col>
                {editable === false ? null : (
                    <>
                        <Col>
                            <Button type='primary' icon={<PlusOutlined />} onClick={() => goToForm(0, 'Add')}>Create MultiChoiceQuestion</Button>
                        </Col>
                        <Col>
                            <Upload customRequest={fileUploadRequest}>
                                <Button icon={<UploadOutlined />}>Upload Questions</Button>
                            </Upload>
                        </Col>
                    </>
                )}
            </Row>
            <Row>
                <Col span={24}>
                    <MultiChoiceQuestionsList data={data} loading={loading} tableParams={tableParams} rowCount={rowCount} updateTableParams={updateTableParams} goToForm={goToForm} />
                </Col>
            </Row>
        </ContentLayout>
    )
}