import { useRoutes } from 'react-router-dom';
import { useAuth } from '../lib/auth';

import { adminRoutes, studentRoutes, facultyRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const auth = useAuth();
  const user = auth.user;
  let routes = publicRoutes;

  if (auth.isLoggedIn) {
    if (user?.role === 'ADMIN') {
      routes = adminRoutes;
    } else if (auth.isLoggedIn && user?.role === 'STUDENT') {
      routes = studentRoutes;
    } else if (user?.role === 'FACULTY') {
      routes = facultyRoutes;
    }
  }

  const element = useRoutes([...routes]);

  return <>{element}</>;
};
