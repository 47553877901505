import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { API_URL } from '../config';
import storage from '../utils/storage';
import { toast } from 'react-toastify';

export const axios = Axios.create({
  baseURL: API_URL,
});

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = storage.getToken();
    config.headers = {
      'Accept': 'application/json',
      // 'Content-Type': 'application/json',
    };
    if (token) {
      const tokenHeader = { 'Authorization': `Bearer ${token}` };
      config.headers = { ...config.headers, ...tokenHeader };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    showSuccessToastForPostAndPut(response.config, response.data);
    return response.data;
  },
  (error: AxiosError) => {
    console.log("error -> ", error);
    const errorMessage = error.response?.data || 'Something went wrong!';
    toast.error(errorMessage.toString());
    console.log("before return -> ", errorMessage);
    // Pass the error to the component
    return Promise.reject(errorMessage);
  }
);

const showSuccessToastForPostAndPut = (config: AxiosRequestConfig, response?: any) => {
  const ignoredUrls = ['/auth/login', '/students/courses/coding/post/answer', '/students/courses/mcq/post/answer'];
  const { method, url } = config;
  const isSuccessResponse = method === 'post' || method === 'put';

  if (isSuccessResponse && url && !ignoredUrls.includes(url)) {
    const successMessage = response && response.message ? response.message : 'Success!';
    toast.success(successMessage);
  }
};

axios.interceptors.request.use((config) => {
  return config;
});
