import React,{useState,useEffect} from 'react';
import { Form, Input, Button,notification } from 'antd';
import './LoginForm.css';
import { useAuth } from '../../../lib/auth';
import { LoginCredentialsDTO } from '../api/login';
import loginPageImage from '../../../images/login_page_image.jpg';
import { useNavigate } from 'react-router-dom';
 
 
const LoginForm = () => {
    const navigate = useNavigate();
    const { loginFn } = useAuth();
    const [showDeveloperToolsAlert, setShowDeveloperToolsAlert] = useState(false);
 
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.keyCode === 123) {
            event.preventDefault();
            setShowDeveloperToolsAlert(true);
          }
        };
   
        const handleRightClick = (event: MouseEvent) => {
          event.preventDefault();
          setShowDeveloperToolsAlert(true);
        };
   
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('contextmenu', handleRightClick);
   
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
          window.removeEventListener('contextmenu', handleRightClick);
        };
      }, []);
   
      useEffect(() => {
        if (showDeveloperToolsAlert) {
          notification.warning({
            message: "Warning",
            description: "Right click and developer tools are disabled",
            onClose: () => setShowDeveloperToolsAlert(false)
          });
        }
      }, [showDeveloperToolsAlert]);
 
      const handleLogin = (loginCredentials: LoginCredentialsDTO) => {
        const trimmedUsername = loginCredentials.username.trim();
        const trimmedCredentials = { ...loginCredentials, username: trimmedUsername };
   
        loginFn(trimmedCredentials).then(res => {
            if (res.role === 'STUDENT') {
                navigate('/student-courses');
            }
            if (res.role === 'ADMIN') {
                navigate('/colleges');
            }
            if (res.role === 'FACULTY') {
                navigate('/dashboard');
            }
        });
    };
   
 
    return (
        <div className="login-page">
            <div className="login-box">
                <div className="illustration-wrapper">
                    <img src={loginPageImage} alt="Login" />
                </div>
                <Form
                    name="login-form"
                    initialValues={{ remember: true }}
                    onFinish={handleLogin}
                    autoComplete="off"
                >
                    <p className="form-title">Welcome back</p>
                    <p>Login to the Dashboard</p>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please enter Username' }]}
                    >
                        <Input
                            placeholder="Username"
                        />
                    </Form.Item>
 
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please enter Password' }]}
                    >
                        <Input.Password
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item style={{ 'marginTop': 30 }}>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            LOGIN
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
 
export default LoginForm;