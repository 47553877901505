import { useEffect, useState } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout"
import { FormMode, TableParams } from "../../../types";
import { CollegesList } from "../components/CollegesList"
import { College } from "../types";
import { getColleges } from "../api/getColleges";
import qs from 'qs';
import { Button, Col, Row } from "antd";
import {
    PlusOutlined
} from '@ant-design/icons';
import Search from "antd/es/input/Search";
import { createCollege } from "../api/createCollege";
import { CollegeForm } from "../components/CollegeForm";
import { getCollegeById } from "../api/getCollegeById";
import { updateCollege } from "../api/updateCollege";


const getQueryParams = (params: TableParams) => ({
    limit: params.pagination?.pageSize,
    offset: params.pagination?.current,
    orderBy: params.sortField,
    order: params.sortOrder,
    searchInput: params.searchInput
});

export const Colleges = () => {

    /* State for CollegeList */
    const [searchInput, SetSearchInput] = useState<string>();
    const [rowCount, setRowCount] = useState<number>(0);
    const [data, setData] = useState<College[]>();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sortField: 'name',
        sortOrder: 'asc',
        searchInput: ''
    });

    /* State for CollegeForm */
    const [openCollegeForm, setOpenCollegeForm] = useState<boolean>(false);
    const [mode, setMode] = useState<string>('Add');
    const [collegeData, setCollegeData] = useState<College>({} as College);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    /* Functions for CollegeList */
    const fetchData = () => {
        setLoading(true);
        getColleges(qs.stringify(getQueryParams(tableParams))).then((res) => {
            setRowCount(res.totalElements);
            setLoading(false)
            setData(res.content)
        }, err => {
            setLoading(false)
        });
    };

    useEffect(() => {
        fetchData();
    }, [tableParams]);


    const updateTableParams = (params: TableParams) => {
        setTableParams({
            ...params,
            searchInput
        });
    }

    const onSearch = (searchInput: string) => {
        let query = ''
        if(searchInput !== '') {
            query = `name=like=${searchInput};email=like=${searchInput};phone=like=${searchInput};departments=like=${searchInput}`
        }
        SetSearchInput(query);
        setTableParams({
            ...tableParams,
            searchInput: query
        })
    }

    /* Functions for CollegeForm */
    const onClose = () => {
        setOpenCollegeForm(false);
        fetchData();
    };

    const openForm = () => {
        setMode('Add');
        setOpenCollegeForm(true);
    }

    const saveCollege = (data: College) => {
        setSaveLoading(true);
        if (mode === 'Edit') {
            updateCollege(data).then((res) => {
                setSaveLoading(false);
                onClose();
            }, err => {
                setSaveLoading(false);
            });
        } else {
            createCollege(data).then((res) => {
                setSaveLoading(false);
                onClose();
            }, err => {
                setSaveLoading(false);
            });
        }
    }

    const handleEdit = (id: number, mode: FormMode) => {
        getCollegeById(id).then((res) => {
            setCollegeData(res);
            setMode(mode);
            setOpenCollegeForm(true);
        });
    }

    return (
        <ContentLayout title="Colleges">
            <CollegeForm onClose={onClose} open={openCollegeForm} mode={mode} onSave={saveCollege} data={collegeData} loading={saveLoading} />
            <Row style={{ marginBottom: 16 }} gutter={[20, 25]} justify='end'>
                <Col>
                    <Search placeholder="Search" enterButton onSearch={onSearch} />
                </Col>
                <Col>
                    <Button type='primary' icon={<PlusOutlined />} onClick={openForm}>Create College</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <CollegesList data={data} loading={loading} tableParams={tableParams} rowCount={rowCount} updateTableParams={updateTableParams} handleEdit={handleEdit} />
                </Col>
            </Row>
        </ContentLayout>
    )
}