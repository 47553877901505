import React, { useState, useEffect } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout";
import { Select, Card, Row, Col, Divider, Button, Space, Table } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCompletedSubmodules } from "../api/getCompletedSubmodules";
import { Submodule } from "../../courses";
import { CodingQuestionResult, MCQuestionResult } from "../types";
import { getMcqTestResults } from "../api/getMcqTestResults";
import qs from 'qs';
import { getCodingTestResults } from "../api/getCodingTestResults";
import CodeEditor from "../../../components/codeeditor/CodeEditor";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { notification } from "antd";
 
const { Option } = Select;
 
export const StudentCourseResults: React.FC = () => {
 
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('enrollmentId');
  const enrollmentId = (id && id !== '') ? +id : 0;
 
  const mid = searchParams.get('moduleId');
  const moduleId = (mid && mid !== '') ? +mid : 0;
 
  const [completedSubmodules, setCompletedSubmodules] = useState<Submodule[]>([]);
  const [mcQuestionResult, setMcQuestionResult] = useState<MCQuestionResult>();
  const [codingQuestionTestDetail, setCodingQuestionTestDetail] = useState<CodingQuestionResult>();
  const [selectedTestType, setSelectedTestType] = useState<string>('');
  const [isSolutionVisible, setIsSolutionVisible] = useState<boolean>(false);
  const [toggleButtonText, setToggleButtonText] = useState<string>('Show Solution');
  const [showDeveloperToolsAlert, setShowDeveloperToolsAlert] = useState(false);
 
 
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 123) {
        event.preventDefault();
        setShowDeveloperToolsAlert(true);
      }
    };
 
    const handleRightClick = (event: MouseEvent) => {
      event.preventDefault();
      setShowDeveloperToolsAlert(true);
    };
 
 
   
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('contextmenu', handleRightClick);
 
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('contextmenu', handleRightClick);
    };
  }, [enrollmentId]);
 
  useEffect(() => {
    if (showDeveloperToolsAlert) {
      notification.warning({
        message: "Warning",
        description: "Right click and developer tools are disabled",
        onClose: () => setShowDeveloperToolsAlert(false)
      });
    }
  }, [showDeveloperToolsAlert]);
 
  const handleToggleSolution = () => {
    setIsSolutionVisible(!isSolutionVisible);
    setToggleButtonText(isSolutionVisible ? 'Show Solution' : 'Hide Solution');
  };
 
  const handleSubModuleChange = (value: string) => {
    const [enrollmentDetailId, submoduleId, testId, testType] = value.split(',');
    setSelectedTestType(testType);
    setMcQuestionResult(undefined);
    setCodingQuestionTestDetail(undefined);
    setIsSolutionVisible(false);
 
    if (testType === 'Multi Choice') {
      getMcqTestResults(+enrollmentDetailId, +testId)
        .then(res => setMcQuestionResult(res))
        .catch(err => console.error(err));
    }
 
    if (testType === 'Coding') {
      getCodingTestResults(+enrollmentDetailId, +submoduleId, +testId)
        .then(res => setCodingQuestionTestDetail(res))
        .catch(err => console.error(err));
    }
 
  };
 
  useEffect(() => {
    if (enrollmentId && moduleId && enrollmentId !== 0 && moduleId !== 0) {
      getCompletedSubmodules(enrollmentId, moduleId)
        .then(res => setCompletedSubmodules(res))
        .catch(err => console.error(err));
    }
  }, [enrollmentId, moduleId]);
 
  const goToCourseMainPage = () => {
    const params = { enrollmentId }
    navigate({
      pathname: '/student-course-main',
      search: qs.stringify(params)
    });
  }
 
  return (
    <ContentLayout title="Course Results" type="nocard">
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Select
            placeholder="Select Test"
            onChange={handleSubModuleChange}
            style={{ width: "100%" }}
          >
            {completedSubmodules.map(subModule => (
              <Option key={subModule.enrollmentDetailId} value={`${subModule.enrollmentDetailId.toString()},${subModule.submoduleId.toString()},${subModule.testId.toString()},${subModule.testType}`}>
                {subModule.submoduleName}
              </Option>
            ))}
          </Select>
        </Col>
 
        <Col span={4}>
          <Space>
            {selectedTestType === 'Coding' && (
 
              <Button
                type="primary"
                onClick={handleToggleSolution}
                disabled={!codingQuestionTestDetail?.showSolution}
              >
                {toggleButtonText}
              </Button>
            )}
            <Button type="primary" onClick={goToCourseMainPage}>
              Back
            </Button>
          </Space>
        </Col>
      </Row>
      {mcQuestionResult && (
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={6}>
            <Card title="Total Questions" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {mcQuestionResult.totalQuestions}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Answered Questions" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {mcQuestionResult.answeredQuestions}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Skipped Questions" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {mcQuestionResult.skippedQuestions}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Score" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {mcQuestionResult.score} %
            </Card>
          </Col>
        </Row>
      )}
      {/* if selectedTestType == 'MultiChoice' render below block */}
      {selectedTestType === 'Multi Choice' && mcQuestionResult?.questionInfoList.map((question, index) => (
        <>
          <Card
            headStyle={{ height: 65, fontSize: 18 }}
            bodyStyle={{ padding: "0" }}
            style={{
              borderRadius: "1rem",
              minHeight: 550,
              display: "flex",
              flexDirection: "column",
            }}
            title={`Question ${index + 1}`}
          >
            <Row gutter={8} key={index} style={{ marginBottom: 16 }}>
              <Col span={12}>
                <div style={{ flex: 1, padding: 16 }}>
                  <p
                    style={{ margin: 16, fontSize: 18 }}
                    dangerouslySetInnerHTML={{
                      __html: question.questionText || "",
                    }}
                  ></p>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ padding: 16 }}>
                  {question.answers.map((data, ansIndex) => (
                    <div
                      key={ansIndex}
                      style={{
                        marginBottom: 10,
                        backgroundColor: data.correctAnswer ? "#baffb0" : data.studentAnswered ? "#ffd700" : "#dfeef5",
                        padding: "10px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: data?.answerText || "" }}
                        style={{ flex: 1 }}
                      />
                      {(data.correctAnswer && data.correctAnswer === data.studentAnswered) && (
                        <CheckCircleOutlined style={{ color: "green", fontSize: 25, marginLeft: 15 }} />
                      )}
                      {(!data.correctAnswer && data.studentAnswered) && (
                        <CloseCircleOutlined style={{ color: "red", fontSize: 25, marginLeft: 15 }} />
                      )}
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Card>
          <Divider style={{ margin: "16px 0" }} />
        </>
      ))}
 
      {codingQuestionTestDetail && (
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={6}>
            <Card title="Total Questions" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {codingQuestionTestDetail.totalQuestions}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Answered Questions" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {codingQuestionTestDetail.answeredQuestions}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Skipped Questions" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {codingQuestionTestDetail.skippedQuestions}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Score" style={{ marginBottom: 16 }} bodyStyle={{ fontSize: 20 }}>
              {codingQuestionTestDetail.score} %
            </Card>
          </Col>
        </Row>
      )}
 
      {/* if selectedTestType == 'Coding' render below block */}
      {selectedTestType === 'Coding' && codingQuestionTestDetail?.codingQuestionsInfo.map((question, index) => (
        <>
          <Card
            headStyle={{ height: 65, fontSize: 18 }}
            bodyStyle={{ padding: "0" }}
            style={{
              borderRadius: "1rem",
              minHeight: 550,
              display: "flex",
              flexDirection: "column",
            }}
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>
                  {`Question ${index + 1}`}
                </div>
                <div>
                  {`Answered Language: ${question.answeredLanguage}`}
                </div>
              </div>
            }
          >
            <Row gutter={8} key={index} style={{ marginBottom: 16 }}>
              <Col span={12}>
                <div style={{ flex: 1, margin: 20, padding: 16, maxHeight: 410, overflowY: 'scroll', border: "1px solid #dbdbd9", borderRadius: "1rem" }}>
                  <p
                    style={{ margin: 16, fontSize: 18 }}
                    dangerouslySetInnerHTML={{
                      __html: question.questionText || "",
                    }}
                  ></p>
                </div>
                {isSolutionVisible && (
                  <div style={{ marginLeft: 20 }}>
                    <h3>Solution:</h3>
                    {/* @ts-ignore */}
                    <CodeEditor mode='java' value={question.solution} onChange={null} width={575} />
                  </div>
                )}
              </Col>
              <Col span={12}>
                {question?.enableHeaderFooter && (
                  <div style={{ marginBottom: 20 }}> <h3>Header:</h3>
                    {/* @ts-ignore */}
                    <CodeEditor mode='java' value={question?.header} onChange={null} height={250} readonly={true} />
                  </div>
                )}
                <div style={{ marginBottom: 20 }}> <h3>Your Answer:</h3>
                  {/* @ts-ignore */}
                  <CodeEditor mode='java' value={question.studentAnswer} onChange={null} />
                </div>
                {question?.enableHeaderFooter && (
                  <div style={{ marginBottom: 20 }}> <h3>Footer:</h3>
                    {/* @ts-ignore */}
                    <CodeEditor mode='java' value={question?.header} onChange={null} height={250} readonly={true} />
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div style={{ padding: 25 }}>
                  {/* Display Sample Test Cases */}
                  <h3>Sample Test Cases:</h3>
                  <Table
                    dataSource={question.answers.filter(testCase => testCase.sampleTestCase)}
                    columns={[
                      { title: 'Input', dataIndex: 'input', key: 'input' },
                      { title: 'Expected Output', dataIndex: 'expectedOutput', key: 'expectedOutput' },
                      { title: 'Actual Output', dataIndex: 'actualOutput', key: 'actualOutput' },
                      {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (text) => (
                          <span style={{ color: text === 'Success' ? 'green' : 'red' }}>
                            {text}
                          </span>
                        )
                      }
                    ]}
                    pagination={false}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div style={{ padding: 25 }}>
                  {/* Display Weighted Test Cases */}
                  <h3>Weighted Test Cases:</h3>
                  <Table
                    dataSource={question.answers.filter(testCase => !testCase.sampleTestCase)}
                    columns={[
                      {
                        title: 'Test Case',
                        dataIndex: 'input',
                        key: 'input',
                        render: (text, record, index) => `Test Case ${index + 1}`
                      },
                      {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (text) => (
                          <span style={{ color: text === 'Success' ? 'green' : 'red' }}>
                            {text}
                          </span>
                        )
                      }
                    ]}
                    pagination={false}
                  />
                </div>
              </Col>
            </Row>
          </Card>
          <Divider style={{ margin: "16px 0" }} />
        </>
      ))}
    </ContentLayout>
  );
};
 
export default StudentCourseResults;
 