import React from "react";
import { Modal, Button } from "antd";
import internetConnectivityGIF from "./internet_connectivity.gif";
import dontRefreshGIF from "./dont_refresh.gif";
import autoSaveGIF from "./auto_save.gif";
import coding from "./coding.gif";
import mcq from "./mcq.gif";

interface PointWithGIFProps {
  title: string;
  gif: string;
  width?: string;
  height?: string;
}

const PointWithGIF: React.FC<PointWithGIFProps> = ({ title, gif, width, height }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flexShrink: 0, marginRight: '20px' }}>
        <img src={gif} alt={title} style={{ maxWidth: width || "100%", height: height || "auto" }} />
      </div>
      <div>
        <h3>{title}</h3>
      </div>
    </div>
  );
};

interface ModalDialogProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const ModalDialog: React.FC<ModalDialogProps> = ({ visible, onOk, onCancel }) => {
  return (
    <Modal
      width={1000}
      title="NOTED⤵️"
      open={visible}
      onCancel={onCancel}
      footer={[
        <div key="footer-buttons">
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>
          <Button key="submit" type="primary" onClick={onOk}>
            Let me take Assessment
          </Button>
        </div>
      ]}
      style={{ top: 20, borderRadius: "8px" }}
      forceRender
      maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      bodyStyle={{ border: "none", boxShadow: "none", borderRadius: "8px", maxHeight: '70vh', overflowY: 'auto' }}
    >
      <div>
        <div>
          <p>
            <h1>POINTS TO BE NOTED📜</h1>
            <PointWithGIF title="Internet Connectivity" gif={internetConnectivityGIF} width="50px" height="50px" />
            <br />
            Ensure that you have a stable internet connection with a minimum speed of 2 Mbps
            <br /><br />
            <PointWithGIF title="Don't Refresh" gif={dontRefreshGIF} width="50px" height="50px" />
            <br />
            "Don't refresh the webpage during the assessment. This will lead to immediate submission of your responses."
            <br />
            <br />
            <PointWithGIF title="Auto Save" gif={autoSaveGIF} width="50px" height="50px" />
            <br />
            All your responses are saved automatically. In case of disconnection or shutdown, you will still be able to resume(Within 15 minutes of disconnection)
            <br />
            <br />
            <b>
              <PointWithGIF title="For Coding" gif={coding} width="50px" height="50px" />
            </b>
            <br />
            &nbsp;&nbsp;&nbsp;Please make sure to:
            <ul>
              <li>Print the Exact output being shown in the sample Test cases.</li>
              <li>Choose the appropriate programming language</li>
              <li>After typing the code,</li>
              <ul>
                <li>First click on "Compile code" button to check the Sample test cases are executed successfully.</li>
                <li>Secondly click the "Submit Code" button to check the status of weighted test cases.</li>
              </ul>
              <li>After attempting all the questions in that section you may give "Submit Test".</li>
              <li>Test once submitted can't be resumed.</li>
              <li>For Practice tests with incremental Timer you can use "Pause Test" button to pause the test and resume from where you left.</li>
              <li>For Java, use the class name as Main</li>
              <li>Directly get the values using Scanner, don't need to print statements like "Enter the values"</li>
            </ul>
            <br />
            <b>
              <PointWithGIF title="For MCQ" gif={mcq} width="50px" height="50px" />
            </b>
            <ul>
              <li>After attempting all the questions in that section you may give "Submit Test".</li>
              <li>Test once submitted can't be resumed.</li>
              <li>For Practice tests with incremental Timer you can use "Pause Test" button to pause the test and resume from where you left.</li>
              <li>You can use "Mark for Review" to revisit the questions after attempting other questions</li>
              <li>After submitting the test you can review the score(Only for submitted tests) with analysis at the top of your course section.</li>
            </ul>
            <br />
            <br />
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDialog;
