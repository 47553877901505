import React, { useEffect, useState } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout";
import moment from "moment";
import qs from "qs";
import { CourseModule, CourseSubModule } from "../types";
import { Button, Card, Col, Empty, Input, List, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCourseModulesForStudent } from "../api/getCourseModulesForStudent";
import { getCourseSubModulesForStudent } from "../api/getCourseSubModulesForStudent";
import { takeTest } from "../api/takeTest";
import ModalDialog from "./ModalDialog";
import { notification} from 'antd';
 
 
export const StudentCourseMain = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("enrollmentId");
  const enrollmentId = id && id !== "" ? +id : 0;
  const navigate = useNavigate();
  const [modules, setModules] = useState<CourseModule[]>();
  const [subModules, setSubModules] = useState<CourseSubModule[]>();
  const [selectedModule, setSelectedModule] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);
  const [subModuleModalId, setSubModuleModalId] = useState<number | null>(null);
  const [subModuleFirstTimeTakeTest, setSubModuleFirstTimeTakeTest] = useState<{ [key: number]: boolean }>({});
  const [showDeveloperToolsAlert, setShowDeveloperToolsAlert] = useState(false);
 
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 123) {
        event.preventDefault();
        setShowDeveloperToolsAlert(true);
      }    
    };
 
    const handleRightClick = (event: MouseEvent) => {
      event.preventDefault();
      setShowDeveloperToolsAlert(true);
    };
 
    if (enrollmentId && enrollmentId !== 0) {
      getCourseModulesByEnrollmentId();
    }
   
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('contextmenu', handleRightClick);
 
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('contextmenu', handleRightClick);
    };
  }, [enrollmentId]);
 
  useEffect(() => {
    if (showDeveloperToolsAlert) {
      notification.warning({
        message: "Warning",
        description: "Right click and developer tools are disabled",
        onClose: () => setShowDeveloperToolsAlert(false)
      });
    }
  }, [showDeveloperToolsAlert]);
 
  useEffect(() => {
    if (enrollmentId && enrollmentId !== 0) {
      getCourseModulesByEnrollmentId();
    }
  }, [enrollmentId]);
 
  useEffect(() => {
    if (modules && modules.length > 0) {
      setSelectedModule(modules[0].id);
    }
  }, [modules]);
 
  useEffect(() => {
    if (selectedModule && selectedModule !== 0) {
      getCourseSubModulesByModuleId();
    }
  }, [selectedModule]);
 
  const getCourseModulesByEnrollmentId = () => {
    getCourseModulesForStudent(enrollmentId).then((res) => {
      setModules(res);
    });
  };
 
  const getCourseSubModulesByModuleId = () => {
    getCourseSubModulesForStudent(enrollmentId, selectedModule).then((res) => {
      setSubModules(res);
    });
  };
 
  const gotToResultsPage = () => {
    const params = { enrollmentId, moduleId: selectedModule };
    navigate({
      pathname: "/student-course-results",
      search: qs.stringify(params),
    });
  };
 
  const handleTakeTest = (subModuleId: number, testType: string) => {
    if (subModules && subModules.length > 0) {
      if (subModuleId && subModuleId !== 0 && testType) 
        {
        if (!subModuleFirstTimeTakeTest[subModuleId]) {
          setSubModuleModalId(subModuleId);
          setShowModal(true);
        }
        else {
          attemptTest(subModuleId, testType);
        }
      }
    }
  };
 
  const attemptTest = (subModuleId: number, testType: string) => {
    takeTest(enrollmentId, subModuleId).then(
      (res) => {
        const path =
          testType === "Coding"
            ? "/student-coding-test"
            : testType === "Multi Choice"
            ? "/student-mcq-test"
            : "";
        const params = { enrollmentId, subModuleId, transactionId: res };
        navigate({
          pathname: path,
          search: qs.stringify(params),
        });
      },
      (err) => {
      }
    );
  };
 
  const handleModalOk = () => {
    if (subModuleModalId !== null && subModules) {
      setSubModuleFirstTimeTakeTest(prevState => ({
        ...prevState,
        [subModuleModalId]: true
      }));
      const subModule = subModules.find(module => module.id === subModuleModalId);
      if (subModule) {
        attemptTest(subModuleModalId, subModule.testType);
      }
      setSubModuleModalId(null);
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
  }

  const sortedSubModules = subModules?.slice().sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
 
  return (
    <ContentLayout title="Modules" type="nocard">
      <Row gutter={16}>
        <Col span={8}>
          <Card style={{ borderRadius: "1rem" }}>
            <Input
              size="large"
              placeholder="Search"
              suffix={<SearchOutlined />}
              style={{ marginBottom: 15 }}
            />
           <List
              dataSource={modules}
              renderItem={(item, key) => {
                return (
                  <List.Item
                    key={key}
                    className={
                      selectedModule === item.id ? "list-item-active" : "list-item"
                    }
                    onClick={() => {
                      setSelectedModule(item.id);
                    }}
                  >
                    <List.Item.Meta
                      title={item.name}
                      description={
                        <div>
                          {moment(item.startDate).format("D MMM yyyy")} -{" "}
                          {moment(item.endDate).format("D MMM yyyy")}
                        </div>
                      }
                    />
                  </List.Item>
                );
              }}
            />
          </Card>
        </Col>
        <Col span={16}>
          <Card style={{ borderRadius: "1rem" }}>
            <div style={{ marginBottom: 10 }}>
              <Button type="primary" onClick={gotToResultsPage}>
                View Results
              </Button>
            </div>
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Questions</th>
                  <th>Duration</th>
                  <th>Marks</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                  {sortedSubModules?.map((data, k) => (
                    <tr key={k}>
                      <td>{data.name}</td>
                      <td>{data.noOfQuestions}</td>
                      <td>{data.testDuration}</td>
                      <td>{data.totalMarks}</td>
                      <td>
                        {data.status === "Valid" ? (
                          <>
                            <Button
                              htmlType="button"
                              type="primary"
                              onClick={() => handleTakeTest(data.id, data.testType)}
                            >
                              Take Test
                            </Button>
                            {subModules && subModules.length > 0 && (
                              <ModalDialog
                                visible={showModal}
                                onOk={handleModalOk}
                                onCancel={handleModalCancel}
                              />
                            )}

                          </>
                        ) : (
                          <p>{data.status}</p>
                        )}              
                      </td>

                    </tr>
                  ))}
                   {subModules && subModules.length === 0 ? (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ) : (
                "" )}
                </tbody>
            </table>
          </Card>
        </Col>
      </Row>
    </ContentLayout>
  );
};
 
 
 