import { useEffect, useState } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout"
import { TableParams } from "../../../types";
import { Course } from "../types";
import qs from 'qs';
import { Button, Col, Row } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import Search from "antd/es/input/Search";
import { useNavigate } from "react-router-dom";
import { getCourses } from "../api/getCourses";
import { CoursesList } from "../components/CourseList";
import { cloneCourse } from "../api/cloneCourse";

const getQueryParams = (params: TableParams) => ({
    limit: params.pagination?.pageSize,
    offset: params.pagination?.current,
    orderBy: params.sortField,
    order: params.sortOrder,
    searchInput: params.searchInput
});

export const Courses = () => {
    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState<string>();
    const [rowCount, setRowCount] = useState<number>(0);
    const [data, setData] = useState<Course[]>();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 100,
        },
        sortField: 'name',
        sortOrder: 'asc',
        searchInput: ''
    });

    const fetchData = () => {
        setLoading(true);
        getCourses(qs.stringify(getQueryParams(tableParams))).then((res) => {
            setRowCount(res.totalElements);
            setLoading(false)
            setData(res.content)
        }, () => {
            setLoading(false)
        });
    };

    useEffect(() => {
        fetchData();
    }, [tableParams]);

    const updateTableParams = (params: TableParams) => {
        setTableParams({
            ...params,
            searchInput
        });
    }

    const onSearch = (searchInput: string) => {
        let query = ''
        if(searchInput !== '') {
            query = `name=like=${searchInput};courseCode=like=${searchInput};college=like=${searchInput};publishingType=like=${searchInput}`
        }
        setSearchInput(searchInput);
        setTableParams({
            ...tableParams,
            searchInput: query
        })
    }

    const goToForm = (id: number, collegeId: number, mode: string) => {
        const params = { id, mode, collegeId };
        navigate({
            pathname: '/course-form',
            search: qs.stringify(params),
        });
    }

    const handleCloneCourse = (courseId: number, collegeId: number) => {
        cloneCourse(courseId, collegeId).then((res) => {
            const params = { id: res.id, mode: 'Edit' };
            navigate({
                pathname: '/course-form',
                search: qs.stringify(params),
            });
        }, err => {
        });
    }

    return (
        <ContentLayout title="Course">
            <Row style={{ marginBottom: 16 }} gutter={[20, 25]} justify='end'>
                <Col>
                    <Search placeholder="Search" enterButton onSearch={onSearch} />
                </Col>
                <Col>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => goToForm(0, 0, 'Add')}>Create Course</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <CoursesList data={data} loading={loading} tableParams={tableParams} rowCount={rowCount} updateTableParams={updateTableParams} goToForm={goToForm} handleCloneCourse={handleCloneCourse} />
                </Col>
            </Row>
        </ContentLayout>
    )
}