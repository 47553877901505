import React, { useRef, useEffect } from "react";
import AceEditor from "react-ace";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-scala";
import "ace-builds/src-noconflict/mode-golang";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

type CodeEditorProps = {
  mode: string;
  value: string;
  onChange: (data: string) => void;
  width?: number | string;
  height?: number | string;
  readonly?: boolean;
  showGutter?: boolean;
  overflowYScroll?: boolean;
  disablePaste?: boolean;
};

const CodeEditor = ({
  mode,
  value,
  onChange,
  width = 600,
  height = 380,
  readonly = false,
  showGutter = true,
  overflowYScroll = false,
  disablePaste = false,
}: CodeEditorProps) => {
  const editorRef = useRef<any>(null);

  useEffect(() => {
    const editor = editorRef.current?.editor;

    const handlePaste = (event: any) => {
      if (disablePaste) {
        event.preventDefault();
        // event.stoppropagation();
        toast.error("Paste is not allowed");  
      }
    };

    document.addEventListener("paste", handlePaste);

    if (editor) {
      editor.on("paste", handlePaste);
    }

    return () => {
      document.removeEventListener("paste", handlePaste);
      if (editor) {
        editor.off("paste", handlePaste);
      }
    };
  }, [disablePaste]);

  const overflowStyle: React.CSSProperties = overflowYScroll ? { overflowY: 'auto' } : { overflowY: 'hidden' };

  return (

      <AceEditor
        ref={editorRef}
        style={{ height, width, borderRadius: '1rem', maxHeight: 500, maxWidth: 700, ...overflowStyle }}
        onChange={onChange}
        value={value}
        mode={mode}
        theme="monokai"
        name="blah2"
        fontSize={14}
        showPrintMargin={true}
        showGutter={showGutter}
        highlightActiveLine={true}
        readOnly={readonly}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
  );
};

export default CodeEditor;
