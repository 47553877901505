import { Button, Card, Checkbox, Col, Divider, Form, Input, Modal, Row, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { MultiChoiceQuestion } from '../types';
import {
    PlusOutlined,
    MinusCircleOutlined
} from '@ant-design/icons';
import qs from 'qs';

import { ContentLayout } from '../../../components/layout/ContentLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createMultiChoiceQuestion } from '../api/createMultiChoiceQuestion';
import { updateMultiChoiceQuestion } from '../api/updateMultiChoiceQuestion';
import TextEditor from '../../../components/texteditor/TextEditor';
import { getMultiChoiceQuestionById } from '../api/getMultiChoiceQuestionById';
import { toast } from 'react-toastify';



const cardStyle = {
    boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)"
}


export const MultiChoiceQuestionForm = () => {

    const navigate = useNavigate();
    const [data, setData] = useState<MultiChoiceQuestion>();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const [searchParams] = useSearchParams();
    const mode = searchParams.get('mode');
    const id = searchParams.get('id');
    let qbankkId = searchParams.get('qbkId');
    const qbkId = (qbankkId !== null && qbankkId !== '') ? +qbankkId : 0;

    const initialAnswers = [{ answer: '', correct: false, position: 1 }, { answer: '', correct: false, position: 2 }, { answer: '', correct: false, position: 3 }, { answer: '', correct: false, position: 4 }]

    const qid = (id !== null && id !== '') ? +id : 0;

    useEffect(() => {
        if (qid !== 0) {
            setLoading(true);
            getMultiChoiceQuestionById(qid).then((res) => {
                setData(res);
                setLoading(false);
            }, err => {
                setLoading(false);
            })
        }
    }, [searchParams]);

    useEffect(() => {
        form.setFieldsValue({ ...data });
        const answers = data?.answers.sort((a, b) => a.position - b.position);
        form.setFieldValue('answers', answers);
    }, [data, form])


    const save = (data: MultiChoiceQuestion) => {
        const hasCorrectAnswer=data.answers.some(answer=>answer.correct);
        
       if (!hasCorrectAnswer) {
            toast.error("Please mark at least one correct answer");
            return;
        }
        setLoading(true);
        data.qbkId = qbkId;
        createMultiChoiceQuestion(data).then((res) => {
            setLoading(false);
            form.resetFields();
            gotoMCQuestionsGrid();
        }, err => {
            setLoading(false);
        });
    }

    const update = (data: MultiChoiceQuestion) => {
        const hasCorrectAnswer = data.answers.some(answer => answer.correct);
        if (!hasCorrectAnswer) {
            toast.error("Please mark at least one correct answer");
            return;
        }
        data.id = qid;
        setLoading(true);
        updateMultiChoiceQuestion(data).then((res) => {
            gotoMCQuestionsGrid();
        }, err => {
            setLoading(false);
        });
    }

    const gotoMCQuestionsGrid = () => {
        const params = { qbkId }
        navigate({
            pathname: '/mcquestions',
            search: qs.stringify(params),
        });
    }

    return (
        <ContentLayout title="Multi Choice Questions">

            <Form
                form={form}
                layout="vertical"
                onFinish={mode === 'Add' ? save : update}
                autoComplete="off"
                initialValues={{ answers: initialAnswers }}
            >

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter name' }, { max: 100, message: 'Name should not be greater than 100 characters' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="category"
                            label="Category"
                            rules={[{ required: true, message: 'Please enter category' }, { max: 100, message: 'Category should not be greater than 100 characters' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="subCategory"
                            label="Sub Category"
                            rules={[{ required: true, message: 'Please enter sub category' }, { max: 100, message: 'Sub Category should not be greater than 100 characters' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="difficultyLevel"
                            label="Difficulty Level"
                            rules={[{ required: true, message: 'Please enter difficulty level' }]}
                        >
                            <Select
                                options={[
                                    { value: 'Low', label: 'Low' },
                                    { value: 'Medium', label: 'Medium' },
                                    { value: 'High', label: 'High' }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="explaination"
                            label="Explaination"
                            rules={[{ max: 250, message: 'Explanation should not be greater than 250 characters' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="mark"
                            label="Score"
                            rules={[{ required: true, message: 'Please enter score' }]}
                        >
                            <Input type="number" min={1} max={10} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <h3>Question</h3>
                <Row gutter={16}>
                    <Col span={12} >
                        <Card size="small" style={cardStyle}>
                            <Form.Item
                                name="question"
                                style={{ marginTop: 15 }}
                            >
                                {/* @ts-ignore */}
                                <TextEditor />
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>

                <h3>Answers</h3>
                <Row gutter={16}>
                    <Col span={12} >
                        <Form.List name="answers" >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Space key={field.key} direction="vertical" size='middle' style={{ display: 'flex', marginBottom: 16 }}>
                                            <Card title={`Option ${index + 1}`} size="small" style={cardStyle}>
                                                <Form.Item
                                                    name={[index, 'answer']}
                                                    style={{ marginBottom: 5 }}
                                                >
                                                    {/* @ts-ignore */}
                                                    <TextEditor />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[index, 'correct']}
                                                    style={{ marginBottom: 5 }}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox>Mark as Correct Answer</Checkbox>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(index)} />
                                            </Card>
                                        </Space>
                                    ))}
                                    {(mode === 'Add' || (data?.editable && mode === 'Edit')) && (
                                        <Form.Item>
                                            <Button type='dashed' onClick={() => add({ answer: '', correct: false, position: (fields.length + 1) })} block icon={<PlusOutlined />}>
                                                Add Answers
                                            </Button>
                                        </Form.Item>
                                    )}
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <Row gutter={16} justify='end' >
                    <Col>
                        {data?.editable && mode === 'Edit' && (
                            <Space>
                                <Button htmlType="submit" type="primary" loading={loading} disabled={loading}>
                                    Update
                                </Button>
                                <Button htmlType="button" onClick={() => gotoMCQuestionsGrid()}>
                                    Cancel
                                </Button>
                            </Space>
                        )}

                        {mode === 'Add' ? (
                            <Space>
                                <Button htmlType="submit" type="primary" loading={loading} disabled={loading}>
                                    Add
                                </Button>
                                <Button htmlType="button" onClick={() => gotoMCQuestionsGrid()}>
                                    Cancel
                                </Button>
                            </Space>
                        ) : null}

                        {!data?.editable && mode === 'Edit' && (
                            <Button htmlType="button" onClick={() => gotoMCQuestionsGrid()}>
                                Back
                            </Button>
                        )}
                    </Col>
                </Row>
            </Form>

        </ContentLayout>
    );
}