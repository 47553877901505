import { axios } from "../../../lib/axios";

export const bulkretaketest = async (submoduleId: number, enrollmentIds: number[]): Promise<void> => {
  return axios.request<void>({
    url: `/courses/modules/submodules/${submoduleId}/retakeTests`,
    method: 'delete',
    data: enrollmentIds,
  })
  .then(response => response.data);
};



  // @DeleteMapping("/modules/submodules/{subModuleId}/retakeTests")