import { useEffect, useState } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout"


import { CodingQuestionsList } from "../components/CodingQuestionsList";

import { CQTableParams, CodingQuestion } from "../types";
import qs from 'qs';
import { Button, Col, Row } from "antd";
import {
    PlusOutlined
} from '@ant-design/icons';
import Search from "antd/es/input/Search";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCodingQuestions } from "../api/getCodingQuestions";
import { getQuestionBankById } from "../../questionbanks/api/getQuestionBankById";


const getQueryParams = (params: CQTableParams) => ({
    limit: params.pagination?.pageSize,
    offset: params.pagination?.current,
    orderby: params.sortField,
    order: params.sortOrder,
    searchInput: params.searchInput,
    qbkId: params.qbkId
});


export const CodingQuestions = () => {

    const navigate = useNavigate();
    const [searchInput, SetSearchInput] = useState<string>();
    const [rowCount, setRowCount] = useState<number>(0);
    const [data, setData] = useState<CodingQuestion[]>();
    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const [tableParams, setTableParams] = useState<CQTableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sortField: 'name',
        sortOrder: 'asc',
        searchInput: '',
        qbkId: 0
    });

    const [searchParams] = useSearchParams();
    const id = searchParams.get('qbkId');
    const qbkId = (id !== null && id !== '') ? +id : 0;


    useEffect(() => {
        if (qbkId !== 0) {
            getQuestionBankById(qbkId).then(res => {
                setEditable(res.editable);
            })
            setTableParams({
                ...tableParams,
                qbkId: qbkId
            })
        }
    }, [searchParams]);


    const fetchData = () => {
        setLoading(true);
        getCodingQuestions(qs.stringify(getQueryParams(tableParams))).then((res) => {
            setRowCount(res.totalElements);
            setLoading(false)
            setData(res.content)
        }, () => {
            setLoading(false)
        });
    };

    useEffect(() => {
        if (tableParams.qbkId !== 0) {
            fetchData();
        }
    }, [tableParams]);


    const updateTableParams = (params: CQTableParams) => {
        setTableParams({
            ...params,
            searchInput
        });
    }

    const onSearch = (searchInput: string) => {
        let query = ''
        if (searchInput !== '') {
            query = `name=like=${searchInput};category=like=${searchInput};subCategory=like=${searchInput};difficultyLevel=like=${searchInput}`
        }
        SetSearchInput(query);
        setTableParams({
            ...tableParams,
            searchInput: query
        })
    }

    const goToForm = (id: number, mode: string) => {
        const params = { id, mode, qbkId };
        navigate({
            pathname: '/coding-question-form',
            search: qs.stringify(params),
        });
    }

    const goToQuestionBankMain = () => {
        navigate('/questionbanks');
    }

    return (
        <ContentLayout title="Coding Questions">
            <Row style={{ marginBottom: 16 }} gutter={[20, 25]} justify='end'>
                <Col>
                    <Button onClick={goToQuestionBankMain}>Back</Button>
                </Col>
                <Col>
                    <Search placeholder="Search" enterButton onSearch={onSearch} />
                </Col>
                {editable === false ? null : (
                    <Col key="create-button">
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => goToForm(0, 'Add')}>
                            Create CodingQuestion
                        </Button>
                    </Col>
                )}
            </Row>
            <Row>
                <Col span={24}>
                    <CodingQuestionsList data={data} loading={loading} tableParams={tableParams} rowCount={rowCount} updateTableParams={updateTableParams} goToForm={goToForm} />
                </Col>
            </Row>
        </ContentLayout>
    )
}