import { Route, Routes } from 'react-router-dom';
import LoginForm from '../components/LoginForm';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginForm />} />
      <Route path="login" element={<LoginForm />} />
      <Route path='*' element={<LoginForm />} />
    </Routes>
  );
};