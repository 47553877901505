import { useEffect, useState } from "react";
import { ContentLayout } from "../../../components/layout/ContentLayout"
import { TableParams } from "../../../types";
import moment from 'moment';
import { notification } from "antd";
import { StudentCourse } from "../types";
import qs from 'qs';
import { Badge, Col, Input, Progress, Row } from "antd";
import {
    SearchOutlined
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { getCoursesForStudent } from "../api/getCoursesForStudent";
 
const getQueryParams = (params: TableParams) => ({
    limit: params.pagination?.pageSize,
    offset: params.pagination?.current,
    orderBy: params.sortField,
    order: params.sortOrder,
    searchInput: params.searchInput
});
 
export const StudentRecriuts = () => {
 
    const navigate = useNavigate();
    const [rowCount, setRowCount] = useState<number>(0);
    const [data, setData] = useState<StudentCourse[]>();
    const [loading, setLoading] = useState(false);
 
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 500,
        },
        sortField: 'name',
        sortOrder: 'asc',
        searchInput: 'publishingType==Recruit'
    });
 
 
    const [showDeveloperToolsAlert, setShowDeveloperToolsAlert] = useState(false);
 
 
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 123) {
        event.preventDefault();
        setShowDeveloperToolsAlert(true);
      }
    };
 
    const handleRightClick = (event: MouseEvent) => {
      event.preventDefault();
      setShowDeveloperToolsAlert(true);
    };
 
 
   
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('contextmenu', handleRightClick);
 
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);
 
  useEffect(() => {
    if (showDeveloperToolsAlert) {
      notification.warning({
        message: "Warning",
        description: "Right click and developer tools are disabled",
        onClose: () => setShowDeveloperToolsAlert(false)
      });
    }
  }, [showDeveloperToolsAlert]);
 
    useEffect(() => {
        fetchData();
    }, [tableParams]);
 
    const fetchData = () => {
        setLoading(true);
        getCoursesForStudent(qs.stringify(getQueryParams(tableParams))).then((res) => {
            setRowCount(res.totalElements);
            setLoading(false)
            setData(res.content)
        }, () => {
            setLoading(false)
        });
    };
 
    const updateTableParams = (input: string) => {
        if (input && input !== '') {
            const searchInput = `publishingType==Recruit;name=like=${input};desc=like=${input}`
            setTableParams({ ...tableParams, searchInput });
        } else {
            setTableParams({ ...tableParams });
        }
    }
 
    const goToCourseMainPage = (id: number) => {
        const params = { enrollmentId: id };
        navigate({
            pathname: '/student-course-main',
            search: qs.stringify(params),
        });
    }
 
    const ribbonColor = (status: string) => {
        let ribbonColor = 'blue';
        switch (status) {
            case 'Expired':
                ribbonColor = 'red';
                break;
            case 'Completed':
                ribbonColor = 'green';
                break;
            case 'In Progress':
                ribbonColor = 'yellow';
                break;
            default:
                break;
        }
        return ribbonColor;
    }
 
    return (
        <ContentLayout title="Recruits" type="nocard">
            <Row style={{ marginBottom: 24 }}>
                <Col span={8}>
                    <Input size="large" placeholder="Search" suffix={<SearchOutlined />} onChange={(e) => updateTableParams(e.target.value)} />
                </Col>
            </Row>
            <Row style={{ display: 'flex', flexWrap: 'wrap', gap: '50px' }}>
                {data?.map((data, k) => (
                    <Col key={k} >
                        <Badge.Ribbon text={data.status} color={ribbonColor(data.status)}>
                            <div className="card" onClick={() => goToCourseMainPage(data.enrollmentId)}>
                                <h3 className="card__title">{data.name}</h3>
                                <p className="card__content">{data.description}</p>
                                <p>
                                    <Progress percent={data.completedPercentage} size="small" />
                                </p>
                                <div className="card__date">
                                    {moment(data.startDate).format('d MMM yyyy')} to {moment(data.endDate).format('d MMM yyyy')}
                                </div>
                                <div className="card__arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="15" width="15">
                                        <path fill="#fff" d="M13.4697 17.9697C13.1768 18.2626 13.1768 18.7374 13.4697 19.0303C13.7626 19.3232 14.2374 19.3232 14.5303 19.0303L20.3232 13.2374C21.0066 12.554 21.0066 11.446 20.3232 10.7626L14.5303 4.96967C14.2374 4.67678 13.7626 4.67678 13.4697 4.96967C13.1768 5.26256 13.1768 5.73744 13.4697 6.03033L18.6893 11.25H4C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H18.6893L13.4697 17.9697Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </Badge.Ribbon>
                    </Col>
                ))}
            </Row>
        </ContentLayout>
    )
}