import React from 'react';
import { ContentLayout } from '../../../components/layout/ContentLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faHourglassEnd, faList, faPercent } from '@fortawesome/free-solid-svg-icons';


export const AdminDashboard: React.FC = () => {

    return (
        <ContentLayout title="Dashboard" type="nocard">
            <div className="status-card-container">
                <div className='status-card-row'>
                <div className="status-card shadow">
                    <div className="status-card-content">
                        <div className="status-card-header">
                            TOTAL COURSES
                        </div>
                        <div className="status-card-body">
                            12
                        </div>
                    </div>
                    <div className="status-card-icon bg-yellow text-white rounded-circle shadow-light">
                        <FontAwesomeIcon icon={faList} size="2x" />
                    </div>
                </div>
                <div className="status-card shadow">
                    <div className="status-card-content">
                        <div className="status-card-header">
                            IN PROGRESS
                        </div>
                        <div className="status-card-body">
                            6
                        </div>
                    </div>
                    <div className="status-card-icon bg-blue text-white rounded-circle shadow-light">
                        <FontAwesomeIcon icon={faPercent} size="2x" />
                    </div>
                </div>
                <div className="status-card shadow">
                    <div className="status-card-content">
                        <div className="status-card-header">
                            EXPIRED
                        </div>
                        <div className="status-card-body">
                            2
                        </div>
                    </div>
                    <div className="status-card-icon bg-red text-white rounded-circle shadow-light">
                        <FontAwesomeIcon icon={faHourglassEnd} size="2x" />
                    </div>
                </div>
            </div>
            <div className="course-card shadow">
                <div className="course-card-header">COURSE LIST</div>
                <div className="course-card-body">
                <div className="course-list">
                    <div className="course-item">Course A</div>
                    <div className="course-item">Course B</div>
                    <div className="course-item">Course C</div>
                </div>
                </div>
                </div>
            </div>
        </ContentLayout >
    );
};

export default AdminDashboard;
