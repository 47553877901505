import { Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { Suspense } from "react";


import { Head } from "../head/Title";

type ContentLayoutProps = {
    children: React.ReactNode;
    title: string;
    type?: 'card' | 'nocard';
};

export const ContentLayout = ({ children, title, type }: ContentLayoutProps) => {
    return (
        <Suspense
            fallback={
                <div className="flex items-center justify-center w-screen h-screen">
                    <Spin />
                </div>
            }
        >
            <Head title={title} />
            <Content
                className="content"
                style={type === 'nocard' ? {
                    margin: '0px 16px 5px',
                    padding: 24,
                    minHeight: 600,
                    overflow: 'auto',
                } : {
                    margin: '0px 16px 5px',
                    padding: 24,
                    minHeight: 600,
                    overflow: 'auto',
                    background: '#fff',
                    borderRadius: 8,
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                }}
            >
                {children}
            </Content>
        </Suspense>
    );
};
