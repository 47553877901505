import { Button, Col, Form, Input, Row, Select, Space } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useNavigate } from "react-router-dom";
import { DatePicker } from 'antd';
import { useEffect, useState } from "react";
import { College } from "../../colleges";
import { TableParams } from "../../../types";
import { getColleges } from "../../colleges/api/getColleges";
import { getQueryParams } from "../../../utils/queryparams";
import qs from 'qs';
import { Course } from "../types";
import { createCourse } from "../api/createCourse";
import { updateCourse } from "../api/updateCourse";
import { getCourseById } from "../api/getCourseById";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import YearSelection from "../../../components/YearSelection";
import { useAuth } from "../../../lib/auth";
import utc from 'dayjs/plugin/utc'; 

dayjs.extend(utc); 
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
type CourseDetailsProps = {
    courseId: number;
    mode: string;
}
export const CourseDetails = ({ courseId, mode }: CourseDetailsProps) => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [selectedCourseId, setSelectedCourseId] = useState<number>(0);
    const [colleges, setColleges] = useState<College[]>([] as College[]);
    const [collegeOptions, setCollegeOptions] = useState<any>();
    const [departmentOptions, setDepartmentOptions] = useState<any>();
    const [collegeId, setCollegeId] = useState<number>(0);
    const [selectedCollege, setSelectedCollege] = useState<number>();
    const [selectedDepartments, setSelectedDepartments] = useState<string>('');
    const [data, setData] = useState<Course>();
    const [selectedBatch, setSelectedBatch] = useState<number>();
    const [dateRangeErr, setDateRangeErr] = useState<boolean>(false);
    const {user} = useAuth()
    const [userTimeZone, setUserTimeZone] = useState<string>(''); 


    useEffect(() => {
        setSelectedCourseId(courseId);
    }, [courseId]);

    useEffect(() => {
        if (selectedCourseId !== 0) {
            getCourseDetails();
        }
        getCollegesList();
    }, [selectedCourseId]);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({ ...data });
        const start = dayjs.utc(data.startDate).local();
        const end = dayjs.utc(data.endDate).local();
            setSelectedDepartments(data.departments)
            form.setFieldValue('courseValidity', [start, end])
        }

        if (colleges && data) {
            const clg = colleges.filter((item) => {
                return item.id === data.collegeId;
            });

            form.setFieldValue("college", { value: clg[0].id, label: clg[0].name });
            const departments = clg[0].departments.split(',').map((item) => {
                return { value: item, label: item }
            })
            setDepartmentOptions(departments);

            const selectedDepts = data.departments.split(',').map(d => {
                return { label: d, value: d }
            })
            form.setFieldValue("departments", selectedDepts);
        }
    }, [data, form, colleges])


  


    useEffect(() => {
        if (user) {
            const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setUserTimeZone(detectedTimeZone);
            console.log(detectedTimeZone);
        }
    }, [user]);

    const adjustTimeToUserTimeZone = (utcDate: Date) => {
        return dayjs.utc(utcDate).local();
    }


    const getCourseDetails = () => {
        getCourseById(courseId).then(res => {
            setData(res);
        });
    }

    const getCollegesList = () => {
        const params: TableParams = {
            pagination: {
                current: 1,
                pageSize: 100,
            },
            sortField: 'name',
            sortOrder: 'asc',
            searchInput: ''
        }

        getColleges(qs.stringify(getQueryParams(params))).then((res) => {
            let filteredColleges = res.content;
            let collegeOptions: any = [];
        
            if (user?.role === 'FACULTY') {
                const userCollegeId = user.collegeId;
                filteredColleges = res.content.filter(c => c.id === userCollegeId);
                collegeOptions = filteredColleges.map((item) => ({ value: item.id, label: item.name }));
            } else if (user?.role === 'ADMIN') {
                collegeOptions = res.content.map((item) => ({ value: item.id, label: item.name }));
            }
        
            setColleges(filteredColleges);
            setCollegeOptions(collegeOptions);
        });
        
    }

    const handleCollegeSelection = (value: number) => {
        const college = colleges.filter((item) => {
            return item.id === value
        });

        if (college.length !== 0) {
            setCollegeId(college[0].id);
            const departments = college[0].departments.split(',').map((item) => {
                return { value: item, label: item }
            })
            setDepartmentOptions(departments);
        }
    }

    const handleDepartmentSelection = (departments: string[]) => {
        setSelectedDepartments(departments.toString())
    }

    const save = (data: Course) => {
        data.collegeId = collegeId;
        data.departments = selectedDepartments;
        const validity = form.getFieldValue('courseValidity');
        data.startDate = validity[0];
        data.endDate = validity[1];
        if (areDatesInRange(new Date(data.startDate), new Date(data.endDate))) {
            createCourse(data).then((res) => {
                const params = { id: res.id, mode: 'Edit' };
                navigate({
                    pathname: '/course-form',
                    search: qs.stringify(params),
                });
            }, err => {
            });
        }
    }

    const update = (data: Course) => {
        data.id = courseId;
        data.departments = selectedDepartments;
        const validity = form.getFieldValue('courseValidity');
        data.startDate = validity[0];
        data.endDate = validity[1];
        if (areDatesInRange(new Date(data.startDate), new Date(data.endDate))) {
            updateCourse(data).then((res) => {
                getCourseDetails();
            }, err => {
            });
        }
    }

    const handleYearChange = (year: number) => {
        setSelectedBatch(year);
    };

    const areDatesInRange = (courseStart: Date, courseEnd: Date): boolean => {
        const validDateRange = courseStart < courseEnd;
        setDateRangeErr(!validDateRange)
        return validDateRange;
    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={mode === 'Add' ? save : update}
            autoComplete="off"
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter name' }, { max: 100, message: 'Name should not be greater than 100 chars' }]}
                    >
                        <Input placeholder="Please enter name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="courseCode"
                        label="Course Code"
                        rules={[{ required: true, message: 'Please enter course code' }, { max: 25, message: 'Course Code should not be greater than 25 chars' }]}
                    >
                        <Input placeholder="Please enter course code" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="college"
                        label="College"
                        rules={[{ required: true, message: 'Please select college' }]}
                    >
                        <Select
                            defaultValue={selectedCollege}
                            onSelect={handleCollegeSelection}
                            options={collegeOptions}
                            disabled={mode === 'Edit'}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="departments"
                        label="Departments"
                        rules={[{ required: true, message: 'Please select departments' }]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="please select departments"
                            onChange={handleDepartmentSelection}
                            style={{ width: '100%' }}
                            options={departmentOptions}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="batch"
                        label="Batch"
                        rules={[{ required: true, message: 'Please select batch' }]}
                    >
                        <YearSelection onChange={handleYearChange} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="publishingType"
                        label="Publishing Type"
                        rules={[{ required: true, message: 'Please select publishing type' }]}
                    >
                        <Select
                            options={[
                                { value: 'Courses', label: 'Courses' },
                                { value: 'Labs', label: 'Labs' },
                                { value: 'Codeathon', label: 'Codeathon' },
                                { value: 'Recruit', label: 'Recruit' }
                            ]}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} >
                <Col span={12}>
                    <Form.Item
                        name="courseValidity"
                        label="Course Validity"
                    >
                        <RangePicker
                       showTime={{
                        format: 'HH:mm:ss.SSS',
                        defaultValue: [dayjs('00:00:00.000', 'HH:mm:ss.SSS'), dayjs('24:00:00.000', 'HH:mm:ss.SSS')],
                        }}
                        format="YYYY-MM-DD HH:mm:ss.SSS"

                        defaultValue={data ? [
                            adjustTimeToUserTimeZone(new Date(data.startDate)),
                            adjustTimeToUserTimeZone(new Date(data.endDate))
                        ] : undefined}
                    />
                    </Form.Item>
                    {
                        dateRangeErr ? <p style={{ color: 'red' }}>The start date should be less than End date.</p> : ''
                    }
                </Col>
            </Row>
            <Row gutter={16} >
                <Col span={12}>
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{ max: 500, message: 'Description cannot be longer than 500 chars' }]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} justify='end' >
                <Col>
                    <Space>
                        <Button htmlType="submit" type="primary" >
                            {mode === 'Add' ? 'Save' : 'Update'}
                        </Button>
                        <Button htmlType="button" onClick={() => { navigate('/courses') }}>
                            Cancel
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    )
}


// npm install dayjs
// npm install dayjs-plugin-utc
