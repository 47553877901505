import { Button, Checkbox, Col, Drawer, Form, Input, Row, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { DEPARTMENTS } from '../../../utils/constants';
import { College } from '../types';
import { STATES } from '../../../utils/enum';

type CollegeFormProps = {
    open?: boolean;
    onClose: () => void;
    mode: string;
    data: College;
    onSave: (data: College) => void;
    loading: boolean
}

export const CollegeForm = ({ onClose, open, mode, data, onSave, loading }: CollegeFormProps) => {

    const [form] = Form.useForm();
    const [departments] = useState<string[]>([]);
    const [selectedDepartments, setSelectedDepartments] = useState<string>('');
    const filteredOptions = DEPARTMENTS.filter((o) => !departments.includes(o));
    const [selectedState, setSelectedState] = useState<string>('');

    const stateOptions = Object.keys(STATES).map((v: any) => {
        return { value: v, label: STATES[v as keyof typeof STATES] }
    })

    const onFormClose = () => {
        form.resetFields();
        onClose();
    };

    const saveCollege = (college: College) => {
        college.id = data.id;
        college.state = selectedState;
        college.departments = selectedDepartments;
        onSave(college);
    }

    const handleDepartmentSelection = (departments: string[]) => {
        setSelectedDepartments(departments.toString())
    }

    const handleStateSelection = (stateCode: string) => {
        setSelectedState(stateCode)
    }

    useEffect(() => {

        if (mode === 'Add') {
            setTimeout(() => {
                form.resetFields();
                form.setFieldValue('active', true);
            }, 0);
        }

        if (data) {
            // Set Selected Departments
            const selectedDepts = data.departments?.split(',').map(d => {
                return { label: d, value: d }
            })
            setTimeout(() => {
                form.setFieldValue("departments", selectedDepts);
            }, 0);

        }

        form.setFieldsValue({ ...data });
    }, [data, form, mode])

    return (
        <>
            <Drawer
                title={`${mode} College`}
                width={720}
                onClose={onFormClose}
                open={open}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={saveCollege}
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[{ required: true, message: 'Please enter name' }, { max: 150, message: 'Name should not be greater than 150 characters' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[{ required: true, message: 'Please enter email' }, { max: 200, message: 'Email should not be greater than 200 characters' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="phone"
                                label="Phone"
                                rules={[{ required: true, message: 'Please enter phone' }, { max: 20, message: 'Phone should not be greater than 20 characters' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="departments"
                                label="Departments"
                                rules={[{ required: true, message: 'Please select departments' }]}
                            >
                                <Select
                                    mode="multiple"
                                    onChange={handleDepartmentSelection}
                                    style={{ width: '100%' }}
                                    options={filteredOptions.map((item) => ({
                                        value: item,
                                        label: item,
                                    }))}
                                />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="street"
                                label="Street"
                                rules={[{ required: true, message: 'Please enter street' }, { max: 100, message: 'Street should not be greater than 100 characters' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="city"
                                label="City"
                                rules={[{ required: true, message: 'Please enter city' }, { max: 100, message: 'City should not be greater than 100 characters' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="state"
                                label="State"
                                rules={[{ required: true, message: 'Please enter state' }]}
                            >
                                <Select
                                    onChange={handleStateSelection}
                                    style={{ width: '100%' }}
                                    options={stateOptions}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="pin"
                                label="Pin"
                                rules={[{ required: true, message: 'Please enter pin' }, { max: 10, message: 'Pin should not be greater than 10 characters' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col>
                            <Form.Item name="active" valuePropName="checked">
                                <Checkbox>Active</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="Description"
                                rules={[{ max: 500, message: 'Description should not be greater than 500 characters' }]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {mode !== 'View' ? (
                        <Row justify='end' >
                            <Col>
                                <Space>
                                    <Button htmlType="submit" type="primary" loading={loading}>
                                        Submit
                                    </Button>
                                    <Button htmlType="button" onClick={onFormClose}>
                                        Cancel
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    ) : ''}
                </Form>
            </Drawer>
        </>
    );
}